import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./index.css"; // Import the CSS file
import { useCreateIngredientWithNameMutation } from "../../../auth/services/ingredientService"; // Adjust the path if needed
import { useNavigate } from "react-router-dom";

const GarnishToppings = ({ garnishes, setGarnishes }) => {
  const navigate = useNavigate();
  const [newGarnish, setNewGarnish] = useState("");
  const [createIngredientWithName] = useCreateIngredientWithNameMutation();

  // Retrieve userId and estoreId from Redux store
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  // Handle adding a new garnish or topping
  const addGarnish = async (event) => {
    event.preventDefault();
    if (newGarnish.trim()) {
      const updatedGarnish = [
        ...garnishes,
        { name: newGarnish.trim(), checked: true },
      ];
      setGarnishes(updatedGarnish);
      // Use the updated state directly
      try {
        await createIngredientWithName({
          name: newGarnish.trim(),
          subCategoryId: updatedGarnish[0]?.SubCategory?.subCategoryId,
          estoreId: estoreId,
        });

        setNewGarnish(""); // Clear the input field
      } catch (error) {
        console.error("Error adding ingredient:", error);
        setGarnishes((prevCourses) =>
          prevCourses.filter((course) => course.name !== newGarnish.trim())
        );
      }
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedGarnishes = garnishes.map((garnish, i) =>
      i === index ? { ...garnish, checked: !garnish.checked } : garnish
    );
    setGarnishes(updatedGarnishes);
  };

  return (
    <div className="garnish-toppings-container w-full">
      <h3 className="garnish-toppings-title">Garnish / Toppings</h3>
      <ul className="garnish-toppings-list">
        {garnishes.map((garnish, index) => (
          <li className="garnish-toppings-list-item" key={index}>
            <label
              htmlFor={`garnish-checkbox-${index}`}
              style={styles.checkboxLabel}
            >
              {garnish.name}
              <input
                id={`garnish-checkbox-${index}`} // Unique id for each checkbox
                name={`garnish-checkbox-${index}`}
                type="checkbox"
                checked={garnish.checked}
                onChange={(e) => {
                  e.preventDefault();
                  handleCheckboxChange(index);
                }}
                className="w-1 mr-0 gap-x-0 ml-1 "
                style={styles.input}
              />
            </label>
          </li>
        ))}
      </ul>
      <div>
        <input
          id={`garnish-checkbox`}
          name={`garnish-checkbox`}
          type="text"
          className="garnish-toppings-input"
          value={newGarnish}
          onChange={(e) => {
            e.preventDefault();
            setNewGarnish(e.target.value);
          }}
          placeholder="Add new garnish or topping"
        />
        {garnishes?.length > 0 ? (
          <button
            className="garnish-toppings-button"
            onClick={(e) => {
              addGarnish(e);
            }}
          >
            Add
          </button>
        ) : (
          <button
            className="course-food-button"
            onClick={(e) => navigate("/dashboard/product/addingredient")}
          >
            Add Content
          </button>
        )}
      </div>
    </div>
  );
};

export default GarnishToppings;
const styles = {
  checkboxLabel: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
    justifyItems: "center",
    width: "auto",
    fontSize: "14px",
    flexDirection: "row-reverse",
    gap: "-1rem",
  },
  input: {
    //margin: 0,
    marginLeft: "-0.90em",
    padding: 0,
    width: "16px",
    height: "16px",
    marginRight: "2px",
  },
};
