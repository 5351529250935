import "./index.css";
import { useState, useRef } from "react";
import * as htmlToImage from "html-to-image";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { decodeToken } from "../../../utils/decodeToken";

const regex = /^[a-zA-Z0-9]{1,4}$/;

function QrCodeGenerator({ url }) {
  const id = decodeToken(useSelector((state) => state.auth?.userToken)).id;
  const [qrIsVisible, setQrIsVisible] = useState(false);
  const [error, setError] = useState("");
  const [title, setTitle] = useState("");
  const [comment, setComment] = useState("");
  const qrCodeRef = useRef(null);
  const containerRef = useRef(null); // Reference for hiding elements
  const [isChecked, setIsChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value === "" || regex.test(value)) {
      setInputValue(value);
      setError("");
    } else {
      setError("Only letters and numbers (1-4 characters) allowed.");
    }
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setInputValue(""); // Clear input when checkbox is checked
    }
  };

  const handleQrCodeGenerator = () => {
    if (!url) {
      return;
    }
    setQrIsVisible(true);
  };

  const downloadQRCode = () => {
    if (!qrCodeRef.current) return;

    // Hide unnecessary elements
    /* const hiddenElements = [];
    containerRef.current.childNodes.forEach((child) => {
      if (child !== qrCodeRef.current) {
        hiddenElements.push({ element: child, display: child.style.display });
        child.style.display = "none"; // Hide other elements
      }
    }); */

    htmlToImage
      .toPng(qrCodeRef.current)
      .then(function (dataUrl) {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "qr-code.png";
        link.click();
      })
      .catch(function (error) {
        console.error("Error generating QR code:", error);
      });
  };

  return (
    <div className="qrcode__container" ref={containerRef}>
      <h4>QR Code Generator</h4>
      <label>
        Title:
        <input
          value={title}
          onChange={(text) => setTitle(text.target.value)}
          className={"w-80 border-none"}
        />
      </label>
      <label>
        Comment:
        <input
          value={comment}
          onChange={(text) => setComment(text.target.value)}
          className={"w-80 border-none"}
        />
      </label>
      <div>
        <label
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            gap: "0.02rem",
          }}
        >
          Attach ID
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            style={{
              margin: 0,
              marginLeft: "-0.80em",
              padding: 0,
              width: "16px",
              height: "16px",
            }} // Ensures no extra spacing
          />
        </label>

        {isChecked && (
          <div>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="ID input"
              className="w-full"
            />
            {error && <p style={{ color: "red" }}>{error}</p>}
          </div>
        )}
      </div>
      <div className="qrcode__container--parent">
        <div className="qrcode__input w-80 row bg-white">
          <label>
            {isChecked && inputValue && inputValue.length >= 1
              ? `${url}/${inputValue}-${id}`
              : url}
          </label>
          <button
            onClick={handleQrCodeGenerator}
            className="rounded-xl bg-blue-600 w-55 flex flex-wrap"
          >
            Generate QR Code
          </button>
        </div>

        {qrIsVisible && (
          <div className="qrcode__download" ref={qrCodeRef}>
            {title && (
              <h2 className="mb-1 rounded bg-blue-500 text-white">{title}</h2>
            )}

            <div className="qrcode__image">
              <QRCode
                value={
                  isChecked && inputValue && inputValue.length >= 1
                    ? `${url}/${inputValue}-${id}`
                    : url
                }
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                viewBox={`0 0 256 256`}
              />
            </div>
            {comment && (
              <p className="mb-1 mt-1 rounded bg-blue-500 text-white">
                {comment}
              </p>
            )}
          </div>
        )}
        <button
          onClick={downloadQRCode}
          className="rounded-xl bg-blue-500 w-auto"
        >
          Download QR Code
        </button>
      </div>
    </div>
  );
}

export default QrCodeGenerator;
