import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productCart: [], // Cart items will be stored as objects with id, name, price, quantity, etc.
};

const productCartSlice = createSlice({
  name: "productCart",
  initialState,

  reducers: {
    addToProductCart: (state, action) => {
      const item = action.payload; // The item passed in the payload
      const existingItem = state.productCart.find(
        (cartItem) => cartItem.productId === item.productId
      );

      if (existingItem) {
        // If the item already exists in the cart, increase its quantity
        existingItem.quantity += 1;
      } else {
        // If it's a new item, add it to the cart with an initial quantity of 1
        state.productCart.push({
          ...item,
          quantity: 1,
          uniqueId: `${item.foodItemId}-${new Date().getTime()}`,
        });
      }
    },

    removeFromProductCart: (state, action) => {
      const productId = action.payload; // The id of the item to be removed
      state.productCart = state.productCart.filter(
        (cartItem) => cartItem.productId !== productId
      );
    },

    updateProductCartItemQuantity: (state, action) => {
      const { productId, quantity } = action.payload; // payload contains item id and new quantity
      const item = state.productCart.find(
        (cartItem) => cartItem.productId === productId
      );

      if (item) {
        // Update the quantity, but if the quantity is less than 1, remove the item from the cart
        if (quantity > 0) {
          item.quantity = quantity;
        } else {
          state.productCart = state.cart.filter(
            (cartItem) => cartItem.productId !== productId
          );
        }
      }
    },
    removeAllFromProductCart: (state) => {
      state.productCart = []; // Clears the entire cart
    },
  },
});

export const {
  addToProductCart,
  updateProductCartItemQuantity,
  removeFromProductCart,
  removeAllFromProductCart,
} = productCartSlice.actions;
export default productCartSlice.reducer;
