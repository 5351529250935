import React, {
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
  useRef,
} from "react";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash, FaEdit, FaBoxOpen } from "react-icons/fa";
import { useLazyGetAllFoodItemsAndProductsQuery } from "../../../auth/services/foodItemService";
import "./index.css";
import SearchFilter from "../../utils/SearchFilter";
import { useSelector } from "react-redux";
import { decodeToken } from "../../../utils/decodeToken";
import { getImageUrl } from "../../utils/constants";

const ProductList = () => {
  const token = useSelector((state) => state.auth.userToken);
  const estoreId = decodeToken(token).store;
  const id = estoreId;
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const [filterOptions, setFilterOptions] = useState({
    name: "",
    categoryId: undefined,
    subcategoryId: undefined,
    estoreId: estoreId || id,
    page,
    size,
  });

  const [fetchAllItems, { data, error, isFetching }] =
    useLazyGetAllFoodItemsAndProductsQuery();

  const observer = useRef();
  const lastItemRef = useCallback(
    (node) => {
      if (isFetching || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetching, hasMore]
  );
  useEffect(() => {
    setPage(0); // Reset page when filterOptions change
  }, [filterOptions]);
  // Adjust size dynamically based on screen width
  useLayoutEffect(() => {
    const updateSize = () => {
      if (window.innerWidth >= 1200) {
        setSize(10);
      } else if (window.innerWidth >= 768) {
        setSize(7);
      } else {
        setSize(5);
      }
    };

    updateSize(); // Set initial size
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    fetchAllItems({ ...filterOptions, page }).then((res) => {
      if (res.data) {
        const newItems = [
          ...(res?.data?.foodItems?.content || []),
          ...(res?.data?.products?.content || []),
        ];
        // Merge all items into a Set based on unique ID
        if (page === 0) {
          setItems([...newItems]);
        } else {
          setItems((prev) => {
            const uniqueItems = new Map();

            [...prev, ...newItems].forEach((item) => {
              const key = item.foodItemId || item.productId || item.eventId; // Ensure uniqueness
              uniqueItems.set(key, item);
            });

            return Array.from(uniqueItems.values());
          });
        }
        setHasMore(newItems.length >= size); // If fewer items are returned, stop fetching
      }
    });
  }, [page, filterOptions, fetchAllItems, size]);
  const handleNextPage = () => {
    if (page < data?.totalPages - 1 && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  if (error) return <p>Error loading data: {error.message}</p>;

  return (
    <div className="product-list-wrapper shadow mx-0 px-1 bg-light rounded-3 w-full">
      {/* <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <button onClick={handlePrevPage} disabled={page === 0}>
          Previous
        </button>
        <span>
          Page {page + 1} of {data?.totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={page >= data?.totalPages - 1}
        >
          Next
        </button>
      </div> */}
      <SearchFilter onFilterChange={setFilterOptions} estoreId={estoreId} />
      <div className="">
        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
        <ul className="product-list">
          {items.map((item, index) => (
            <li
              key={index}
              ref={index === items.length - 1 ? lastItemRef : null}
              className="product-item d-flex align-items-center justify-content-between p-2 border-bottom"
            >
              <Link
                to={`product/${item.foodItemId ? "fooditem" : "product"}/${
                  item.foodItemId || item.productId
                }`}
              >
                <img
                  src={getImageUrl(
                    item.image,
                    item?.foodItemId ? "food-item" : "product"
                  )}
                  alt={item.name}
                  className="product-img"
                />
              </Link>
              <div className="product-details flex-grow-1 px-2">
                <Link
                  to={`product/${item.foodItemId ? "fooditem" : "product"}/${
                    item.foodItemId || item.productId
                  }`}
                  className="product-name"
                >
                  {item.name}
                </Link>
                <div className="text-muted">${item.price?.basePrice}</div>
                <div>{item?.subCategory?.name}</div>
              </div>
              <div className="product-actions d-flex">
                <span
                  className={`visibility-icon text-$
                    {item.visibility?.toLowerCase() === "public" ? "green" : "orange"}`}
                >
                  {item.visibility?.toLowerCase() === "public" ? (
                    <FaEye />
                  ) : (
                    <FaEyeSlash />
                  )}
                </span>
                <Link
                  to={`product/edit${
                    item.foodItemId ? "fooditem" : "product"
                  }/${item.foodItemId || item.productId}`}
                  className="px-2"
                >
                  <FaEdit className="text-primary" />
                </Link>
                {item.visibility?.toLowerCase() === "private" && (
                  <FaBoxOpen className="text-danger" />
                )}
              </div>
            </li>
          ))}
        </ul>
        {isFetching && <p></p>}
      </div>
    </div>
  );
};

export default ProductList;
