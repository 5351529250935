import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import "./index.css";
import {
  useLazyFetchEstoreOrdersQuery,
  useUpdateEstoreOrderMutation,
  useUpdateOrderItemMutation,
} from "../../../auth/services/estoreOrderService";
import CompletionDateTimePicker from "../../utils/CompletionDateTime";
import { FaClock } from "react-icons/fa";
import OrderStatusProgression from "../../utils/OrderStatusProgression";
import {
  generatePayload,
  filterNonEmptyValues,
  groupByCategory,
} from "../../utils/cartNormalization";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { isNullOrUndefined } from "@hookform/resolvers";
import Modal from "react-modal";
import { formatPhoneNumber } from "../../../pages/components/formatPhoneNumber";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { createEstoreOrderTab } from "../../../redux/auth/estoreTab";
import { useDispatch, useSelector } from "react-redux";

const EstoreOrderList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tab = useSelector((state) => state?.estoreTab?.estoreTabs || []);
  const estoreTabs = useSelector((state) => state?.estoreTab?.estoreTabs || []);
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [exportedOrderId, setExportedOrderId] = useState(null);
  const [updateOrderItem] = useUpdateOrderItemMutation();
  const [completionDateTime, setCompletionDateTime] = useState(null);
  const [openModalId, setOpenModalId] = useState(null);
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [hasMore, setHasMore] = useState(true);
  const totalPages = useRef(0);
  const [fetchAllItems, { data: estoreOrders, error, isLoading }] =
    useLazyFetchEstoreOrdersQuery({ page, size });
  const handleScroll = debounce(() => {
    //
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100 &&
      hasMore &&
      !isLoading
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  }, 300);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize(
        window.matchMedia("(min-width: 1200px)").matches
          ? 10
          : window.matchMedia("(min-width: 768px)").matches
          ? 7
          : 5
      );
    };
    updateSize();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);
  useEffect(() => {
    fetchAllItems({ page, size }).then((res) => {
      if (res.data) {
        if (page === 0) {
          totalPages.current = Math.max(
            res.data?.totalPages ?? 0,

            res.data?.page?.totalPages ?? 0
          );
        }
        const newItems = [...res?.data?.content];
        setOrders((prevOrders) => {
          const uniqueItems = new Map();
          [...prevOrders, ...newItems]?.forEach((item) => {
            const key = item.estoreOrderId;
            uniqueItems.set(key, item);
          });

          return Array.from(uniqueItems.values());
        });
        setHasMore(res.data?.content?.length > 0);
      }
    });
  }, [page, fetchAllItems, size]);
  const observer = useRef();
  // Intersection Observer for Infinite Scroll
  const lastItemRef = useCallback(
    (node) => {
      if (isLoading || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  const [updateEstoreOrder] = useUpdateEstoreOrderMutation();
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading data.</div>;
  const openModal = (orderId) => {
    setOpenModalId(orderId); // Set the specific order ID for the modal
  };

  const closeModal = () => {
    setOpenModalId(null); // Close the modal
  };

  const handleSaveTime = ({ selectedTime, order }) => {
    setCompletionDateTime(selectedTime); // Set the formatted time string
    handleEstoreOrderStatusUpdate({
      estoreOrderId: order.estoreOrderId,
      completionDateTime: selectedTime,
    });

    closeModal(); // Close modal after saving
  };
  const toggleOrder = (orderId) => {
    setExpandedOrderId((prev) => (prev === orderId ? null : orderId));
  };
  const tabEstoreOrderExists = (orderId) => {
    if (!orderId) return;
    const tb = estoreTabs?.some((t) => t.estoreOrderId === orderId) || false;
    return tb;
  };
  const navigateToEstoreOrderTab = (orderId) => {
    const tab = estoreTabs?.find((t) => t.estoreOrderId === orderId) || "";
    navigate(`tabs/${tab.tabId}`, { state: { tab } });
  };
  const exportOrder = (order) => {
    if (!order) return;

    const updatedItems = [
      ...(Array.isArray(order.orderItems)
        ? order.orderItems
        : [order.orderItems]),
    ];

    const tabId = `tab-${Date.now()}`;
    dispatch(
      createEstoreOrderTab({
        estoreId: order?.estore?.estoreId,
        tabId,
        name: order?.orderItems[0]?.inStoreId || order?.estoreOrderId,
        estoreOrderId: order?.estoreOrderId,
        clientOrder: order?.clientOrder,
        items: updatedItems,
      })
    );
    //alert("here " + tb + " h " + tabEstoreOrderExists(order.estoreOrderId));
    setExportedOrderId();
  };
  const handleEstoreOrderStatusUpdate = async ({
    estoreOrderId,
    updatedStatuses,
    completionDateTime,
    orderItems = [],
  }) => {
    const currentTime = new Date().toISOString();
    //const completionDateTime = new Date().toISOString();
    //const orderItems = [];
    /* alert(
      JSON.stringify({
        updatedStatuses,
        currentTime,
        completionDateTime,
        orderItems,
      })
    ); */
    const dynamicPayload = generatePayload({
      updatedStatuses,
      currentTime,
      completionDateTime,
      orderItems,
    });
    //http://localhost:8080/api/v1/public/auth/estore/orders/103?page=1&size=7
    console.log("Generated Payload:", dynamicPayload);
    //alert(JSON.stringify({ estoreOrderId, data: dynamicPayload }));
    // Example API call
    await updateEstoreOrder({ estoreOrderId, data: dynamicPayload })
      .unwrap()
      .then((response) => {
        console.log("Estore Order update response:", response);
      })
      .catch((error) => {
        console.error("Error updating Estore Order:", error);
      });
  };
  const handleOrderItemStatusUpdate = async ({
    orderItemId,
    updatedStatuses,
  }) => {
    /* 
    const currentTime = new Date().toISOString();
    const payload = generateOrderIemPayload({
      updatedStatuses,
      currentTime,
    });

    try {
      const response = await updateOrderItem({
        orderItemId: orderItemId,
        data: payload,
      }).unwrap();
      console.log("Order Item Update Successful:", response);
    } catch (error) {
      console.error("Error updating order item status:", error);
    } */
  };
  function inStoreId(order) {
    const hasInStore = order?.orderItems?.find((e) => e?.inStoreId != null);
    return hasInStore?.inStoreId;
  }

  function DisplayOrderItems() {
    const order = orders?.find((e) => e?.estoreOrderId === expandedOrderId);
    return (
      <>
        {/* <pre>{JSON.stringify(order, null, 2)}</pre> */}
        <div
          className="flex justify-between items-center h-15 bg-white-500 rounded-xl mb-1"
          style={{
            backgroundColor: "white",
            color: "black",
          }}
        >
          <div className="rounded-xl ml-4">
            <h5
              className="text-lg font-bold rounded-xl"
              style={{
                backgroundColor: "white",
                color: "black",
              }}
            >
              Order #{order?.estoreOrderId} - {inStoreId(order)}
            </h5>

            <p
              className="text-600"
              style={{
                backgroundColor: "white",
                color: "black",
              }}
            >
              {order.clientOrder?.name} -<br />{" "}
              {formatPhoneNumber(order?.clientOrder?.phone)}
            </p>
          </div>

          {tabEstoreOrderExists(order.estoreOrderId) ? (
            <button
              className="text-white-500 text-sm rounded"
              onClick={() => navigateToEstoreOrderTab(order.estoreOrderId)}
            >
              View Tab
            </button>
          ) : (
            <button
              className="text-white-500 text-sm rounded"
              onClick={() => exportOrder(order)}
            >
              Tab
            </button>
          )}

          {/* <pre>{JSON.stringify(estoreTabs, null, 2)}</pre> */}
          <div>
            <div style={{ marginRight: "10px" }}>
              {!isNullOrUndefined(order?.completionDateTime) ? (
                <OrderStatusProgression
                  onStatusUpdate={(updatedStatuses) => {
                    const orderItemIds = order?.orderItems?.map(
                      (item) => item?.orderItemId
                    );
                    handleEstoreOrderStatusUpdate({
                      estoreOrderId: order.estoreOrderId,
                      updatedStatuses,
                      orderItems: orderItemIds,
                    });
                  }}
                  estoreOrder={order}
                />
              ) : (
                <>
                  <p
                    onClick={() => openModal(order.estoreOrderId)}
                    className="mt-2 mr-4 text-xl"
                  >
                    {/* Pickup Time:{" "}
                        {new Date(
                          order?.completionDateTime != null
                        )?.toLocaleString()} */}
                    <FaClock />
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        {/* <pre>{JSON.stringify(order, null, 2)}</pre> */}
        {order?.orderItems != null &&
          order?.orderItems?.map((item, idx) => (
            <div
              key={item.orderItemId}
              className="p-2 border rounded-xl mb-1"
              style={{
                backgroundColor: "white",
                color: "black",
              }}
            >
              <h5 className="font-semibold">
                <span className="font-normal text-sm">
                  {idx + 1}
                  {")"}
                </span>{" "}
                {item?.name} (x
                {item?.quantity})
              </h5>

              {/* <OrderStatusProgression
                      onStatusUpdate={(updatedStatuses) => {
                        handleOrderItemStatusUpdate({
                          orderItemId: item.orderItemId,
                          updatedStatuses,
                          orderItem: item,
                        });
                      }}
                      orderItem={item}
                    /> */}
              <p className="text-600">Unit Price: ${item?.unitPrice}</p>
              {item?.specialMessage && (
                <p className="text-600">
                  Note: {item?.specialMessage || "None"}
                </p>
              )}
              {item?.customizations && (
                <p className="text-600">
                  Customization: {filterNonEmptyValues(item?.customizations)}
                </p>
              )}
            </div>
          ))}
        <p>
          <span className="font-normal text-sm">
            {order?.username && <br />}
            {order?.username}
          </span>
        </p>
      </>
    );
  }
  return (
    <div className="w-full">
      {/* <pre>{JSON.stringify(orders, null, 2)}</pre> */}
      <div className="flex flex-row-reverse">
        <button
          className="text-white-500 text-sm rounded "
          onClick={() => navigate("tabs")}
        >
          My Tabs
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-2">
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h5 className="text-lg font-semibold">Orders Pending</h5>
          <p className="text-2xl font-bold">
            {estoreOrders?.pendingCount || 0}
          </p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h5 className="text-lg font-semibold">Orders Total</h5>
          <p className="text-2xl font-bold">{estoreOrders?.totalCount || 0}</p>
        </div>
      </div>
      <div className="flex w-full">
        <ul className="w-full p-0 m-0 sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 border-none  border-gray-300 rounded-md">
          {orders?.map((order, index) => (
            <li
              key={order.estoreOrderId}
              ref={index === orders.length - 1 ? lastItemRef : null}
              className="my-1 p-1 rounded-lg shadow-md w-full gap-4"
              style={{
                backgroundColor: index % 2 === 0 ? "white" : "grey",
                color: index % 2 === 0 ? "black" : "white",
              }}
            >
              <div className="flex justify-between items-center h-15">
                <div>
                  <h5
                    className="text-lg font-bold"
                    style={{
                      backgroundColor: index % 2 === 0 ? "white" : "grey",
                      color: index % 2 === 0 ? "black" : "white",
                    }}
                  >
                    Order #{order.estoreOrderId} - {inStoreId(order)}
                  </h5>

                  <p
                    className="text-600"
                    style={{
                      backgroundColor: index % 2 === 0 ? "white" : "grey",
                      color: index % 2 === 0 ? "black" : "white",
                    }}
                  >
                    {order.clientOrder?.name} -<br />{" "}
                    {formatPhoneNumber(order?.clientOrder?.phone)}
                  </p>
                </div>
                <div>
                  <button
                    className="text-white-500 text-sm rounded"
                    onClick={() => toggleOrder(order.estoreOrderId)}
                  >
                    {expandedOrderId === order.estoreOrderId
                      ? "Hide Items"
                      : "Show Items"}
                    {expandedOrderId === order.estoreOrderId ? (
                      <>
                        <span className="display-block-right display-none-right ml-1">
                          {" "}
                          <i class="fa-solid fa-arrow-right"></i>
                        </span>
                        <span className="display-block-down display-none-down ml-1">
                          {" "}
                          <i class="fa-solid fa-arrow-down"></i>
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </button>
                  <>
                    {!isNullOrUndefined(order?.completionDateTime) ? (
                      <OrderStatusProgression
                        onStatusUpdate={(updatedStatuses) => {
                          const orderItemIds = order.orderItems.map(
                            (item) => item?.orderItemId
                          );
                          handleEstoreOrderStatusUpdate({
                            estoreOrderId: order.estoreOrderId,
                            updatedStatuses,
                            orderItems: orderItemIds,
                          });
                        }}
                        estoreOrder={order}
                      />
                    ) : (
                      <>
                        <p
                          onClick={() => openModal(order.estoreOrderId)}
                          className="mt-2 ml-4 text-2xl"
                        >
                          {/* Pickup Time:{" "}
                        {new Date(
                          order?.completionDateTime != null
                        )?.toLocaleString()} */}
                          <FaClock />
                        </p>
                      </>
                    )}
                  </>
                </div>
              </div>
              <Modal
                isOpen={openModalId === order.estoreOrderId}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel={`Order ${order.estoreOrderId}`}
              >
                <CompletionDateTimePicker
                  onSave={({ selectedTime, order }) =>
                    handleSaveTime({
                      selectedTime,
                      order,
                    })
                  }
                  value={completionDateTime || ""}
                  order={order} // Pass specific order to the modal
                  onClose={closeModal}
                />
                {/* <button onClick={closeModal}>Close</button> */}
              </Modal>
              {expandedOrderId === order.estoreOrderId && (
                <div
                  className={`w-full  md:hidden lg:hidden xl:hidden mt-4 border-t pt-4 overflow-hidden transition-all duration-300 ${
                    expandedOrderId === order.estoreOrderId
                      ? "max-h-screen"
                      : "max-h-0"
                  }`}
                  style={{ minWidth: "600px" }}
                >
                  {tabEstoreOrderExists(order.estoreOrderId) ? (
                    <button
                      className="text-white-500 text-sm rounded"
                      onClick={() =>
                        navigateToEstoreOrderTab(order.estoreOrderId)
                      }
                    >
                      View Tab
                    </button>
                  ) : (
                    <button
                      className="text-white-500 text-sm rounded"
                      onClick={() => exportOrder(order)}
                    >
                      Tab
                    </button>
                  )}
                  {order?.orderItems != null &&
                    order?.orderItems?.map((item, idx) => (
                      <div
                        key={item.orderItemId}
                        className="p-2 border rounded-md mb-2"
                      >
                        <h4 className="font-semibold">
                          <span className="font-normal text-sm">
                            {idx + 1}
                            {")"}
                          </span>{" "}
                          {item.name} (x{item.quantity})
                        </h4>

                        {/* <OrderStatusProgression
                      onStatusUpdate={(updatedStatuses) => {
                        handleOrderItemStatusUpdate({
                          orderItemId: item.orderItemId,
                          updatedStatuses,
                          orderItem: item,
                        });
                      }}
                      orderItem={item}
                    /> */}
                        <p className="text-600">
                          Unit Price: ${item.unitPrice}
                        </p>
                        {item?.specialMessage && (
                          <p className="text-600">
                            Note: {item?.specialMessage || "None"}
                          </p>
                        )}

                        {item?.customization && (
                          <p className="text-600">
                            Customization:{" "}
                            {item?.customizations && (
                              <p className="text-600">
                                Customization:{" "}
                                {groupByCategory(
                                  filterNonEmptyValues(item?.customizations)
                                )}
                              </p>
                            )}
                          </p>
                        )}
                      </div>
                    ))}

                  <p
                    className="mt-2 text-sm flex flex-wrap gap-2"
                    onClick={() => openModal(order.estoreOrderId)}
                  >
                    Pickup:{" "}
                    {new Date(order.completionDateTime).toLocaleString()}{" "}
                    <FaClock className="text-xl" />
                  </p>
                  <p className="mt-2 text-sm flex flex-wrap">
                    <span className="font-normal text-sm">
                      {order?.username}
                    </span>
                  </p>
                </div>
              )}
            </li>
          ))}
        </ul>
        {/* Div that appears from md and larger */}
        <div className="display-none min-w-md sm:hidden md:block md:w-1/2 lg:w-1/2 xl:w-1/2 bg-white p-1 rounded-xl m-1">
          {/* Content inside the div */}
          {expandedOrderId && <DisplayOrderItems />}
        </div>
      </div>
    </div>
  );
};

export default EstoreOrderList;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    border: "1px solid #ccc",
  },
};
