import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "./pages/index.js";
import About from "./pages/About.js";
import Login from "./pages/Login.js";
import Verify from "./pages/Verify.js";
import SignUp from "./pages/Signup.js";
import UserProfile from "./pages/user/UserProfile.js";
import ProfileScreen from "./pages/user/ProfileScreen.js";
import ProfileEditScreen from "./pages/user/ProfileEditScreen.js";
import Profile from "./pages/Profile.js";
import Dashboard from "./components/Dashboard/index.js";
import NavBar from "./components/NavBar/index";
import CartPage from "./components/Dashboard/CartPage/index.js";
import CartEvent from "./components/Dashboard/CartEvent/index.js";
import CartItemDetails from "./components/Dashboard/CartItemDetails/index.js";
import CartItemEdit from "./components/Dashboard/CartItemEdit/index.js";
import AllergyAdd from "./pages/allergy/AllergyAdd.js";
import AllergyDetails from "./pages/allergy/AllergyDetails.js";
import AllergyList from "./pages/allergy/AllergyList.js";
import EditAllergy from "./pages/allergy/EditAllergy.js";
import EstoreFrontPage from "./pages/EstoreFrontPage/index.js";
import Events from "./pages/EstoreFrontPage/Events.js/index.js";
import UserOrdersPage from "./components/Dashboard/UserOrdersPage/index.js";
import CheckoutPage from "./components/Dashboard/CheckoutPage/index.js";
import ForgotPassword from "./pages/ForgotPassword/index.js";
import ResetPassword from "./pages/ResetPassword/index.js";
import { useDispatch, useSelector } from "react-redux";
import { useGetUserDetailsQuery } from "./auth/services/authService.js";
import { jwtDecode } from "jwt-decode";
import { getUserInfo } from "./redux/auth/userSlice.js";
import { logout, setCredentials } from "./redux/auth/authSlice.js";
import { setAuthToken } from "./utils/apiClient.js";

function ProtectedRoute({ children }) {
  const { userToken } = useSelector((state) => state.auth);
  const location = useLocation();

  if (!userToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
function App() {
  const dispatch = useDispatch();
  const { userToken, refreshToken } = useSelector((state) => state.auth);
  const { data } = useGetUserDetailsQuery(undefined, {
    skip: !userToken,
    pollingInterval: 900000, // 15 minutes
  });

  useEffect(() => {
    if (userToken) {
      try {
        setAuthToken(userToken, refreshToken);
        const decodedToken = jwtDecode(userToken);

        if (decodedToken.exp * 1000 < Date.now()) {
          //alert(JSON.stringify(decodedToken));
          console.error("Token expired");
          dispatch(logout());
          return;
        }

        dispatch(getUserInfo(decodedToken.id));

        if (data) {
          dispatch(setCredentials(data));
        }
      } catch (error) {
        console.error("Invalid token", error);
      }
    }
  }, [userToken, data, dispatch, refreshToken]);
  return (
    <Router>
      <>
        <NavBar />
        <div className="flex justify-center items-center">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />

            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<About />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/signup" element={<SignUp />} />
            {/* <Route path="/settings/:id" element={<Settings />} /> */}
            {/*  <Route path="/settings" element={<Settings />} /> */}
            {/* Protected Routes */}
            <Route
              path="/dashboard/*"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/profiles" element={<Profile />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/profile/:id" element={<ProfileEditScreen />} />
            {/*  <Route path="/dashboard/*" element={<Dashboard />} /> */}
            <Route path="/cart" element={<CartPage />} />
            <Route path="/cartitem/:id" element={<CartItemDetails />} />
            <Route path="/cart-event/:id" element={<CartEvent />} />
            <Route path="/cart-item-edit/:id" element={<CartItemEdit />} />
            <Route path="/store" element={<EstoreFrontPage />} />
            <Route path="/store/:id/events" element={<Events />} />
            <Route path="/store/:id" element={<EstoreFrontPage />} />
            <Route path="/store/:id/:estoreId" element={<EstoreFrontPage />} />
            <Route
              path="/store/:id/:estoreId/:inStoreId"
              element={<EstoreFrontPage />}
            />
            <Route
              path="/allergy"
              element={
                <ProtectedRoute>
                  <AllergyList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/allergy/add"
              element={
                <ProtectedRoute>
                  <AllergyAdd />
                </ProtectedRoute>
              }
            />
            <Route
              path="/allergy/:id"
              element={
                <ProtectedRoute>
                  <AllergyDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/allergy/edit/:id"
              element={
                <ProtectedRoute>
                  <EditAllergy />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cart/check-out"
              element={
                <ProtectedRoute>
                  <CheckoutPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/orders"
              element={
                <ProtectedRoute>
                  <UserOrdersPage />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </>
    </Router>
  );
}

export default App;
function NotFound() {
  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
}
