import React from "react";
import { useNavigate } from "react-router-dom";
import "./index.css"; // Custom styles if needed
import { getImageUrl } from "../../components/utils/constants";
import { ShoppingCartPlus } from "../../components/CartIcons";
import { toOneWord } from "../../components/utils/formatSentenceToWord";
const FoodItem = ({
  foodItem,
  handleAddToFoodItemCart,
  isItemInFoodItemCart,
}) => {
  const navigate = useNavigate();

  return (
    <div /* key={foodItem.foodItemId} */ className="product-card sm:w-full">
      {foodItem?.image && (
        <img
          onClick={() => navigate(`/cartitem/${foodItem.foodItemId}`)}
          src={getImageUrl(foodItem.image, "food-item")}
          alt="food item"
        />
      )}
      {foodItem?.logo && (
        <img
          onClick={() => navigate(`/cartitem/${foodItem.foodItemId}`)}
          src={getImageUrl(foodItem.logo, "food-item")}
          alt="food item"
        />
      )}

      <>
        <div className="price-and-cart">
          <ShoppingCartPlus
            className="add-icon"
            onAdd={() => handleAddToFoodItemCart(foodItem)}
            disabled={isItemInFoodItemCart(foodItem.foodItemId)}
          />
          <p className="price">${foodItem.price.basePrice}</p>
        </div>
        <label className="item-label">
          <span className="calorie-text">
            {foodItem?.calories >= 1 && (
              <p className="calorie-text">({foodItem.calories}cal)</p>
            )}
          </span>
          {foodItem?.name.trim()}
        </label>
      </>

      {foodItem?.estore?.logo && (
        <div
          className="estore-info"
          onClick={() =>
            navigate(`/store/${toOneWord(foodItem?.estore?.name)}`, {
              state: { estore: foodItem?.estore },
            })
          }
        >
          <img
            src={getImageUrl(foodItem?.estore?.logo, "estore")}
            alt="logo"
            className="small-image"
          />
          <div className="estore-name">{foodItem?.estore?.name}</div>
          {/*  <p>{product?.estore.name}</p>
            <pre>{JSON.stringify(product, null, 2)}</pre> */}
        </div>
      )}
    </div>
  );
};

export default FoodItem;
