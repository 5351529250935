import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetSubCategoriesQuery } from "../../../auth/services/subcategoryService";
import {
  useGetAllCategoriesQuery,
  useGetCategoryBySubCategoryIdQuery,
} from "../../../auth/services/categoryService";
import "./index.css";
import OutOfStockCheckbox from "../OutOfStock";
import SpiceLevels from "../SpiceLevel";
import GarnishToppings from "../GarnishToppings";
import LiquidAddons from "../LiquidAddOns";
import SolidAddons from "../SolidAddons";
import Ingredients from "../Ingredients";
import {
  useUpdateFoodItemDetailsMutation,
  useGetFoodItemInfoQuery,
  useDeleteFoodItemMutation,
} from "../../../auth/services/foodItemService";
import { useGetIngredientInfoByEstoreQuery } from "../../../auth/services/ingredientService";
import FoodCourse from "../FoodCourse";
import FoodItemFileUpload from "../../utils/FoodItemUpload";
import AvailableDaysAndHoursEditor from "../../utils/AvailableDaysAndHours";
import { skipToken } from "@reduxjs/toolkit/query";
import DefaultIngredients from "../DefaultIngredients";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; // Import slick styles
import "slick-carousel/slick/slick-theme.css"; // Import slick theme styles

const EditFoodItem = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the food item ID from the URL

  // Retrieve userId and estoreId from Redux store
  const userId = useSelector((state) => state.auth.userInfo.id);
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  const [updateFoodItemDetails] = useUpdateFoodItemDetailsMutation();
  const [deleteFoodItem] = useDeleteFoodItemMutation();

  // Fetch existing food item details to populate the form
  const { data: foodItem, isLoading, error } = useGetFoodItemInfoQuery(id);

  //Fetch Ingredients from backend
  const { data: ingredientSource = [], isLoading: loadingIngredientSource } =
    useGetIngredientInfoByEstoreQuery();
  // State for form inputs
  const [name, setName] = useState("");
  const [cuisineType, setCuisineType] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0.0);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [visibility, setVisibility] = useState("PUBLIC");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [garnishes, setGarnishes] = useState([]);
  const [foodCourses, setFoodCourses] = useState([]);
  const [liquidAddons, setLiquidAddons] = useState([]);
  const [solidAddons, setSolidAddons] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [selectedDefaultIngredients, setSelectedDefaultIngredients] = useState(
    []
  );
  const [spiceLevels, setSpiceLevels] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [productData, setProductData] = useState({
    name: "",
    description: "",
    category: "",
    subCategoryId: selectedSubCategoryId,
    calories: 0,
    price: { basePrice: 0.0, discount: 0.0, currency: "USD" },
    availableDaysAndHours: {
      openPeriodsByDay: [],
    },

    estoreId,
  });

  const {
    data: category,
    isLoading: loadCategory,
    refetch,
  } = useGetCategoryBySubCategoryIdQuery(
    foodItem?.subCategory?.subCategoryId
      ? {
          id: foodItem?.subCategory?.subCategoryId,
        }
      : skipToken
  );
  const { data: categories = [], isLoading: loadingCategories } =
    useGetAllCategoriesQuery();
  const { data: subcategories = [], isLoading: loadingSubcategories } =
    useGetSubCategoriesQuery(
      selectedCategoryId
        ? { categoryId: selectedCategoryId }
        : category?.categoryId
        ? { categoryId: category?.categoryId }
        : skipToken,
      { skip: !selectedCategoryId && !category?.categoryId }
    );
  const handleRefetch = (e) => {
    e.preventDefault();
    refetch(); // This triggers the refetch
  };
  // Prepare combined ingredients from foodItem
  const mapToChecked = useCallback(
    (items) => items.map((item) => ({ name: item.trim(), checked: true })),
    []
  );
  const initialProduct = useCallback(
    (foodItem) => {
      if (foodItem) {
        // Set initial data from fetched food item
        const { category, subCategory, availableDaysAndHours, ...rest } =
          foodItem;
        setProductData({
          ...rest,
          subCategory,
          category,
          availableDaysAndHours,
        });

        //alert(foodItem?.ingredients?.trim()?.split());
        setName((prev) => prev || foodItem.name);
        setCuisineType((prev) => prev || foodItem.cuisine_type);
        setDescription((prev) => prev || foodItem.description);
        setPrice((prev) => prev || foodItem.price.basePrice);
        setDiscount((prev) => prev || foodItem.price.discount);
        setVisibility((prev) => prev || foodItem.visibility);
        setSelectedSubCategoryId(
          (prev) => prev || foodItem.subCategory?.subCategoryId || null
        );

        const {
          garnish = [],
          spiceLevels = [],
          solidAddons = [],
          liquidAddons = [],
          foodCourses = [],
          otherIngredients = [],
        } = foodItem.combinedIngredients || {};

        setGarnishes(mapToChecked(garnish));
        setSpiceLevels(mapToChecked(spiceLevels));
        setSolidAddons(mapToChecked(solidAddons));
        setLiquidAddons(mapToChecked(liquidAddons));
        setFoodCourses(mapToChecked(foodCourses));
        setSelectedIngredients(otherIngredients);
        setSelectedDefaultIngredients(foodItem?.ingredients?.split(",") || []);
      }
    },
    [mapToChecked]
  );
  const initializeIngredients = useCallback(() => {
    // Helper to combine foodItem ingredients with ingredientSource
    if (!loadingIngredientSource && ingredientSource.length === 0) return;
    const mergeIngredients = (source, foodItems) => {
      const sourceMap = source.reduce((map, item) => {
        map[item.name] = { ...item, checked: false };
        return map;
      }, {});

      foodItems.forEach((item) => {
        const itemName = typeof item === "string" ? item : item.name; // Support string or object
        if (sourceMap[itemName]) {
          sourceMap[itemName].checked = true; // Mark as true if found in source
        } else {
          sourceMap[itemName] = { name: itemName, checked: true }; // Add missing items
        }
      });

      return Object.values(sourceMap); // Return unique, merged items
    };

    // Filter ingredientSource by subcategories
    const garnishSource = ingredientSource.filter(
      (item) => item.SubCategory.name === "Garnish/Toppings"
    );
    const spiceSource = ingredientSource.filter(
      (item) => item.SubCategory.name === "Spice Level"
    );
    const solidSource = ingredientSource.filter(
      (item) => item.SubCategory.name === "Solid AddOn"
    );
    const liquidSource = ingredientSource.filter(
      (item) => item.SubCategory.name === "Liquid AddOn"
    );
    const foodCourseSource = ingredientSource.filter(
      (item) => item.SubCategory.name === "Course Type"
    );

    // Merge sources with foodItem ingredients
    setGarnishes(
      mergeIngredients(
        garnishSource,
        foodItem?.combinedIngredients?.garnish || []
      )
    );
    setSpiceLevels(
      mergeIngredients(
        spiceSource,
        foodItem?.combinedIngredients?.spiceLevels || []
      )
    );
    setSolidAddons(
      mergeIngredients(
        solidSource,
        foodItem?.combinedIngredients?.solidAddons || []
      )
    );
    setLiquidAddons(
      mergeIngredients(
        liquidSource,
        foodItem?.combinedIngredients?.liquidAddons || []
      )
    );
    setFoodCourses(
      mergeIngredients(
        foodCourseSource,
        foodItem?.combinedIngredients?.foodCourses || []
      )
    );
    setSelectedIngredients(
      foodItem?.combinedIngredients?.otherIngredients.map((ingredient) => ({
        name: ingredient,
      })) || []
    );
  }, [foodItem, loadingIngredientSource, ingredientSource]);
  useEffect(() => {
    initialProduct(foodItem);
    initializeIngredients();
  }, [
    foodItem,
    initialProduct,
    initializeIngredients,
    loadingIngredientSource,
    mapToChecked,
    ingredientSource,
  ]);
  useEffect(() => {
    if (foodItem && category) {
      // Set initial category and subcategory from food item details
      //setSelectedSubCategoryId(foodItem.subCategory?.subCategoryId || null);
      setSelectedCategoryId(category.categoryId);
    }
  }, [foodItem, category]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "basePrice") {
      setProductData((prevData) => ({
        ...prevData,
        price: { ...prevData.price, basePrice: parseFloat(value) },
      }));
    } else if (name === "calories") {
      setProductData((prevData) => ({
        ...prevData,
        calories: parseFloat(value),
      }));
    } else if (name === "subCategoryId") {
      setProductData((prevData) => ({
        ...prevData,
        subCategoryId: parseInt(value),
      }));
    } else {
      setProductData((prevData) => ({ ...prevData, [name]: value }));
    }
  };
  const combinedIngredients = {
    garnish: garnishes
      .filter((garnish) => garnish.checked)
      .map((garnish) => garnish.name),
    spiceLevels: spiceLevels
      .filter((spice) => spice.checked)
      .map((spice) => spice.name),
    solidAddons: solidAddons
      .filter((solid) => solid.checked)
      .map((solid) => solid.name),
    liquidAddons: liquidAddons
      .filter((liquid) => liquid.checked)
      .map((liquid) => liquid.name),
    otherIngredients: selectedIngredients.map((ingredient) => ingredient.name),
    foodCourses: foodCourses
      .filter((course) => course.checked)
      .map((course) => course.name),
  };
  const handleCategoryChange = (e) => {
    const categoryId = parseInt(e.target.value);
    setSelectedCategoryId(categoryId);
    setSelectedSubCategoryId(null); // Clear subcategory when category changes
  };
  const onDelete = async (e) => {
    e.preventDefault();
    try {
      //console.log(JSON.stringify(data));
      //http://localhost:8080/api/v1/public/auth/foodItem/202

      await deleteFoodItem({ id });
      console.log("Data deleted successfully:");
      navigate("/dashboard");
    } catch (error) {
      alert(JSON.stringify(error));
      console.error("Error updating data:", error);
    }
  };
  const handleSubCategoryChange = (e) => {
    const subCategoryId = parseInt(e.target.value);
    setSelectedSubCategoryId(subCategoryId);
  };
  const handleSetSelectedDefaultIngredients = (selectedDefaultIngredients) => {
    // Update local state of selected ingredients
    setSelectedDefaultIngredients(selectedDefaultIngredients);
  };
  // Submit data to server
  const submitData = async () => {
    const data = {
      //...productData,
      subCategoryId: selectedSubCategoryId,
      calories: productData.calories,
      name,
      image: foodItem.image,
      spice_level:
        "" +
        spiceLevels
          .filter((spice) => spice.checked)
          .map((spice) => spice.name)
          .join(", "), // Format spice levels as a comma-separated string
      cuisine_type: cuisineType,
      price: {
        basePrice: price,
        discount: discount,
        currency: "USD",
      },
      description,
      //days_available: daysAvailable.join(", "), // Comma-separated days
      visibility,
      estoreId,
      userId,
      combinedIngredients,
      ingredients: selectedDefaultIngredients.join(", "), // Comma-separated ingredients

      availableDaysAndHoursDTO: productData?.availableDaysAndHours,
    };
    try {
      //console.log(JSON.stringify(data));
      await updateFoodItemDetails({ id, data }).unwrap();
      console.log("Data updated successfully:");
      navigate("/dashboard");
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitData();
  };
  //
  const handleBackClick = () => {
    navigate("/dashboard");
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
  };

  if (isLoading || loadCategory || loadingCategories || loadingSubcategories) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching food item details</div>;
  }

  return (
    <div className="edit-food-item-container">
      <button className="btn btn-secondary mb-3" onClick={handleBackClick}>
        Back to Dashboard
      </button>
      <h3>Edit Menu Item</h3>
      {/* <pre>{JSON.stringify(foodItem, null, 2)}</pre> */}
      <FoodItemFileUpload image={productData.image} className="w-full" />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="productCategory">Menu Item Category</label>
          <select
            id="category"
            value={selectedCategoryId || ""}
            onChange={handleCategoryChange}
          >
            <option value="">Select Category</option>
            {categories.map((cat) => (
              <option key={cat.categoryId} value={cat.categoryId}>
                {cat.name}
              </option>
            ))}
          </select>

          <label htmlFor="subcategory">Subcategory</label>
          <select
            id="subcategory"
            value={selectedSubCategoryId || ""}
            onChange={handleSubCategoryChange}
            disabled={!selectedCategoryId} // Disable until category is selected
          >
            <option value="">Select Subcategory</option>
            {subcategories.map((sub) => (
              <option key={sub.subCategoryId} value={sub.subCategoryId}>
                {sub.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="product-name">Product Name</label>
          <input
            id="product-name"
            name="product-name"
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="cuisine-type">Cuisine Type</label>
          <input
            id="cuisine-type"
            name="cuisine-type"
            type="text"
            className="form-control"
            value={cuisineType}
            onChange={(e) => setCuisineType(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            className="form-control"
            rows="3"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
        <DefaultIngredients
          selectedDefaultIngredients={selectedDefaultIngredients}
          setSelectedDefaultIngredients={handleSetSelectedDefaultIngredients}
          suggestions={ingredientSource}
        />
        <div className="d-flex w-100">
          <div className="form-group">
            <label htmlFor="price">Price</label>
            <input
              id="price"
              name="price"
              type="number"
              className="form-control"
              value={price}
              onChange={(e) => setPrice(parseFloat(e.target.value))}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="discount">Discount</label>
            <input
              id="discount"
              name="discount"
              type="number"
              className="form-control"
              value={discount}
              onChange={(e) => setDiscount(parseFloat(e.target.value))}
            />
          </div>
          <div className="form-group">
            <label htmlFor="calories">Calories</label>
            <input
              id="calories"
              type="number"
              className="form-control"
              name="calories"
              value={productData.calories}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <p className="italic text-black-500 font-300 mb-0 flex items-center space-x-2">
          Optional Sections for client order customization:
          <button
            onClick={handleRefetch}
            className="flex items-center space-x-1 rounded bg-blue-500"
          >
            <i className="fas fa-sync text-sm"></i>
          </button>
        </p>
        <div className="mt-0">
          <Slider {...settings} className="mt-0">
            <FoodCourse
              foodCourses={foodCourses}
              setFoodCourses={setFoodCourses}
            />
            <GarnishToppings
              garnishes={garnishes}
              setGarnishes={setGarnishes}
            />
            <LiquidAddons
              liquidAddons={liquidAddons}
              setLiquidAddons={setLiquidAddons}
            />
            <SolidAddons
              solidAddons={solidAddons}
              setSolidAddons={setSolidAddons}
            />
            <Ingredients
              selectedIngredients={selectedIngredients}
              setSelectedIngredients={setSelectedIngredients}
              suggestions={ingredientSource}
            />
            <SpiceLevels
              spiceLevels={spiceLevels}
              setSpiceLevels={setSpiceLevels}
            />
          </Slider>
        </div>

        <div className="d-flex mt-5">
          <div className="form-group me-2 w-40">
            <label htmlFor="visibility">Visibility</label>
            <select
              id="visibility"
              name="visibility"
              value={visibility}
              onChange={(e) => setVisibility(e.target.value)}
              className="form-control"
            >
              <option value="PUBLIC">Public</option>
              <option value="PRIVATE">Private</option>
              <option value="UNAVAILABLE">Unavailable</option>
            </select>
          </div>
          <div className="form-group  w-40 ">
            <OutOfStockCheckbox
              isOutOfStock={isOutOfStock}
              setIsOutOfStock={setIsOutOfStock}
            />
          </div>
        </div>
        <AvailableDaysAndHoursEditor
          availableDaysAndHours={
            productData?.availableDaysAndHours?.openPeriodsByDay || []
          }
          onUpdate={(newOpenDaysAndHours) =>
            setProductData((prevProductData) => ({
              ...prevProductData,
              availableDaysAndHours: { openPeriodsByDay: newOpenDaysAndHours },
            }))
          }
        />
        <div className={"containers mx-auto mt-2 w-full px-2 pt-2 lg:w-3/5"}>
          <button type="submit" className="btn btn-primary">
            Update
          </button>
          <button
            onClick={onDelete}
            className="ml-30 btn-secondary !bg-red-500 btn"
          >
            Delete
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditFoodItem;
