import React, { useEffect, useState } from "react";
import "./OpenDaysAndHoursEditor.css";

const DAYS_OF_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const OpenDaysAndHoursEditor = ({
  openDaysAndHours,
  onUpdate,
  canAddPeriod = true,
}) => {
  const [daysAndHours, setDaysAndHours] = useState(
    DAYS_OF_WEEK.map((day) => ({
      dayOfWeek: day,
      openPeriods: [],
    }))
  );

  const [selectedDay, setSelectedDay] = useState("Sunday"); // Default to Sunday

  useEffect(() => {
    if (openDaysAndHours?.length) {
      setDaysAndHours(openDaysAndHours);
    }
  }, [openDaysAndHours]);

  const getSelectedDayData = () =>
    daysAndHours.find((day) => day.dayOfWeek === selectedDay) || {
      dayOfWeek: selectedDay,
      openPeriods: [],
    };

  const handleToggleIsOpen = (periodIndex) => {
    const updatedDaysAndHours = daysAndHours.map((day) => {
      if (day.dayOfWeek === selectedDay) {
        const updatedPeriods = day.openPeriods.map((period, j) =>
          j === periodIndex ? { ...period, open: !period.open } : period
        );
        return { ...day, openPeriods: updatedPeriods };
      }
      return day;
    });
    setDaysAndHours(updatedDaysAndHours);
    onUpdate(updatedDaysAndHours);
  };

  const handleChange = (periodIndex, field, value) => {
    const updatedDaysAndHours = daysAndHours.map((day) => {
      if (day.dayOfWeek === selectedDay) {
        const updatedPeriods = day.openPeriods.map((period, j) =>
          j === periodIndex ? { ...period, [field]: value } : period
        );
        return { ...day, openPeriods: updatedPeriods };
      }
      return day;
    });
    setDaysAndHours(updatedDaysAndHours);
    onUpdate(updatedDaysAndHours);
  };

  const handleAddPeriod = () => {
    const updatedDaysAndHours = daysAndHours.map((day) => {
      if (day.dayOfWeek === selectedDay) {
        return {
          ...day,
          openPeriods: [
            ...day.openPeriods,
            { id: null, openTime: "", closeTime: "", open: true },
          ],
        };
      }
      return day;
    });
    setDaysAndHours(updatedDaysAndHours);
    onUpdate(updatedDaysAndHours);
  };

  const handleRemovePeriod = () => {
    const updatedDaysAndHours = daysAndHours.map((day) => {
      if (day.dayOfWeek === selectedDay) {
        return {
          ...day,
          openPeriods: day.openPeriods.slice(0, -1), // Remove last period
        };
      }
      return day;
    });
    setDaysAndHours(updatedDaysAndHours);
    onUpdate(updatedDaysAndHours);
  };

  return (
    <div>
      <h2>Edit Available Days and Hours</h2>

      {/* Dropdown to select a day */}
      <label>Select a Day: </label>
      <select
        value={selectedDay}
        onChange={(e) => setSelectedDay(e.target.value)}
      >
        {DAYS_OF_WEEK.map((day) => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </select>

      {/* Display the selected day's periods */}
      <div className="day-card w-full">
        <h3>{selectedDay}</h3>

        {getSelectedDayData().openPeriods.map((period, periodIndex) => (
          <div key={periodIndex} className="period-inputs w-full">
            <label>
              Open Time:
              <input
                type="time"
                value={period.openTime}
                onChange={(e) =>
                  canAddPeriod &&
                  handleChange(periodIndex, "openTime", e.target.value)
                }
                className="w-full"
              />
            </label>
            <label>
              Close Time:
              <input
                type="time"
                value={period.closeTime}
                onChange={(e) =>
                  canAddPeriod &&
                  handleChange(periodIndex, "closeTime", e.target.value)
                }
                className="w-full"
              />
            </label>
            <label>
              Open:
              <input
                type="checkbox"
                checked={period.open}
                onChange={() => canAddPeriod && handleToggleIsOpen(periodIndex)}
              />
            </label>
          </div>
        ))}

        {canAddPeriod && (
          <>
            <button type="button" onClick={handleAddPeriod} className="button">
              Add Period
            </button>

            {getSelectedDayData().openPeriods.length > 0 && (
              <button
                type="button"
                onClick={handleRemovePeriod}
                className="button mt-1"
              >
                Remove
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OpenDaysAndHoursEditor;
