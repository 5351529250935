import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./index.css"; // Add custom CSS for the checkout page
import { normalizeCartItem } from "../../utils/cartNormalization";
import { usePostOrderMutation } from "../../../auth/services/userOrderService";
import ErrorDisplay from "../../../pages/components/ErrorDisplay";
import { fetchSafetyMessage } from "../../../auth/services/homeService";
import Modal from "react-modal"; // Import the Modal from react-modal
import { removeAllFromProductCart } from "../../../redux/auth/productCartSlice";
import { removeAllFromFoodItemCart } from "../../../redux/auth/foodItemCartSlice";
const CheckoutPage = () => {
  const dispatch = useDispatch();
  const { foodItemCart } = useSelector((state) => state.foodItemCart);
  const { productCart } = useSelector((state) => state.productCart);
  const inStoreId = useSelector((state) => state.auth?.inStoreId);
  const [postOrder, { isLoading, error, data }] = usePostOrderMutation();
  const [showModalId, setShowModalId] = useState(null);
  const navigate = useNavigate();
  const estoreIds = useMemo(() => {
    return [...foodItemCart, ...productCart].map(
      (item) => item?.estore?.estoreId
    );
  }, [foodItemCart, productCart]);
  const [safetyMessages, setSafetyMessage] = useState([]);
  const [showModal, setShowModal] = useState(true); // State to control modal visibility
  const [shippingDetails, setShippingDetails] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const [paymentDetails, setPaymentDetails] = useState({
    cardNumber: "",
    expirationDate: "",
    cvv: "",
  });

  useEffect(() => {
    if (estoreIds.length === 0) return;
    if (!safetyMessages.length) {
      fetchSafetyMessage(estoreIds)
        .then((response) => {
          setSafetyMessage(response);
          setShowModalId(true);
        })
        .catch((error) => {
          console.error("Error fetching safety messages:", error);
        });
    }
  }, [estoreIds, safetyMessages.length]);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxClick = () => {
    setIsChecked(!isChecked);
    //console.log("Checkbox clicked:", !isChecked);
  };
  const handleInputChange = (e, field, type) => {
    const value = e.target.value;
    if (type === "shipping") {
      setShippingDetails((prev) => ({ ...prev, [field]: value }));
    } else {
      setPaymentDetails((prev) => ({ ...prev, [field]: value }));
    }
  };

  const handlePlaceOrder = async () => {
    try {
      const items = [...foodItemCart, ...productCart].map((item) => {
        return normalizeCartItem(item, inStoreId);
      });
      const orderDetails = {
        items,
        shippingDetails,
        paymentDetails,
        totalPrice:
          foodItemCart.reduce(
            (total, product) =>
              total + product.price.basePrice * product.quantity,
            0
          ) +
          productCart.reduce(
            (total, product) =>
              total + product.price.basePrice * product.quantity,
            0
          ),
      };

      console.log("Order Placed:", items);
      //alert(`Order placed successfully! items: ${JSON.stringify(items)}`);
      const response = await postOrder({ data: items }).unwrap();

      // Handle success (optional)
      dispatch(removeAllFromFoodItemCart());
      dispatch(removeAllFromProductCart());
      alert("Order posted successfully:", response);
    } catch (err) {
      // Handle error
      console.error("Error posting order:", err);
    }
  };

  if (foodItemCart?.length < 1 && productCart?.length < 1) {
    return <label>No products in the cart</label>;
  }

  const closeModal = () => {
    setShowModalId(null);
    setShowModal(false); // Close the modal and allow the user to proceed
  };

  return (
    <div className="checkout-container">
      <h2>Checkout</h2>

      {/* Modal to show Safety Messages */}
      {showModalId && (
        <Modal
          isOpen={showModalId === showModal}
          onRequestClose={closeModal}
          contentLabel="Safety Agreement"
          ariaHideApp={false}
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          {safetyMessages && safetyMessages?.length > 0 && (
            <h3>Safety Messages</h3>
          )}
          <ul>
            {safetyMessages?.map((message, index) => (
              <li key={index}>
                <p>
                  <strong>{message.name}:</strong> {message.foodSafetyMessage}
                </p>
              </li>
            ))}
          </ul>
          <button onClick={closeModal} className="btn-agree">
            I Agree and Proceed
          </button>
        </Modal>
      )}

      <div className="checkout-section">
        <h3>Cart Summary</h3>

        <ul className="checkout-cart-list">
          {[...foodItemCart, ...productCart].map((product) => (
            <li
              key={product.uniqueId || product.productId}
              className="cart-item"
            >
              <div className="cart-item-details">
                <h4>{product.name}</h4>
                <p>Quantity: {product.quantity}</p>
                <p>
                  Price: $
                  {(product.price.basePrice * product.quantity).toFixed(2)}
                </p>
              </div>
            </li>
          ))}
        </ul>
        <h3>
          Total: $
          {(
            foodItemCart.reduce(
              (total, product) =>
                total + product.price.basePrice * product.quantity,
              0
            ) +
            productCart.reduce(
              (total, product) =>
                total + product.price.basePrice * product.quantity,
              0
            )
          ).toFixed(2)}
        </h3>
      </div>

      {/* <div className="checkout-section">
        <h3>Shipping Details</h3>
        <form className="shipping-form">
          <input
            type="text"
            placeholder="Name"
            value={shippingDetails.name}
            onChange={(e) => handleInputChange(e, "name", "shipping")}
          />
          <input
            type="email"
            placeholder="Email"
            value={shippingDetails.email}
            onChange={(e) => handleInputChange(e, "email", "shipping")}
          />
          <input
            type="text"
            placeholder="Address"
            value={shippingDetails.address}
            onChange={(e) => handleInputChange(e, "address", "shipping")}
          />
          <input
            type="text"
            placeholder="City"
            value={shippingDetails.city}
            onChange={(e) => handleInputChange(e, "city", "shipping")}
          />
          <input
            type="text"
            placeholder="State"
            value={shippingDetails.state}
            onChange={(e) => handleInputChange(e, "state", "shipping")}
          />
          <input
            type="text"
            placeholder="ZIP Code"
            value={shippingDetails.zipCode}
            onChange={(e) => handleInputChange(e, "zipCode", "shipping")}
          />
        </form>
      </div> */}

      {/* <div className="checkout-section">
        <h3>Payment Details</h3>
        <form className="payment-form">
          <input
            type="text"
            placeholder="Card Number"
            value={paymentDetails.cardNumber}
            onChange={(e) => handleInputChange(e, "cardNumber", "payment")}
          />
          <input
            type="text"
            placeholder="Expiration Date (MM/YY)"
            value={paymentDetails.expirationDate}
            onChange={(e) => handleInputChange(e, "expirationDate", "payment")}
          />
          <input
            type="text"
            placeholder="CVV"
            value={paymentDetails.cvv}
            onChange={(e) => handleInputChange(e, "cvv", "payment")}
          />
        </form>
      </div> */}
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <input
          type="checkbox"
          id="terms"
          checked={isChecked}
          onChange={handleCheckboxClick}
        />
        <label htmlFor="terms" onClick={() => setShowModalId(true)}>
          Agree to Terms and Conditions
        </label>
      </div>
      {isChecked && (
        <button
          className="place-order-btn"
          onClick={handlePlaceOrder}
          disabled={isLoading}
        >
          {isLoading ? "Submitting..." : " Place Order"}
          {error && <div>{error.message}</div>}
          {data && <div>Order submitted successfully!</div> && navigate("/")}
        </button>
      )}
    </div>
  );
};

export default CheckoutPage;
