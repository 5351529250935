export const createCartItem = ({
  quantity = 1,
  unitPrice = 0,
  specialMessage = "",
  uniqueId = "",
  customizations = "",
  foodItemId = null,
  productId = null,
  inStoreId,
} = {}) => ({
  quantity,
  unitPrice,
  specialMessage,
  uniqueId,
  customizations,
  foodItemId,
  productId,
  inStoreId,
});

export const normalizeCartItem = (foodItem, inStoreId) => {
  return createCartItem({
    name: foodItem?.name,
    quantity: foodItem.quantity || 1,
    unitPrice:
      (foodItem.price?.basePrice || 0) - (foodItem.price?.discount || 0),
    specialMessage: foodItem.speacialMessage || "",
    uniqueId: foodItem?.uniqueId || null,
    customizations: `${JSON.stringify(foodItem?.customizations)}` || null,
    foodItemId: foodItem?.foodItemId || null,
    productId: foodItem?.productId || null,
    inStoreId: inStoreId,
  });
};
export const normalizeEstoreOrderCartItem = (foodItem, estoreId, inStoreId) => {
  return {
    name: foodItem?.name,
    quantity: foodItem.quantity || 1,
    unitPrice:
      (foodItem.price?.basePrice || 0) - (foodItem.price?.discount || 0),
    specialMessage: foodItem.specialMessage || "",
    uniqueId: foodItem?.uniqueId || null,
    customizations: foodItem?.customizations || null,
    foodItemId: foodItem?.foodItemId || null,
    productId: foodItem?.productId || null,
    inStoreId,
    estoreId,
  };
};

/* const addToCartWithValidation = (cartType, item, dispatch) => {
  const normalizedItem = normalizeFoodItemToCartItem(item);

  if (validateCartItem(normalizedItem)) {
    if (cartType === "product") {
      dispatch(addProductToCart(normalizedItem));
    } else if (cartType === "foodItem") {
      dispatch(addFoodItemToCart(normalizedItem));
    }
  } else {
    console.error("Invalid cart item", item);
  }
}; */

export const validateCartItem = (item) => {
  return (
    typeof item.quantity === "number" &&
    typeof item.unitPrice === "number" &&
    typeof item.specialMessage === "string" &&
    typeof item.uniqueId === "string" &&
    typeof item.inStoreId === "string" &&
    typeof item.customizations === "string" &&
    (item.foodItemId === null || typeof item.foodItemId === "number") &&
    (item.productId === null || typeof item.productId === "number")
  );
};
export const filterNonEmptyValues = (obj) => {
  try {
    const parsed = typeof obj === "string" ? JSON.parse(obj.trim()) : obj;

    const filtered = Object.fromEntries(
      Object.entries(parsed).filter(
        ([, value]) =>
          value !== "" && (!Array.isArray(value) || value.length > 0)
      )
    );

    return Object.keys(filtered).length > 0 ? groupByCategory(filtered) : "";
  } catch (error) {
    console.error("Error parsing customizations:", error);
    return ""; // Return an empty string for consistency
  }
};
export const groupByCategory = (customizations) => {
  return Object.entries(customizations).map(([category, items]) => {
    if (Array.isArray(items)) {
      return ` ${category}: ${items
        .map(({ name, qty, side }) => `${name}(${qty}${side ? ", side" : ""})`)
        .join(", ")} `;
    } else if (typeof items === "object" && items !== null) {
      return `${category}: ${items.name}(${items.qty}${
        items.side ? ", side" : ""
      })`;
    }
    return null;
  });
};
export const deepIterate = (item) => {
  if (typeof item === "string") {
    try {
      item = JSON.parse(item); // Convert string to JSON object
    } catch (e) {
      console.error("Invalid JSON string:", e);
      return null;
    }
  }

  if (item instanceof Map) {
    const result = new Map();
    item.forEach((value, key) => {
      const filteredValue = deepIterate(value);
      if (filteredValue !== null) {
        result.set(key, filteredValue);
      }
    });
    return result.size > 0 ? result : null;
  } else if (typeof item === "object" && item !== null) {
    if (Array.isArray(item)) {
      // Filter out empty array objects
      const filteredArray = item.map(deepIterate).filter((v) => v !== null);
      return filteredArray.length > 0 ? filteredArray : null;
    } else {
      const result = {};
      for (let key in item) {
        const filteredValue = deepIterate(item[key]);
        if (
          filteredValue !== null &&
          filteredValue !== "" &&
          !(Array.isArray(filteredValue) && filteredValue.length === 0)
        ) {
          result[key] = filteredValue;
        }
      }
      return Object.keys(result).length > 0 ? result : null;
    }
  }

  return item; // Return primitive values as they are
};

export function generatePayload({
  updatedStatuses,
  currentTime,
  completionDateTime,
  orderItems,
}) {
  const payload = {};

  // Conditionally add status if updatedStatuses is provided
  if (updatedStatuses) {
    const status = {};
    updatedStatuses.forEach((statusKey) => {
      status[statusKey] = currentTime; // Add current timestamp for each status
    });
    payload.status = status;
  }

  // Conditionally add completionDateTime
  if (completionDateTime) {
    payload.completionDateTime = new Date(completionDateTime).toISOString();
  }

  // Conditionally add orderItems
  if (orderItems) {
    payload.orderItems = orderItems.map((orderItemId) => {
      // Assign the status from updatedStatuses to each orderItemId
      const status = {};
      updatedStatuses.forEach((statusKey) => {
        status[statusKey] = currentTime; // Add current timestamp for each status
      });

      return {
        orderItemId,
        status,
      };
    });
  }

  return payload;
}
export function generateOrderIemPayload({ updatedStatuses, currentTime }) {
  const payload = {};

  // Conditionally add status if updatedStatuses is provided
  if (updatedStatuses) {
    const status = {};
    updatedStatuses.forEach((statusKey) => {
      status[statusKey] = currentTime; // Add current timestamp for each status
    });
    payload.status = status;
  }

  return payload;
}
export const extractCustomizationDetails = (customization) => {
  const regex = /\(qty:\s*(\w+),\s*side:\s*(\w+)\)/; // Regex to extract values

  const match = customization.match(regex);
  if (match) {
    return {
      qty: match[1], // Extracted quantity value
      side: match[2] === "true", // Convert "true"/"false" to boolean
    };
  }

  return { qty: "norm", side: false }; // Default values if not found
};
export const parsedCustomizations = (customizations) =>
  Object.fromEntries(
    Object.entries(customizations).map(([key, value]) => {
      if (typeof value === "string") {
        return [key, extractCustomizationDetails(value)];
      } else if (Array.isArray(value)) {
        return [key, value.map((item) => extractCustomizationDetails(item))];
      }
      return [key, value]; // Keep unchanged for non-string, non-array values
    })
  );
