import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
const initialState = {
  estoreTabs: [], // Array of tab objects
};

const estoreTabSlice = createSlice({
  name: "estoreTab",
  initialState,

  reducers: {
    // Create a new tab
    createTab: (state, action) => {
      const { estoreId, tabId, name } = action.payload;
      state.estoreTabs.push({
        estoreId,
        tabId,
        name,
        items: [], // Each tab will have an items array
      });
    },
    // Transfer EstoreOrder to a new tab
    createEstoreOrderTab: (state, action) => {
      const { estoreId, tabId, name, estoreOrderId, clientOrder, items } =
        action.payload;
      const updatedItems = items.map(({ status, ...rest }) => ({
        ...rest,
        uniqueId: uuidv4(), // Assign a new unique ID
      }));
      state.estoreTabs.push({
        estoreId,
        tabId,
        name,
        estoreOrderId,
        clientOrder,
        items: updatedItems, // Each tab will have an items array
      });
    },

    // Edit a tab name
    editTab: (state, action) => {
      const { estoreId, tabId, name } = action.payload;
      const tab = state.estoreTabs.find(
        (t) => t.estoreId === estoreId && t.tabId === tabId
      );
      if (tab) {
        tab.name = name;
      }
    },

    // Add a list of items to a tab
    addItemsToTab: (state, action) => {
      const { estoreId, tabId, items } = action.payload;
      const tab = state.estoreTabs.find(
        (t) => t.estoreId === estoreId && t.tabId === tabId
      );
      if (tab) {
        tab.items.push(
          ...items.map((item) => ({
            ...item,
            quantity: item.quantity || 1, // Ensure quantity exists
            uniqueId: `${item.foodItemId}-${uuidv4()}`,
          }))
        );
      }
    },

    // Remove an item from a tab
    removeItemFromTab: (state, action) => {
      const { estoreId, tabId, uniqueId } = action.payload;
      //alert("here" + estoreId + " h " + tabId + " h " + foodItemId);
      const tab = state.estoreTabs.find(
        (t) => t.estoreId === estoreId && t.tabId === tabId
      );
      if (tab) {
        //alert(JSON.stringify(tab));
        tab.items = tab.items.filter((item) => item.uniqueId !== uniqueId);
      }
    },

    // Edit an item inside a tab
    editItemInTab: (state, action) => {
      const { estoreId, tabId, uniqueId, updatedFields } = action.payload;
      const tab = state.estoreTabs.find(
        (t) => t.estoreId === estoreId && t.tabId === tabId
      );
      if (tab) {
        const item = tab.items.find((item) => item.uniqueId === uniqueId);
        if (item) {
          Object.assign(item, updatedFields);
        }
      }
    },
    // Add a list of product items to a tab
    addProductItemsToTab: (state, action) => {
      const { estoreId, tabId, items } = action.payload;
      const tab = state.estoreTabs.find(
        (t) => t.estoreId === estoreId && t.tabId === tabId
      );
      if (tab) {
        tab.items.push(
          ...items.map((item) => ({
            ...item,
            quantity: item.quantity || 1, // Ensure quantity exists
            uniqueId: `${item.productId}-${uuidv4()}`,
          }))
        );
      }
    },
    addToItemVariantCart: (state, action) => {
      const { estoreId, tabId, item } = action.payload;
      const tab = state.estoreTabs.find(
        (t) => t.estoreId === estoreId && t.tabId === tabId
      );
      if (tab) {
        tab.items.push({
          ...item,
          quantity: 1,
          uniqueId: `${item?.foodItemId}-${uuidv4()}`,
        });
      }
    },
    incrementItemQuantity: (state, action) => {
      const { estoreId, tabId, uniqueId } = action.payload;
      const tab = state.estoreTabs.find(
        (t) => t.estoreId === estoreId && t.tabId === tabId
      );
      if (tab) {
        const item = tab.items.find((item) => item.uniqueId === uniqueId);
        if (item) {
          item.quantity = (item.quantity || 1) + 1;
        }
      }
    },

    decrementItemQuantity: (state, action) => {
      const { estoreId, tabId, uniqueId } = action.payload;
      const tab = state.estoreTabs.find(
        (t) => t.estoreId === estoreId && t.tabId === tabId
      );
      if (tab) {
        const item = tab.items.find((item) => item.uniqueId === uniqueId);
        if (item) {
          if (item.quantity > 1) {
            item.quantity -= 1;
          } else {
            tab.items = tab.items.filter((i) => i.uniqueId !== uniqueId);
          }
        }
      }
    },

    incrementProductQuantity: (state, action) => {
      const { estoreId, tabId, uniqueId } = action.payload;
      const tab = state.estoreTabs.find(
        (t) => t.estoreId === estoreId && t.tabId === tabId
      );
      if (tab) {
        const product = tab.items.find((item) => item.funiqueId === uniqueId);
        if (product) {
          product.quantity = (product.quantity || 1) + 1;
        }
      }
    },

    decrementProductQuantity: (state, action) => {
      const { estoreId, tabId, productId } = action.payload;
      const tab = state.estoreTabs.find(
        (t) => t.estoreId === estoreId && t.tabId === tabId
      );
      if (tab) {
        const product = tab.items.find((item) => item.productId === productId);
        if (product) {
          if (product.quantity > 1) {
            product.quantity -= 1;
          } else {
            tab.items = tab.items.filter((p) => p.productId !== productId);
          }
        }
      }
    },
    // Remove a product item from a tab
    removeProductItemFromTab: (state, action) => {
      const { estoreId, tabId, foodItemId } = action.payload;
      const tab = state.estoreTabs.find(
        (t) => t.estoreId === estoreId && t.tabId === tabId
      );
      if (tab) {
        tab.items = tab.items.filter((item) => item.foodItemId !== foodItemId);
      }
    },

    // Edit an product item inside a tab
    editProductItemInTab: (state, action) => {
      const { estoreId, tabId, foodItemId, updatedFields } = action.payload;
      const tab = state.estoreTabs.find(
        (t) => t.estoreId === estoreId && t.tabId === tabId
      );
      if (tab) {
        const item = tab.items.find((item) => item.foodItemId === foodItemId);
        if (item) {
          Object.assign(item, updatedFields);
        }
      }
    },
    // Delete a tab completely
    deleteTab: (state, action) => {
      const { estoreId, tabId } = action.payload;
      state.estoreTabs = state.estoreTabs.filter(
        (t) => t.estoreId !== estoreId || t.tabId !== tabId
      );
    },
  },
});

export const {
  createTab,
  createEstoreOrderTab,
  editTab,
  addItemsToTab,
  removeItemFromTab,
  editItemInTab,
  editProductItemInTab,
  removeProductItemFromTab,
  addProductItemsToTab,
  addToItemVariantCart,
  incrementItemQuantity,
  decrementItemQuantity,
  incrementProductQuantity,
  decrementProductQuantity,
  deleteTab,
} = estoreTabSlice.actions;
export default estoreTabSlice.reducer;
