import React, { useState } from "react";
import "./index.css";
import { createTab, editTab } from "../../../redux/auth/estoreTab";
import { useDispatch, useSelector } from "react-redux";
import { decodeToken } from "../../../utils/decodeToken";
import { useNavigate } from "react-router-dom";

const Tabs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const estoreTabs = useSelector((state) => state.estoreTab.estoreTabs);
  const token = useSelector((state) => state.auth.userToken);
  const estoreId = decodeToken(token).store;
  const [title, setTitle] = useState("");
  const [selectedTabs, setSelectedTabs] = useState([]);
  // Handle tab selection
  const toggleSelectTab = (tabId) => {
    setSelectedTabs((prevSelected) =>
      prevSelected.includes(tabId)
        ? prevSelected.filter((id) => id !== tabId)
        : [...prevSelected, tabId]
    );
  };
  const mergeTabs = () => {
    if (selectedTabs.length < 2) return; // Ensure at least two tabs are selected

    // Combine items from selected tabs
    const mergedItems = selectedTabs.flatMap(
      (tabId) => estoreTabs.find((tab) => tab.tabId === tabId)?.items || []
    );

    // Create a new merged tab
    const newTab = {
      tabId: Date.now(), // Unique ID
      name: "Merged Tab",
      items: mergedItems,
    };
  };
  // Create a new tab
  const handleAddTab = () => {
    if (!title) return;
    const tabId = `tab-${Date.now()}`;
    dispatch(createTab({ estoreId, tabId, name: title }));
    setTitle("");
  };

  // Edit a tab name
  const handleEditTab = (tabId, newName) => {
    dispatch(editTab({ estoreId, tabId, name: newName }));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {/* Tab Creation */}
      <div className={"w-100 md:w-2/3 flex flex-wrap"}>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Tab Name"
          className={"w-1/2 md:w-1/4"}
        />
        <button
          onClick={handleAddTab}
          style={{
            borderRadius: "100%",
            marginTop: "3px",
            background: "rgb(45, 127, 161)",
          }}
        >
          +
        </button>
        {selectedTabs.length > 1 && (
          <button onClick={mergeTabs} disabled={selectedTabs.length < 2}>
            Merge Selected Tabs
          </button>
        )}
      </div>
      {/* Render Tabs */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "2px",
          width: "100%",
        }}
      >
        {estoreTabs.map((tab) => (
          <div
            key={tab.tabId}
            /* className="border border-black p-2 w-full max-w-[150px] rounded-lg m-1 box-border" */
            style={{
              border: "1px solid black",
              padding: "10px",
              width: "100%", // Make sure the tab fits within the parent
              maxWidth: "150px", // Ensures each tab does not exceed its intended width
              borderRadius: "10px",
              margin: "4px",
              boxSizing: "border-box", // Prevents padding from increasing width
              wordWrap: "break-word", // Prevents text from causing overflow
            }}
            className={selectedTabs.includes(tab.tabId) ? "shaking" : ""}
          >
            <input
              type="checkbox"
              checked={selectedTabs.includes(tab.tabId)}
              onChange={() => toggleSelectTab(tab.tabId)}
            />
            {/* Tab Title */}
            <span
              style={{
                width: "100%", // Ensures the input does not exceed the parent
                maxWidth: "inherit", // Restricts input to fit within the div
                boxSizing: "border-box", // Ensures padding doesn't affect width
              }}
            >
              <input
                type="text"
                value={tab.name}
                onChange={(e) => handleEditTab(tab.tabId, e.target.value)}
                style={{
                  width: "100%", // Ensures the input does not exceed the parent
                  maxWidth: "100%", // Restricts input to fit within the div
                  boxSizing: "border-box", // Ensures padding doesn't affect width
                  marginLeft: "-6px",
                }}
                className="w-full max-w-full box-border md:w-full lg:w-full"
              />
            </span>
            {/* <p>Tab ID: {tab.tabId}</p> */}

            {/* Items in Tab */}
            {tab.items.length > 0 ? (
              <div
                onClick={() => navigate(`${tab.tabId}`, { state: { tab } })}
                className={"w-full md:3/4"}
              >
                <p>{tab?.items[0]?.name}</p>
                <p>Items: {tab?.items?.length}</p>
                {/* <pre>{JSON.stringify(tab?.items[0], null, 2)}</pre> */}
                {/* <button
                  onClick={() => navigate(`${tab.tabId}`, { state: { tab } })}
                >
                  View
                </button> */}
              </div>
            ) : (
              <p onClick={() => navigate(`${tab.tabId}`, { state: { tab } })}>
                No items added
              </p>
            )}
            {/* <p className="ellipsis-btn">
              <i class="fa-solid fa-ellipsis-vertical"></i>
            </p> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
