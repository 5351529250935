import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import authReducer from "./auth/authSlice";
import cartReducer from "./auth/cartSlice";
import passwordRecoveryReducer from "./auth/passwordRecoverySlice";
import foodItemCartReducer from "./auth/foodItemCartSlice";
import estoreTabReducer from "./auth/estoreTab";
import foodItemReducer from "./auth/foodItemSlice";
import productCartReducer from "./auth/productCartSlice";
import productReducer from "./auth/productSlice";
import userReducer from "./auth/jwtSlice";
import { authApi } from "../auth/services/authService";
import counterReducer from "./features/counterSlice";
import estoreReducer from "./auth/estoreSlice";
import { foodItemApi } from "../auth/services/foodItemService";
import { allergyApi } from "../auth/services/allergyService";
import { specialEventApi } from "../auth/services/specialEventService";
import { subcategoryApi } from "../auth/services/subcategoryService";
import { categoryApi } from "../auth/services/categoryService";
import { productApi } from "../auth/services/productService";
import { ingredientApi } from "../auth/services/ingredientService";
import { estoreApi } from "../auth/services/estoreService";
import { estoreUserApi } from "../auth/services/estoreUserService";
import { userOrdersApi } from "../auth/services/userOrderService";
import { estoreOrdersApi } from "../auth/services/estoreOrderService";
import { homeRTKServiceApi } from "../auth/services/homeRTKService";

// Define persist config for the auth slice
const persistConfig = {
  key: "root",
  storage,
};

// Create a persisted reducer for auth
const persistedAuthReducer = persistReducer(persistConfig, authReducer);

const appReducer = combineReducers({
  // Add other reducer slice
  passwordRecovery: passwordRecoveryReducer,
  estoreTab: estoreTabReducer, // Adding the slice reducer
  estore: estoreReducer, // Adding the slice reducer
  user: userReducer,
  cart: cartReducer,
  productCart: productCartReducer,
  product: productReducer,
  foodItem: foodItemReducer,
  foodItemCart: foodItemCartReducer,
  counter: counterReducer,
  auth: persistedAuthReducer,
  // Add API reducers RTK
  [homeRTKServiceApi.reducerPath]: homeRTKServiceApi.reducer, // Adding the API reducer
  [estoreApi.reducerPath]: estoreApi.reducer, // Adding the API reducer
  [estoreUserApi.reducerPath]: estoreUserApi.reducer, // Adding the API reducer
  [foodItemApi.reducerPath]: foodItemApi.reducer,
  [allergyApi.reducerPath]: allergyApi.reducer,
  [specialEventApi.reducerPath]: specialEventApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [subcategoryApi.reducerPath]: subcategoryApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [ingredientApi.reducerPath]: ingredientApi.reducer,
  [userOrdersApi.reducerPath]: userOrdersApi.reducer,
  [estoreOrdersApi.reducerPath]: estoreOrdersApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
});

// Higher-order reducer to reset state on logout
const rootReducer = (state, action) => {
  if (action.type === "auth/logout") {
    // Clear persisted storage and reset Redux state
    storage.removeItem("persist:root"); // Clear persisted state
    return appReducer(undefined, action); // Reset state to initial state
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(authApi.middleware)
      .concat(allergyApi.middleware)
      .concat(specialEventApi.middleware)
      .concat(categoryApi.middleware)
      .concat(subcategoryApi.middleware)
      .concat(foodItemApi.middleware)
      .concat(productApi.middleware)
      .concat(ingredientApi.middleware)
      .concat(userOrdersApi.middleware)
      .concat(estoreOrdersApi.middleware)
      .concat(estoreUserApi.middleware)
      .concat(homeRTKServiceApi.middleware)
      .concat(estoreApi.middleware),
});

// Create the persistor
const persistor = persistStore(store);

export { store, persistor };
