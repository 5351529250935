import React from "react";
import "./index.css"; // Custom styles if needed
import { getImageUrl } from "../../components/utils/constants";
import { ShoppingCartPlus } from "../../components/CartIcons";
const FoodItemEstore = ({
  foodItem,
  handleAddItemToTab,
  isItemInFoodItemCart,
}) => {
  return (
    <div /* key={foodItem.foodItemId} */ className="product-card">
      {foodItem?.image && (
        <img src={getImageUrl(foodItem.image, "food-item")} alt="food item" />
      )}
      {foodItem?.logo && (
        <img src={getImageUrl(foodItem.logo, "food-item")} alt="food item" />
      )}

      <>
        <div className="price-and-cart">
          <ShoppingCartPlus
            className="add-icon"
            onAdd={() => handleAddItemToTab(foodItem)}
            disabled={isItemInFoodItemCart(foodItem.foodItemId)}
          />
          <p className="price">${foodItem.price.basePrice}</p>
        </div>
        <label className="item-label">
          <span className="calorie-text">
            {foodItem?.calories >= 1 && (
              <p className="calorie-text">({foodItem.calories}cal)</p>
            )}
          </span>
          {foodItem?.name.trim()}
        </label>
      </>

      {foodItem?.estore?.logo && (
        <div className="estore-info">
          <img
            src={getImageUrl(foodItem?.estore?.logo, "estore")}
            alt="logo"
            className="small-image"
          />
          <div className="estore-name">{foodItem?.estore?.name}</div>
          {/*  <p>{product?.estore.name}</p>
            <pre>{JSON.stringify(product, null, 2)}</pre> */}
        </div>
      )}
    </div>
  );
};

export default FoodItemEstore;
