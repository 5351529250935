import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetSubCategoryQuery,
  useCreateSubCategoryMutation,
  useUpdateSubCategoryMutation,
} from "../../../auth/services/subcategoryService"; // Adjust the path as necessary
import { useGetAllCategoriesQuery } from "../../../auth/services/categoryService";
const SubCategoryForm = () => {
  const { categoryId, id } = useParams(); // Assuming categoryId is passed in the URL for subcategory creation
  const [name, setName] = useState("");
  const [categories, setCategories] = useState([]); // For the category dropdown
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    parseInt(categoryId) || 0
  ); // Default to categoryId from URL
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Fetch subcategory if an ID is provided
  const { data: categoryData, error: fetchError1 } = useGetAllCategoriesQuery();

  const { data: subCategoryData, error: fetchError } = useGetSubCategoryQuery(
    { id },
    {
      skip: !id,
    }
  );

  const [createSubCategory] = useCreateSubCategoryMutation();
  const [updateSubCategory] = useUpdateSubCategoryMutation();

  useEffect(() => {
    if (subCategoryData) {
      setName(subCategoryData.name);
    }
    if (categoryData) {
      setCategories(categoryData);
    }
  }, [subCategoryData, categoryData]);

  useEffect(() => {
    if (fetchError && fetchError1) {
      setError("Error fetching subcategory or category data");
    }
  }, [fetchError, fetchError1]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      name,
      categoryId: parseInt(selectedCategoryId, 10), // Send category ID as the parent reference
    };
    //
    alert(JSON.stringify(payload));
    try {
      if (id) {
        await updateSubCategory({ id, payload }).unwrap();
      } else {
        await createSubCategory({ categoryId, payload }).unwrap();
      }
      navigate(
        `/dashboard/product/categories/${payload.categoryId}/subcategories`
      );
    } catch (err) {
      setError("Error saving subcategory - " + err?.data?.message);
    }
  };

  return (
    <div className="w-full sm:w-3/4 md:w-1/2 lg:w-2/5 xl:w-2/5 border border-gray-300 p-2 rounded-md">
      <h1>{id ? "Edit SubCategory" : "Add SubCategory"}</h1>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="w-full ">
          <label>Parent Category:</label>
          <select
            className="h-9 w-40"
            value={selectedCategoryId}
            onChange={(e) => setSelectedCategoryId(e.target.value)}
            required
          >
            <option type="number">Select Category</option>
            {categories.map((category) => (
              <option
                key={category.categoryId}
                value={parseInt(category.categoryId)}
              >
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Name:</label>
          <input
            className="w-full mb-3"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="rounded-xl">
          Save
        </button>
      </form>
    </div>
  );
};

export default SubCategoryForm;
