import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useGetAllAllergiesQuery } from "../../../auth/services/allergyService";
import { getImageUrl } from "../../utils/constants";
import { getFoodItemInfo } from "../../../auth/services/homeService";
import { addToFoodItemCart } from "../../../redux/auth/foodItemCartSlice";
const CartItemDetails = ({ onAddToCart }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { foodItemCart } = useSelector((state) => state.foodItemCart);
  const [itemData, setItemData] = useState();
  const [error, setError] = useState();
  // Fetch food item data using the provided ID
  const { data: allergies } = useGetAllAllergiesQuery();

  useEffect(() => {
    getFoodItemInfo(id)
      .then((response) => {
        setItemData(response?.data);
      })
      .catch((er) => setError(er.message));
  }, [id]);
  function getAllergiesInIngredients(ingredients, allergiesList) {
    // Filter the allergies whose names match any of the ingredients
    const lowerCaseIngredients = ingredients?.toLowerCase();

    return allergiesList
      ?.filter((allergy) =>
        lowerCaseIngredients?.includes(allergy.name?.toLowerCase())
      )
      .map((allergy) => allergy.name); // Return only the names
  }
  const allergyNames = getAllergiesInIngredients(
    itemData?.ingredients,
    allergies
  );
  //const ingArray = itemData?.ingredients ? foodItem.ingredients.split(" ") : [];
  // Show loading message while data is being fetched
  // Helper function to check if the product is already in the cart
  const isItemInFoodItemCart = (productId) => {
    return foodItemCart?.some((cartItem) => cartItem?.foodItemId === productId);
  };

  // Add product foodItem to cart handler
  const handleAddToFoodItemCart = (product) => {
    dispatch(addToFoodItemCart(product)); // Dispatch the action to add product to cart
  };

  // Handle errors
  if (error) {
    return <p>Error loading food item details.</p>;
  }
  return (
    <div style={styles.container} className="w-full">
      {/* <pre>{JSON.stringify(itemData, null, 2)}</pre> */}
      <h2>{itemData?.name}</h2>
      <img
        src={getImageUrl(itemData?.image, "food-item")}
        alt={itemData?.name}
        style={styles.image}
      />

      <div style={styles.infoSection}>
        <p>
          <strong>Price:</strong> ${itemData?.price?.basePrice}{" "}
          {itemData?.price?.discount > 0 &&
            `Discount: $${itemData?.price?.discount}`}{" "}
          {itemData?.price?.currency}
        </p>
        <p className="w-full sm:w-full md:w-3/4 lg:w-3/5 xl:w-3/5">
          <strong>Description:</strong> {itemData?.description}
        </p>

        <p>
          <strong>Ingredients:</strong> {itemData?.ingredients}{" "}
        </p>
        <p>
          <strong>Allergies:</strong>{" "}
          {allergyNames?.length > 0 ? (
            <span style={{ color: "red" }}>{allergyNames.join(", ")}</span>
          ) : (
            "None"
          )}
        </p>

        {/* Combined Ingredients */}

        <div style={styles.combinedIngredientsSection}>
          {/* <h3>Combined Ingredients</h3> */}
          <p>
            <strong>Garnish:</strong>{" "}
            {itemData?.combinedIngredients?.garnish?.join(", ")}
          </p>
          <p>
            <strong>Spice Levels:</strong>{" "}
            {itemData?.combinedIngredients?.spiceLevels?.join(", ")}
          </p>
          <p>
            <strong>Solid Addons:</strong>{" "}
            {itemData?.combinedIngredients?.solidAddons?.join(", ")}
          </p>
          <p>
            <strong>Liquid Addons:</strong>{" "}
            {itemData?.combinedIngredients?.liquidAddons?.join(", ")}
          </p>
          <p>
            <strong>Other Ingredients:</strong>{" "}
            {itemData?.combinedIngredients?.otherIngredients?.join(", ")}
          </p>
          <p>
            <strong>Food Courses:</strong>{" "}
            {itemData?.combinedIngredients?.foodCourses?.join(", ")}
          </p>
        </div>

        {/* Estore and User Info */}
        <div style={styles.additionalInfo}>
          <p>
            <strong>Estore:</strong> {itemData?.estore?.name}
          </p>
        </div>

        {/* Add to Cart Button */}
        <button
          style={styles.addToCartButton}
          onClick={() => handleAddToFoodItemCart(itemData)}
          disabled={isItemInFoodItemCart(itemData?.foodItemId)}
        >
          Add to Cart
        </button>
        <button
          style={styles.addToCartButton}
          onClick={() => navigate(`/cart-item-edit/${itemData?.foodItemId}`)}
        >
          Customize
        </button>
      </div>
    </div>
  );
};

// Basic Styles
const styles = {
  container: {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "20px",
    maxWidth: "100vw",
    margin: "20px auto",
    width: "80vw",
  },
  image: {
    width: "100%",
    height: "auto",
    maxHeight: "300px",
    objectFit: "cover",
    borderRadius: "8px",
    marginBottom: "15px",
  },
  infoSection: {
    fontSize: "16px",
    lineHeight: "1.6",
  },
  combinedIngredientsSection: {
    marginTop: "20px",
    paddingTop: "10px",
    borderTop: "1px solid #ddd",
  },
  additionalInfo: {
    marginTop: "20px",
  },
  addToCartButton: {
    marginTop: "20px",
    padding: "10px 15px",
    backgroundColor: "#28a745",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  },
};

export default CartItemDetails;
