import React, { useState, useEffect } from "react";
import { useForgotPasswordMutation } from "../../auth/services/authVerfiyApi";
import { useNavigate } from "react-router-dom";
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [forgotPassword, { isLoading, isError, isSuccess, error }] =
    useForgotPasswordMutation();
  useEffect(() => {
    if (isSuccess) setTimeout(() => navigate("/"), 5000);
  }, [isSuccess, navigate]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    await forgotPassword(email);
  };

  return (
    <div className="w-full sm:w-3/4 md:w-1/2 lg:w-2/5 xl:w-2/5 border border-gray-300 p-2 rounded-md">
      <h2>Forgot Password?</h2>
      <form onSubmit={handleSubmit} className="w-full">
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full mb-3"
          required
        />
        <button type="submit" disabled={isLoading} className={"rounded-xl"}>
          Request Reset Link
        </button>
      </form>

      {isSuccess && <p>Email sent! Check your inbox.</p>}
      {isError && <p>Error: {error?.data}</p>}
    </div>
  );
};

export default ForgotPassword;
