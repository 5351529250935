import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useVerifyMutation } from "../auth/services/authVerfiyApi"; // Adjust based on your API setup

const Verify = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const verifyCode = searchParams.get("code");

  const [verify, { isLoading, isError, error }] = useVerifyMutation();
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (verifyCode) {
      verify({ code: verifyCode }) // Calls the backend
        .unwrap() // Unwraps the response (Promise)
        .then((response) => {
          console.log(response); // Logs the backend response
          setMessage(response.message); // Set the success message
          setTimeout(() => navigate("/login"), 2000); // Redirect to login
        })
        .catch((err) => {
          console.error(err);
          setMessage(
            err?.data?.message || "Verification failed. Please try again."
          );
          setTimeout(() => navigate("/login"), 3000); // Redirect home on failure
        });
    }
  }, [verifyCode, verify, navigate]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h2 className="text-xl font-semibold">
        {isLoading
          ? "Verifying your account..."
          : message || "Processing verification..."}
      </h2>
      {isError && (
        <p className="text-red-500">
          Error: {error?.data?.message || "Something went wrong"}
        </p>
      )}
    </div>
  );
};

export default Verify;
