import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./index.css"; // Import the CSS file
import { useCreateIngredientWithNameMutation } from "../../../auth/services/ingredientService"; // Adjust the path if needed
import { useNavigate } from "react-router-dom";

const SolidAddons = ({ solidAddons, setSolidAddons }) => {
  const navigate = useNavigate();
  const [newSolidAddon, setNewSolidAddon] = useState("");
  const [createIngredientWithName] = useCreateIngredientWithNameMutation();

  // Retrieve userId and estoreId from Redux store
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  // Handle adding a new solid addon
  const addSolidAddon = async (event) => {
    event.preventDefault();
    if (newSolidAddon.trim()) {
      const updatedSolidAddons = [
        ...solidAddons,
        { name: newSolidAddon.trim(), checked: true },
      ];
      setSolidAddons(updatedSolidAddons);

      try {
        await createIngredientWithName({
          name: newSolidAddon.trim(),
          subCategoryId: solidAddons[0]?.SubCategory?.subCategoryId, // Adjust if needed
          estoreId: estoreId,
        });
        setNewSolidAddon(""); // Clear the input field
      } catch (error) {
        console.error("Error adding ingredient:", error);
        setSolidAddons((prevCourses) =>
          prevCourses.filter((course) => course.name !== newSolidAddon.trim())
        );
      }
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedSolidAddons = solidAddons.map((addon, i) =>
      i === index ? { ...addon, checked: !addon.checked } : addon
    );
    setSolidAddons(updatedSolidAddons);
  };

  return (
    <div className="solid-addon-container">
      <h3 className="solid-addon-title">Solid Add-ons</h3>
      <ul className="solid-addon-list">
        {solidAddons.map((addon, index) => (
          <li className="solid-addon-list-item" key={index}>
            <label
              htmlFor={`solid-addon-checkbox-${index}`}
              style={styles.checkboxLabel}
            >
              {addon.name}
              <input
                id={`solid-addon-checkbox-${index}`} // Unique id for each checkbox
                name={`solid-addon-checkbox-${index}`}
                type="checkbox"
                /* className="solid-addon-input" */
                checked={addon.checked}
                onChange={(e) => {
                  e.preventDefault();
                  handleCheckboxChange(index);
                }}
                style={styles.input}
              />
            </label>
          </li>
        ))}
      </ul>
      <div>
        <input
          id={`solid-addon-input`}
          name={`solid-addon-input`}
          type="text"
          className="solid-addon-input"
          value={newSolidAddon}
          onChange={(e) => {
            e.preventDefault();
            setNewSolidAddon(e.target.value);
          }}
          placeholder="Add new solid addon"
        />
        {solidAddons?.length > 0 ? (
          <button
            className="solid-addon-button"
            onClick={(e) => {
              e.preventDefault();
              addSolidAddon(e);
            }}
          >
            Add
          </button>
        ) : (
          <button
            className="course-food-button"
            onClick={(e) => navigate("/dashboard/product/addingredient")}
          >
            Add Content
          </button>
        )}
      </div>
    </div>
  );
};

export default SolidAddons;
const styles = {
  checkboxLabel: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
    justifyItems: "center",
    width: "auto",
    fontSize: "14px",
    flexDirection: "row-reverse",
    gap: "-1rem",
  },
  input: {
    //margin: 0,
    marginLeft: "-0.90em",
    padding: 0,
    width: "16px",
    height: "16px",
    marginRight: "2px",
  },
};
