import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEstoreList } from "../../redux/auth/estoreSlice";
import { getCurrentUser, setDefaultEstore } from "../../redux/auth/userSlice"; // Assume you have an action for this
import Spinner from "../../components/Spinner";
import Company from "./Company";
import { decodeToken } from "../../utils/decodeToken";

const CompanyList = ({ refreshEstoreList }) => {
  const dispatch = useDispatch();
  const { estoreList, isLoading, error } = useSelector((state) => state.estore);
  const defaultEstore = decodeToken(
    useSelector((state) => state.auth?.userToken)
  )?.store;

  useEffect(() => {
    if (estoreList?.length === 0 && !isLoading && !error) {
      dispatch(fetchEstoreList());
    }
  }, [dispatch, estoreList?.length, isLoading, error]);
  useEffect(() => {
    if (defaultEstore) {
      dispatch(getCurrentUser());
    }
  }, [defaultEstore, dispatch]);

  // Handle the selection of a company
  const handleActivate = async (estoreId) => {
    try {
      await dispatch(setDefaultEstore(estoreId)).unwrap(); // Wait for setDefaultEstore to finish
      await dispatch(getCurrentUser()).unwrap(); // Fetch updated user data
    } catch (error) {
      console.error("Failed to update default store:", error);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-wrap justify-center gap-4">
        {Array.isArray(estoreList) &&
          estoreList?.map((company) => (
            <Company
              key={company.estoreId}
              company={company}
              //onSelect={handleSelect}
              onActivate={() => handleActivate(company.estoreId)}
            />
          ))}
      </div>
    </div>
  );
};

export default CompanyList;
