// src/components/FileUpload.js
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateSpecialEventLogo } from "../../redux/auth/specialEventSlice";
import "./FileUpload.css";
import { getImageUrl } from "./constants";
const SpecialEventsFileUpload = ({ id, image }) => {
  //const { id2 } = useParams();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [progress, setProgress] = useState(0);

  function handleFileChange(e) {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setImgUrl(URL?.createObjectURL(selectedFile));
      setProgress(0); // Reset progress when new file is selected
    }
  }

  const submitData = async () => {
    if (file) {
      setProgress(10); // Start with a small progress value

      // Simulate upload progress
      const fakeUpload = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(fakeUpload);
            return 100;
          }
          return prev + 20;
        });
      }, 500);

      await dispatch(updateSpecialEventLogo({ id, file }));
    }
    setTimeout(() => setProgress(0), 1000);
  };

  return (
    <div className="file-upload-container">
      {/* <label>Logo</label> */}
      <h2>Edit/Add Image:</h2>
      <input
        type="file"
        onChange={(e) => handleFileChange(e)}
        className="w-50"
      />
      {imgUrl && (
        <div className="mt-4">
          <img
            src={imgUrl}
            alt="logo"
            className="w-32 h-auto object-cover rounded"
          />
        </div>
      )}
      {image && <img src={getImageUrl(image, "specialEvents")} alt="logo" />}

      <button onClick={submitData}>Update Logo</button>
      {progress > 0 && (
        <div className="mt-4 w-full">
          <div className="relative h-2 w-full bg-gray-200 rounded">
            <div
              className="absolute h-2 bg-blue-500 rounded transition-all"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <p className="text-center text-sm mt-1">{progress}%</p>
        </div>
      )}
    </div>
  );
};

export default SpecialEventsFileUpload;
