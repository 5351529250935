import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { getFoodItemInfo } from "../../../auth/services/homeService.js";
import { getImageUrl } from "../../utils/constants.js";
import CheckboxWithRadio from "../../../pages/components/CheckboxWithRadio/index.js";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  addToFoodItemCart,
  updateFoodItemCartItem,
} from "../../../redux/auth/foodItemCartSlice.js";
import { FaPlusCircle } from "react-icons/fa";
import "./index.css";
import ErrorDisplay from "../../../pages/components/ErrorDisplay.js";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { Pagination } from "swiper/modules";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #ccc",
    background: "rgba(255, 255, 255, 1)",
  },
};

const CartItemEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams(); // Get foodItem ID from the URL
  const { foodItemId, uniqueId } = useLocation().state || {};
  const [foodItem, setFoodItem] = useState();
  const [chefNote, setChefNote] = useState("");
  const { foodItemCart } = useSelector((state) => state.foodItemCart);
  // Find the item in the cart that matches the foodItemId and uniqueId
  const cartItem = foodItemCart.find(
    (item) => item.foodItemId === foodItemId && item.uniqueId === uniqueId
  );

  const [error, setError] = useState();
  const [openModalId, setOpenModalId] = useState(null);
  const [customizationMap, setCustomizationMap] = useState(new Map());
  const categories = [
    { label: "Garnish", field: "garnish" },
    { label: "Spice Levels", field: "spiceLevels" },
    { label: "Protein", field: "solidAddons" },
    { label: "Liquid Base", field: "liquidAddons" },
    { label: "Other Items", field: "otherIngredients" },
  ];

  const [selectedItems, setSelectedItems] = useState({
    garnish: [],
    spiceLevels: "",
    solidAddons: [],
    liquidAddons: "",
    otherIngredients: [],
  });
  let subtitle;
  const [modalContent, setModalContent] = useState({
    category: "",
    item: "",
    name: "",
    qty: "norm",
    side: false,
  });

  function openModal(category, item) {
    // Get the existing customization for the category
    const categoryCustomizations = cartItem.customizations?.[category];

    // Check if it's an array or an object
    let existingCustomization = Array.isArray(categoryCustomizations)
      ? categoryCustomizations.find((entry) => entry.name === item)
      : categoryCustomizations?.name === item
      ? categoryCustomizations
      : null;
    setModalContent({
      category,
      item: item,
      name: item,
      qty: existingCustomization?.qty || "norm", // Default to "norm" if not found
      side: existingCustomization?.side || false, // Default to false if not found
    });

    setOpenModalId(`${category}-${item}`);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setOpenModalId(null); // Close all modals
  }
  useEffect(() => {
    // If the item is found in the cart
    if (cartItem) {
      if (cartItem?.specialMessage) {
        setChefNote(cartItem?.specialMessage);
      }

      if (cartItem.customizations) {
        const newCustomizationMap = new Map();

        for (const [key, value] of Object.entries(cartItem.customizations)) {
          newCustomizationMap.set(key, value);
        }
        setCustomizationMap(newCustomizationMap);

        // Ensure selectedItems is properly set from customizations
        setSelectedItems({
          garnish: Array.isArray(cartItem.customizations.garnish)
            ? cartItem.customizations.garnish.map((item) => item.name)
            : [],
          spiceLevels: cartItem.customizations.spiceLevels?.name || "",
          solidAddons: Array.isArray(cartItem.customizations.solidAddons)
            ? cartItem.customizations.solidAddons.map((item) => item.name)
            : [],
          liquidAddons: cartItem.customizations.liquidAddons?.name || "",
          otherIngredients: Array.isArray(
            cartItem.customizations.otherIngredients
          )
            ? cartItem.customizations.otherIngredients.map((item) => item.name)
            : [],
        });
      }
    }
  }, [cartItem, cartItem?.customizations]);

  useEffect(() => {
    getFoodItemInfo(id)
      .then((res) => {
        setFoodItem(res.data);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [id]);

  const handleSelectionChange = (category, value) => {
    if (
      category === "otherIngredients" ||
      category === "garnish" ||
      category === "solidAddons"
    ) {
      setSelectedItems((prevSelected) => {
        const updatedItems = [...prevSelected[category]];
        if (updatedItems.includes(value)) {
          updatedItems.splice(updatedItems.indexOf(value), 1);
        } else {
          updatedItems.push(value);
        }
        return {
          ...prevSelected,
          [category]: updatedItems,
        };
      });
    } else {
      setSelectedItems((prevSelected) => ({
        ...prevSelected,
        [category]: value,
      }));
    }
  };

  const handleUpdateCustomization = (category, itemName, updates) => {
    // Step 1: Create a copy of the current customization map
    const updatedMap = new Map(customizationMap);

    // Step 2: Get the existing customizations for the category (if they exist)
    const existingCustomizations = updatedMap.get(category);

    if (Array.isArray(existingCustomizations)) {
      // Step 3: If it's an array (e.g., `garnish`), find the correct item to update
      const updatedItems = existingCustomizations.map((entry) =>
        entry.name === itemName ? { ...entry, ...updates } : entry
      );

      updatedMap.set(category, updatedItems);
    } else if (
      existingCustomizations &&
      existingCustomizations.name === itemName
    ) {
      // Step 4: If it's an object (e.g., `spiceLevels`), update directly
      updatedMap.set(category, { ...existingCustomizations, ...updates });
    } else {
      // Step 5: If the category doesn't exist, initialize it
      const newEntry = { name: itemName, qty: "norm", side: false, ...updates };

      updatedMap.set(
        category,
        Array.isArray(existingCustomizations)
          ? [...existingCustomizations, newEntry]
          : newEntry
      );
    }

    // Step 6: Update the customizationMap state
    setCustomizationMap(updatedMap);

    // Step 7: Update modal content if necessary
    setModalContent((prevModalContent) => ({
      ...prevModalContent,
      updates,
    }));
  };

  // Checkbox change handler
  const handleCheckboxChange = (isChecked, item, category) => {
    // handleUpdateCustomization(item, category, { side: isChecked });
    handleUpdateCustomization(item, category, { side: isChecked });
    setModalContent((prev) => ({
      ...prev,
      side: isChecked,
    }));
  };

  // Radio change handler
  const handleRadioChange = (qty, item, category) => {
    handleUpdateCustomization(item, category, { qty });
    setModalContent((prev) => ({
      ...prev,
      qty,
    }));
    //handleUpdateCustomization(item, category, { qty });
  };

  // Add product foodItem to cart handler
  const handleAddToFoodItemCart = (product) => {
    // Get the existing customizations (if any) or initialize an empty object
    const previousCustomizations = cartItem?.customizations || {};

    // Create a new customizations object
    const updatedCustomizations = Object.entries(selectedItems).reduce(
      (acc, [category, items]) => {
        if (!items || (Array.isArray(items) && items.length === 0)) return acc; // Ignore empty selections

        // Convert previous items to a map for quick lookup
        const previousCategoryItems = Array.isArray(
          previousCustomizations[category]
        )
          ? previousCustomizations[category]
          : [];

        const previousItemsMap = new Map(
          previousCategoryItems.map((item) => [item.name, item])
        );

        const updatedItems = Array.isArray(items)
          ? items.map((item) => {
              const existingCustomization = previousItemsMap.get(item);
              const newCustomization = customizationMap.get(item);

              return {
                name: item,
                qty:
                  newCustomization?.qty || existingCustomization?.qty || "norm",
                side:
                  newCustomization?.side ??
                  existingCustomization?.side ??
                  false,
              };
            })
          : (() => {
              const existingCustomization = previousItemsMap.get(items);
              const newCustomization = customizationMap.get(items);

              return {
                name: items,
                qty:
                  newCustomization?.qty || existingCustomization?.qty || "norm",
                side:
                  newCustomization?.side ??
                  existingCustomization?.side ??
                  false,
              };
            })();

        return { ...acc, [category]: updatedItems };
      },
      {}
    );

    // Ensure that only selected items are included (removing deselected ones)
    Object.keys(previousCustomizations).forEach((category) => {
      if (!updatedCustomizations[category]) {
        delete updatedCustomizations[category]; // Remove categories with no selected items
      }
    });

    const updatedFields = {
      customizations: updatedCustomizations, // ✅ Now removes deselected items
      specialMessage: chefNote || "",
    };

    delete updatedFields?.availableDaysAndHours; // Remove unnecessary fields

    if (cartItem?.uniqueId) {
      dispatch(
        updateFoodItemCartItem({
          uniqueId: cartItem?.uniqueId,
          ...cartItem,
          ...updatedFields,
        })
      );
    } else {
      dispatch(addToFoodItemCart({ ...foodItem, ...updatedFields }));
    }
    navigate(-1);
  };

  if (error) return <ErrorDisplay error={error} />;
  return (
    <div style={styles.container}>
      {/* <pre>{JSON.stringify(cartItem, null, 2)}</pre>
      <pre>{JSON.stringify(customizationMap, null, 2)}</pre> */}
      <div style={styles.headers}>
        <img src={getImageUrl(foodItem?.image, "food-item")} alt="logo" />
        <h5>
          <strong>{foodItem?.name}</strong>
        </h5>
        <p style={{ fontSize: ".8rem" }}>Customize:</p>
      </div>

      {/* Slick Carousel for Customization Categories */}
      <Swiper
        style={{
          height: "300px",
          background: "white",
          color: "black",
          zIndex: 0,
        }}
        slidesPerView={1}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {categories?.map((category, index) => {
          const items = foodItem?.combinedIngredients[category?.field] || [];
          if (items.length === 0) return null; // Skip empty categories

          return (
            <SwiperSlide key={index}>
              <span>
                <h6>
                  <strong>{category.label}</strong>
                </h6>
              </span>
              {items.map((item, itemIndex) => (
                <div key={itemIndex} style={styles.radioLabelContainer}>
                  {["otherIngredients", "garnish", "solidAddons"].includes(
                    category.field
                  ) ? (
                    <label
                      style={styles.checkboxLabel}
                      className="w-auto space-y-1"
                    >
                      {item}
                      <input
                        type="checkbox"
                        name={category.field}
                        value={item}
                        style={styles.input}
                        checked={selectedItems[category.field].includes(item)}
                        onChange={() =>
                          handleSelectionChange(category.field, item)
                        }
                      />
                    </label>
                  ) : (
                    <label style={styles.radioLabel} className="w-auto">
                      <input
                        type="radio"
                        name={category.field}
                        value={item}
                        style={styles.input}
                        checked={selectedItems[category.field] === item}
                        onChange={() =>
                          handleSelectionChange(category.field, item)
                        }
                      />
                      {item}
                    </label>
                  )}
                </div>
              ))}
            </SwiperSlide>
          );
        })}
      </Swiper>

      {/* <Slider {...settings}>
        {categories.map((category, index) => {
          const items = foodItem?.combinedIngredients[category?.field] || [];
          if (items.length === 0) return null; // Skip empty categories
          return (
            <div key={index} style={styles.carouselSlide}>
              <h6>
                <strong>{category.label}</strong>
              </h6>
              {items?.map((item, itemIndex) => (
                <div key={itemIndex} style={styles.radioLabelContainer}>
                  {["otherIngredients", "garnish", "solidAddons"].includes(
                    category.field
                  )
                    ? category?.field &&
                      item && (
                        <label
                          key={itemIndex}
                          style={styles.checkboxLabel}
                          className="w-auto"
                        >
                          {item}
                          <input
                            type="checkbox"
                            name={category.field}
                            value={item}
                            style={styles.input}
                            checked={selectedItems[category.field].includes(
                              item
                            )}
                            onChange={() =>
                              handleSelectionChange(category.field, item)
                            }
                          />
                        </label>
                      )
                    : category?.field &&
                      item && (
                        <label
                          key={itemIndex}
                          style={styles.radioLabel}
                          className="w-auto"
                        >
                          <input
                            type="radio"
                            name={category.field}
                            value={item}
                            style={styles.input}
                            checked={selectedItems[category.field] === item}
                            onChange={() =>
                              handleSelectionChange(category.field, item)
                            }
                          />
                          {item}
                        </label>
                      )}
                </div>
              ))}
            </div>
          );
        })}
      </Slider> */}

      {/* Selected Items Summary */}
      <div style={styles.selectionSummary}>
        <h6>
          <strong>Selected Customizations</strong>
        </h6>
        <ul
          style={{ display: "flex", flexWrap: "wrap", gap: "10px", padding: 0 }}
        >
          {Object.entries(selectedItems)?.map(
            ([category, items]) =>
              items.length > 0 && (
                <li
                  key={
                    category + (Array.isArray(items) ? items.join(",") : items)
                  }
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "90%",
                    maxWidth: "100%",
                    boxSizing: "border-box",
                    overflow: "hidden",
                  }}
                >
                  <strong>
                    {categories.map(
                      (i) => i.field.includes(category) && i.label
                    )}
                    :
                  </strong>

                  {/* Handle if category is array or "otherIngredients" */}
                  {/* {category === "otherIngredients" ? */}
                  {Array.isArray(items) ? (
                    // Handle case for "otherIngredients" which is an array
                    items?.map((item) => (
                      <div key={category + items + item}>
                        <div style={styles.rowItem}>
                          <button
                            className="label"
                            /* className="dark-left label" */
                            onClick={() => openModal(category, item)}
                          >
                            {item}
                            {/* <HoverText originalText={item} hoverText="yes" /> */}
                          </button>
                        </div>
                        <Modal
                          isOpen={
                            openModalId === `${category}-${modalContent.item}`
                          }
                          onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel={`${modalContent.category} - ${modalContent.item}`}
                        >
                          <div style={styles.rowItem}>
                            <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                              {categories.map(
                                (i) =>
                                  i.field.includes(modalContent.category) &&
                                  i.label
                              )}
                            </h2>
                            <button className="rounded-xl" onClick={closeModal}>
                              close
                            </button>
                          </div>
                          <div>
                            Selected Item: {modalContent.item}
                            <CheckboxWithRadio
                              label={modalContent.item}
                              initialQty={modalContent.qty}
                              initialSide={modalContent.side}
                              onRadioChange={(qty) =>
                                handleRadioChange(
                                  qty,
                                  modalContent.item,
                                  modalContent.category
                                )
                              }
                              onCheckboxChange={(isChecked) =>
                                handleCheckboxChange(
                                  isChecked,
                                  modalContent.item,
                                  modalContent.category
                                )
                              }
                            />
                          </div>
                        </Modal>
                      </div>
                    ))
                  ) : (
                    <div>
                      <div style={styles.rowItem}>
                        <button
                          /* className="label dark-left" */
                          className="label"
                          onClick={() => openModal(category, items)}
                        >
                          {items}
                          {/* <HoverText originalText={items} hoverText="yes" /> */}
                        </button>
                      </div>
                      <Modal
                        isOpen={
                          openModalId === `${category}-${modalContent.item}`
                        }
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel={`${modalContent.category} - ${modalContent.item}`}
                      >
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                              {categories.map(
                                (i) =>
                                  i.field.includes(modalContent.category) &&
                                  i.label
                              )}
                            </h2>
                            <button onClick={closeModal} className="rounded-xl">
                              close
                            </button>
                          </div>
                          <div>
                            Selected Item: {modalContent?.item}
                            <CheckboxWithRadio
                              label={modalContent.item}
                              initialQty={modalContent.qty} // Pass initial quantity
                              initialSide={modalContent.side} // Pass initial side state
                              onRadioChange={(qty) =>
                                handleRadioChange(
                                  qty,
                                  modalContent.item,
                                  modalContent.category
                                )
                              }
                              onCheckboxChange={(isChecked) =>
                                handleCheckboxChange(
                                  isChecked,
                                  modalContent.item,
                                  modalContent.category
                                )
                              }
                            />
                          </div>
                        </>
                      </Modal>
                    </div>
                  )}
                </li>
              )
          )}
        </ul>
        <label style={styles.label}>
          Chef Note:
          <textarea
            style={styles.textarea}
            id="specialMessage"
            name="specialMessage"
            maxLength="100"
            rows={4}
            cols={40}
            value={chefNote || ""}
            onChange={(e) => setChefNote(e.target.value)}
            placeholder={
              " Allergies / Intolerants if any please indicate them here"
            }
          ></textarea>
        </label>
        <div style={styles.rowItem}>
          <button className="btn btn-1" style={styles.rowItem}>
            <FaPlusCircle style={{ marginRight: "8px" }} />
            cancel
          </button>
          <button className="btn btn-2" style={styles.rowItem}>
            <FaPlusCircle style={{ marginRight: "8px" }} />
            reset Item
          </button>
          <button
            style={styles.rowItem}
            className="btn btn-4"
            onClick={() => handleAddToFoodItemCart(foodItem)}
          >
            <FaPlusCircle style={{ marginRight: "8px" }} />
            save to cart
          </button>
          {/* <h3>Customization Map:</h3>
          <pre>
            {JSON.stringify(Object.fromEntries(customizationMap), null, 2)}
          </pre> */}
        </div>
      </div>
    </div>
  );
};

// Basic Styles
const styles = {
  headers: {
    justifyItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "5px",
    justifyContent: "space-between",
    width: "100%", // Full width by default (small screens)
    maxWidth: "100%", // Prevents overflow
    boxSizing: "border-box", // Ensures padding doesn't increase width

    // Responsive Design
    "@media (minWidth: 768px)": {
      // Medium screens (tablets)
      maxWidth: "75%", // ¾ width
    },
    "@media (minWidth: 1024px)": {
      // Large screens (desktops)
      maxWidth: "50%", // ½ width
    },
  },
  carouselSlide: {
    padding: "10px",
  },
  radioLabelContainer: {
    display: "flex",
    flexDirection: "row",
    justifyItems: "flex-start",
    gap: "10px",
    width: "100%",
    marginLeft: "40px",
    alignContents: "center",
    marginBottom: "10px",
  },
  radioLabel: {
    display: "flex",
    alignContents: "center",
    marginBottom: "10px",
    justifyItems: "center",
    width: "150px",
    fontSize: "14px",
    lineHeight: "1.5",
  },
  rowContainer: {
    display: "flex", // Align the container items in a row
    alignItems: "center", // Center the items vertically
    alignContents: "center",
    alignSelf: "center",
    gap: "1px", // Add some space between items
  },
  rowItem: {
    display: "flex",
    flexWrap: "wrap", // Allows items to wrap within the container
    fontSize: "11px",
    marginLeft: "5px",
    marginRight: "5px",
    padding: "5px",
    justifyContent: "space-between",
    maxWidth: "100%", // Prevents exceeding the parent container
    boxSizing: "border-box", // Ensures padding/margins don’t cause overflow
    overflow: "hidden", // Prevents any accidental overflow
  },
  checkboxLabel: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
    justifyItems: "center",
    width: "auto",
    fontSize: "14px",
    flexDirection: "row-reverse",
    gap: "-1rem",
  },
  input: {
    margin: 0,
    marginLeft: "-0.90em",
    padding: 0,
    width: "16px",
    height: "16px",
  },
  label: {
    display: "block", // Makes the label a block element, causing it to take up the full width and push the textarea below.
    marginBottom: "5px", // Adds a small space between the label and textarea.
    fontWeight: "bold", // Makes the label text bold for better visibility.
    fontSize: ".85rem",
  },
  textarea: {
    border: "1px solid black", // Adds a black border to the textarea.
    marginRight: "10px", // Adds space to the right side of the textarea.
    width: "100%", // Ensures the textarea takes up the full width of its container.
    padding: "8px", // Adds padding inside the textarea for better readability.
    boxSizing: "border-box", // Ensures the padding is included in the width of the textarea.
  },
  selectionSummary: {
    marginTop: "20px",
    paddingTop: "10px",
    borderTop: "1px solid #ddd",
  },
};

export default CartItemEdit;
