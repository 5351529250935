import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../components/utils/constants";

export const subcategoryApi = createApi({
  reducerPath: "subcategoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.userToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
        return headers;
      }
    },
  }),
  endpoints: (builder) => ({
    getSubCategory: builder.query({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const { id } = args;

        const result = await fetchWithBQ({
          url: `/subcategory/${id}`,
          method: "GET",
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),

    getSubCategories: builder.query({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const { categoryId } = args;
        const result = await fetchWithBQ({
          url: `/subcategory/category/${categoryId}`,
          method: "GET",
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),
    //
    updateSubCategory: builder.mutation({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const { id, payload } = args;

        const result = await fetchWithBQ({
          url: `/subcategory/${id}`,
          method: "PATCH",
          body: {
            ...payload,
          },
        });

        return result.error ? { error: result.error } : { data: result.data };
      },
    }),

    createSubCategory: builder.mutation({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const { defaultEstore } = getState().auth.userInfo;
        const { payload } = args;
        //alert(JSON.stringify(args));
        const result = await fetchWithBQ({
          url: `/subcategory/category/${payload.categoryId}`,
          method: "POST",
          body: {
            ...payload,
            estoreId: defaultEstore, // Append state value
          },
        });

        return result.error ? { error: result.error } : { data: result.data };
      },
    }),
    deleteSubCategory: builder.mutation({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const { id } = args;

        const result = await fetchWithBQ({
          url: `/subcategory/${id}`,
          method: "DELETE",
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),
  }),
});

export const {
  useGetSubCategoryQuery,
  useGetSubCategoriesQuery,
  useUpdateSubCategoryMutation,
  useCreateSubCategoryMutation,
  useDeleteSubCategoryMutation,
} = subcategoryApi;
