import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateEstoreLogo } from "../../redux/auth/estoreSlice";
import "./FileUpload.css";

const EstoreFileUpload = () => {
  const dispatch = useDispatch();
  const { defaultEstore } = useSelector((state) => state.auth.userInfo);
  const [file, setFile] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [progress, setProgress] = useState(0);

  function handleFileChange(e) {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setImgUrl(URL.createObjectURL(selectedFile));
      setProgress(0); // Reset progress when new file is selected
    }
  }

  const submitData = async () => {
    if (!file) return;

    setProgress(10); // Start with a small progress value

    // Simulate upload progress
    const fakeUpload = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(fakeUpload);
          return 100;
        }
        return prev + 20;
      });
    }, 500);

    await dispatch(updateEstoreLogo({ id: defaultEstore, file }));

    setTimeout(() => setProgress(0), 1000); // Hide progress bar after completion
  };

  return (
    <div className="file-upload-container w-full p-4 border rounded shadow-sm bg-white">
      <label className="block font-semibold mb-2">Logo</label>
      <input
        type="file"
        onChange={handleFileChange}
        className="block w-full border p-2 rounded"
      />

      {imgUrl && (
        <div className="mt-4">
          <img
            src={imgUrl}
            alt="logo"
            className="w-32 h-auto object-cover rounded"
          />
        </div>
      )}

      <button
        onClick={submitData}
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Update Logo
      </button>

      {progress > 0 && (
        <div className="mt-4 w-full">
          <div className="relative h-2 w-full bg-gray-200 rounded">
            <div
              className="absolute h-2 bg-blue-500 rounded transition-all"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <p className="text-center text-sm mt-1">{progress}%</p>
        </div>
      )}
    </div>
  );
};

export default EstoreFileUpload;
