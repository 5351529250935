import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../components/utils/constants";

export const specialEventApi = createApi({
  reducerPath: "specialEventApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.token; // Assuming the token is stored in Redux under auth slice
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Create a new special event
    createSpecialEvent: builder.mutation({
      query: (specialEventRequest) => ({
        url: "/special-event",
        method: "POST",
        body: specialEventRequest,
      }),
    }),

    // Update a special event
    updateSpecialEvent: builder.mutation({
      query: ({ id, specialEventRequest }) => ({
        url: `/special-event/${id}`,
        method: "PATCH",
        body: specialEventRequest,
      }),
    }),

    // Delete a special event
    deleteSpecialEvent: builder.mutation({
      query: ({ id }) => ({
        url: `/special-event/${id}`,
        method: "DELETE",
      }),
    }),

    // Get a special event by ID
    getSpecialEventById: builder.query({
      query: ({ id }) => ({
        url: `/special-event/${id}`,
        method: "GET",
      }),
    }),
    // Get special events by estore ID
    getSpecialEventsByEstoreId: builder.query({
      queryFn: async (_, { getState }, _extraOptions, fetchWithBQ) => {
        const { defaultEstore } = getState().auth.userInfo;
        const result = await fetchWithBQ({
          url: `/special-event/estore/${defaultEstore}`,
          method: "GET",
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),

    // Get special events by estore ID and date-time
    getSpecialEventsByEstoreIdAndDateTime: builder.query({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const dateTime = args;
        const { defaultEstore } = getState().auth.userInfo;
        const result = await fetchWithBQ({
          url: `/special-event/estore/${defaultEstore}/datetime/${dateTime}`,
          method: "GET",
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),

    // Get special events by date
    getSpecialEventsByDate: builder.query({
      queryFn: async (args, _extraOptions, fetchWithBQ) => {
        const dateTime = args;

        const result = await fetchWithBQ({
          url: `/special-event/datetime/${dateTime}`,
          method: "GET",
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),

    // Get all special events
    getAllSpecialEvents: builder.query({
      query: () => `/special-event/`,
    }),
  }),
});

export const {
  useCreateSpecialEventMutation,
  useUpdateSpecialEventMutation,
  useDeleteSpecialEventMutation,
  useGetSpecialEventByIdQuery,
  useGetSpecialEventsByEstoreIdQuery,
  useGetSpecialEventsByEstoreIdAndDateTimeQuery,
  useGetSpecialEventsByDateQuery,
  useGetAllSpecialEventsQuery,
} = specialEventApi;
