// Import RTK Query utilities
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../components/utils/constants";
// Create the API slice using RTK Query
export const estoreOrdersApi = createApi({
  reducerPath: "estoreOrdersApi",
  // A unique key for this API slice in the Redux store
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL, // Base URL of the backend API
    prepareHeaders: (headers, { getState }) => {
      // Retrieve token from Redux store
      const token = getState()?.auth?.userToken;
      // Add the token to the headers if it exists
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Endpoint for fetching past estore orders
    fetchEstoreOrders: builder.query({
      queryFn: async (
        { page = 0, size = 10 },
        { getState },
        _extraOptions,
        fetchWithBQ
      ) => {
        // Get the default estore from Redux state
        const { defaultEstore } = getState()?.auth?.userInfo;
        const defaultEstoreId = getState().auth.defaultEstore;
        const result = await fetchWithBQ({
          //url: `/estore/orders`,
          url: `/estore/orders/${
            defaultEstore || defaultEstoreId
          }?page=${page}&size=${size}`,
          method: "GET",
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),
    // Endpoint for fetching order
    fetchOrder: builder.query({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const { estoreOrderId } = args;
        const result = await fetchWithBQ({
          url: `/estore/order-id=${estoreOrderId}`,
          method: "GET",
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),
    // Endpoint for calculating total price estore order
    fetchOrderTotalPrice: builder.query({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const { estoreOrderId } = args;
        const result = await fetchWithBQ({
          url: `/estore/orders/estore/${estoreOrderId}`,
          method: "GET",
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),

    // Endpoint for updating orderItem Status
    updateOrderItem: builder.mutation({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const { orderItemId, data } = args;

        const result = await fetchWithBQ({
          url: `/estore/orders/order-id=${orderItemId}`,
          method: "PATCH",
          body: { ...data },
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),
    // Endpoint for updating estoreOrder items
    updateEstoreOrderItems: builder.mutation({
      queryFn: async (
        { estoreOrderId, data },
        { getState },
        _extraOptions,
        fetchWithBQ
      ) => {
        ///const { estoreOrderId, data } = args;
        const result = await fetchWithBQ({
          url: `/estore/orders/in-store/${estoreOrderId}`,
          method: "PATCH",
          body: { ...data },
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),
    // Endpoint for updating estoreOrder Status
    updateEstoreOrder: builder.mutation({
      queryFn: async (
        { estoreOrderId, data },
        { getState },
        _extraOptions,
        fetchWithBQ
      ) => {
        ///const { estoreOrderId, data } = args;
        const result = await fetchWithBQ({
          url: `/estore/orders/${estoreOrderId}`,
          method: "PATCH",
          body: { ...data },
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),
    // Endpoint for creating estoreOrder
    createEstoreOrder: builder.mutation({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const { data } = args;
        const result = await fetchWithBQ({
          url: `/estore/orders`,
          method: "POST",
          body: { ...data },
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),
    // Endpoint for updating estoreOrder
    /* sendStatusSMSEstoreOrder: builder.query({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const { estoreOrderId } = args;
        const result = await fetchWithBQ({
          url: `/estore/orders/${estoreOrderId}/sms`,
          method: "GET",
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }), */
  }),
});

// Export hooks for components to use
export const {
  useFetchEstoreOrdersQuery, // Hook for fetching past orders
  useLazyFetchEstoreOrdersQuery, // Hook for fetching past orders
  useFetchOrderQuery, // Hook for fetching order
  useUpdateOrderItemMutation, // Hook for posting a new order
  useUpdateEstoreOrderItemsMutation,
  useCreateEstoreOrderMutation,
  useUpdateEstoreOrderMutation, //Hook for updating seen Time stamp
  //useSendStatusSMSEstoreOrderQuery,
} = estoreOrdersApi;
