import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
const initialState = {
  foodItemCart: [], // Cart items will be stored as objects with id, name, price, quantity, etc.
};

const foodItemCartSlice = createSlice({
  name: "foodItemCart",
  initialState,

  reducers: {
    addToFoodItemCart: (state, action) => {
      const item = action.payload; // The item passed in the payload
      const existingItem = state.foodItemCart.find(
        (cartItem) => cartItem.foodItemId === item.foodItemId
      );

      if (existingItem) {
        // If the item already exists in the cart, increase its quantity
        existingItem.quantity += 1;
      } else {
        // If it's a new item, add it to the cart with an initial quantity of 1
        // Generate a uniqueId if it's not passed in the payload

        state.foodItemCart.push({
          ...item,
          quantity: 1,
          uniqueId: `${item.foodItemId}-${uuidv4()}`,
        });
      }
    },
    addToFoodItemVariantCart: (state, action) => {
      const item = action.payload; // The item passed in the payload

      // Get all existing variant numbers for this food item
      const existingVariants = state.foodItemCart
        .filter(
          (cartItem) =>
            cartItem.foodItemId === item.foodItemId && cartItem.variant
        )
        .map((cartItem) => parseInt(cartItem.variant.replace("copy ", ""), 10))
        .filter((num) => !isNaN(num)) // Ensure only numbers are considered
        .sort((a, b) => a - b); // Sort numerically

      // Find the smallest missing number
      let newVariantNumber = 1;
      for (let i = 0; i < existingVariants.length; i++) {
        if (existingVariants[i] !== i + 1) {
          newVariantNumber = i + 1;
          break;
        }
        newVariantNumber = existingVariants.length + 1;
      }

      // Add the new variant with the correct number
      state.foodItemCart.push({
        ...item,
        quantity: 1,
        variant: `copy ${newVariantNumber}`, // Assign the lowest available copy number
        uniqueId: `${item?.foodItemId}-${uuidv4()}`,
      });
    },
    removeFromFoodItemCart: (state, action) => {
      const uniqueId = action.payload; // The id of the item to be removed
      state.foodItemCart = state.foodItemCart.filter(
        (cartItem) => cartItem?.uniqueId !== uniqueId
      );
    },
    updateFoodItemCartItemQuantity: (state, action) => {
      const { uniqueId, quantity } = action.payload; // payload contains item id and new quantity
      const item = state.foodItemCart.find(
        (cartItem) => cartItem?.uniqueId === uniqueId
      );

      if (item) {
        // Update the quantity, but if the quantity is less than 1, remove the item from the cart
        if (item?.quantity >= 1) {
          item.quantity += quantity;
          if (item?.quantity === 0) {
            state.foodItemCart = state.foodItemCart.filter(
              (cartItem) => cartItem.uniqueId !== uniqueId
            );
          }
        } else {
          state.foodItemCart = state.foodItemCart.filter(
            (cartItem) => cartItem.uniqueId !== uniqueId
          );
        }
      }
    },
    updateFoodItemCartItem: (state, action) => {
      const { uniqueId, ...updatedFields } = action.payload;
      // Extract uniqueId and the other fields to update dynamically
      const item = state.foodItemCart.find(
        (cartItem) => cartItem.uniqueId === uniqueId
      );

      if (item) {
        // Update the item's fields with the new values from the payload
        Object.assign(item, { ...updatedFields });
      }
    },
    removeAllFromFoodItemCart: (state) => {
      state.foodItemCart = []; // Clears the entire cart
    },
  },
});

export const {
  addToFoodItemCart,
  addToFoodItemVariantCart,
  updateFoodItemCartItemQuantity,
  updateFoodItemCartItem,
  removeFromFoodItemCart,
  removeAllFromFoodItemCart,
} = foodItemCartSlice.actions;
export default foodItemCartSlice.reducer;
