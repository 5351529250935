import React, { useEffect, useState, useRef, useCallback } from "react";
import SockJS from "sockjs-client";
import { Client } from "@stomp/stompjs";
import axios from "axios";
import { FaBell } from "react-icons/fa";
import { baseURL } from "../../../components/utils/constants";
import { useSelector } from "react-redux";
import { decodeToken } from "../../../utils/decodeToken";

const NotificationComponent = ({ estoreId, userId }) => {
  const token = useSelector((state) => state?.auth?.userToken);
  const store = decodeToken(token)?.store;
  const [notifications, setNotifications] = useState([]);
  const notificationsRef = useRef([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const hasMarkedRead = useRef(false);

  const fetchDashboardData = useCallback(async () => {
    try {
      const [userNotifications, estoreNotifications, count] = await axios.all([
        axios.get(
          `${baseURL}/ws/estore-notifications/unread/${userId}/${estoreId}`
        ),
        axios.get(`${baseURL}/ws/user-notifications/unread/${userId}`),
        axios.get(`${baseURL}/ws/notifications/unread/${userId}/${estoreId}`),
      ]);

      const allNotifications = [
        ...userNotifications.data,
        ...estoreNotifications.data,
      ];
      setUnreadCount(count);

      notificationsRef.current = allNotifications;
      setNotifications(allNotifications);
      setUnreadCount(allNotifications.length);
    } catch (error) {
      console.error("Error fetching notification data:", error);
    }
  }, [userId, estoreId]);
  const fetchReadDashboardData = useCallback(async () => {
    try {
      const [userNotifications, estoreNotifications] = await axios.all([
        axios.get(
          `${baseURL}/ws/estore-notifications/read/${userId}/${estoreId}`
        ),
        axios.get(`${baseURL}/ws/user-notifications/read/${userId}`),
      ]);

      const allNotifications = [
        ...userNotifications.data,
        ...estoreNotifications.data,
      ];

      notificationsRef.current = allNotifications;
      setNotifications(allNotifications);
    } catch (error) {
      console.error("Error fetching notification data:", error);
    }
  }, [userId, estoreId]);
  useEffect(() => {
    fetchDashboardData();
    fetchReadDashboardData();
    const socket = new SockJS(`${baseURL}/ws`);
    const client = new Client({
      webSocketFactory: () => socket,
      reconnectDelay: 5000,
      onConnect: () => {
        console.log("Connected to WebSocket");
        const topics = [
          `/topic/${estoreId || store}`,
          `/topic/clientOrder/${userId}`,
        ];
        topics.forEach((topic) => {
          client.subscribe(topic, (message) => {
            // Use ref to avoid unnecessary re-renders
            // Ensure message.body is properly parsed
            const newNotification =
              typeof message.body === "string"
                ? JSON.parse(message.body)
                : message.body;
            notificationsRef.current = [
              ...notificationsRef.current,
              newNotification,
            ];
            setNotifications([...notificationsRef.current]);
            setUnreadCount((prevCount) => prevCount + 1);
          });
        });
      },
      onStompError: (frame) => {
        console.error("STOMP Error:", frame);
      },
    });

    client.activate();

    return () => {
      if (client.active) {
        client.deactivate();
      }
    };
  }, [estoreId, store, userId, fetchDashboardData, fetchReadDashboardData]);

  const markAllAsRead = async () => {
    if (hasMarkedRead.current) return; // Prevent multiple API calls
    hasMarkedRead.current = true;

    try {
      const estoreNotificationIds = notifications
        .filter((n) => n?.estoreId != null)
        .map((n) => n.id);
      const userNotificationIds = notifications
        .filter((n) => n?.estoreId === null)
        .map((n) => n.id);

      if (estoreNotificationIds.length > 0) {
        await axios.put(
          `${baseURL}/ws/estore-notifications/mark-read`,
          estoreNotificationIds
        );
      }
      if (userNotificationIds.length > 0) {
        await axios.put(
          `${baseURL}/ws/user-notifications/mark-read`,
          userNotificationIds
        );
      }

      setNotifications([]);
      setUnreadCount(0);
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

  const handleBellClick = () => {
    setDropdownOpen(!dropdownOpen);
    if (!dropdownOpen) {
      markAllAsRead();
    }
  };
  /*  */
  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {/* Bell Icon */}
      <div
        onClick={handleBellClick}
        style={{ cursor: "pointer", position: "relative" }}
      >
        <FaBell size={24} style={styles.icon} />
        {unreadCount > 0 && (
          <span
            style={{
              position: "absolute",
              top: "-5px",
              right: "-5px",
              background: "red",
              color: "white",
              borderRadius: "50%",
              padding: "4px 8px",
              fontSize: "12px",
            }}
          >
            {unreadCount}
          </span>
        )}
      </div>

      {/* Dropdown Notifications List */}
      {dropdownOpen && (
        <div
          style={{
            position: "absolute",
            top: "30px",
            right: "0",
            background: "white",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "5px",
            width: "250px",
            zIndex: 1000,
          }}
        >
          <div style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
            <strong>Notifications</strong>
          </div>
          <ul
            style={{
              listStyle: "none",
              padding: "10px",
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            {notifications.length === 0 ? (
              <li style={{ textAlign: "center", padding: "10px" }}>
                No new notifications
              </li>
            ) : (
              notifications.map((note) => (
                <li
                  key={note.id}
                  style={{ padding: "5px 0", borderBottom: "1px solid #eee" }}
                >
                  {note.message}
                </li>
              ))
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default NotificationComponent;
const styles = {
  icon: {
    fontSize: "25px", // Size of the shopping cart icon
    color: "gray", // Color of the icon
  },
};
