import React from "react";
import { ShoppingCartPlus } from "../../components/CartIcons";
import { useNavigate } from "react-router-dom";
import "./index.css"; // Custom styles if needed
import { toOneWord } from "../../components/utils/formatSentenceToWord";
import { getImageUrl } from "../../components/utils/constants";
const Product = ({ product, handleAddToProductCart, isItemInProductCart }) => {
  const navigate = useNavigate();

  return (
    <div /*  key={product.productId} */ className="product-card">
      {product?.image && (
        <img
          onClick={() => navigate(`/cartitem/${product.productId}`)}
          src={getImageUrl(product.image, "product")}
          alt="product"
        />
      )}
      {product?.logo && (
        <img
          onClick={() => navigate(`/cartitem/${product.productId}`)}
          src={getImageUrl(product.logo, "food-item")}
          alt="product"
        />
      )}

      <>
        <div className="price-and-cart">
          <ShoppingCartPlus
            className="add-icon"
            onAdd={() => handleAddToProductCart(product)}
            disabled={isItemInProductCart(product.productId)}
          />
          <p className="price">${product.price.basePrice}</p>
        </div>
        <label className="item-label">
          <span className="calorie-text">
            {product?.calories && product?.calories > 0 && (
              <p className="calorie-text">({product.calories} cal)</p>
            )}
          </span>
          {product?.name.trim()}
        </label>
      </>

      {product?.estore?.logo && (
        <div
          className="estore-info"
          onClick={() =>
            navigate(`/store/${toOneWord(product?.estore?.name)}`, {
              state: { estore: product?.estore },
            })
          }
        >
          <img
            src={getImageUrl(product?.estore?.logo, "estore")}
            alt="logo"
            className="small-image"
          />
          <div className="estore-name">{product?.estore?.name}</div>
          {/*  <p>{product?.estore.name}</p>
                <pre>{JSON.stringify(product, null, 2)}</pre> */}
        </div>
      )}
    </div>
  );
};
export default Product;
