import "./index.css";
import VerticalNavbar from "./VerticalNavBar";
import Body from "./Body";
import { Routes, Route } from "react-router-dom";
import EstoreUpdate from "./EstoreUpdate";
import EstoreView from "./EstoreView";
import Product from "./Product";
import IngredientDetails from "./IngredientDetails";
import EditIngredient from "./EditIngredient";
import AddIngredient from "./AddIngredient";
import IngredientList from "./IngredientList";
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import AddFoodItem from "./AddFoodItem";
import EditFoodItem from "./EditFoodItem";
import FoodItemDetails from "./FoodItemDetails";
import Category from "./Category";
import CategoryForm from "./CategoryForm";
import SubCategory from "./SubCategory";
import SubCategoryForm from "./SubCategoryForm";
import SpecialEvents from "./SpecialEvents";
import SpecialEventDetail from "./SpecialEventDetails";
import EstoreOrderList from "./EstoreOrderList";
import EstoreUser from "./EstoreUser";
import Tabs from "./Tabs";
import Tab from "./Tab";
import EstoreTabItemEdit from "./EstoreTabItemEdit";
function Dashboard() {
  return (
    <div className="d-container">
      {/* VerticalNavbar on the left side */}
      <div className="VerticalNavbar">
        <VerticalNavbar />
      </div>
      <div className="content">
        <Routes>
          {/* Main Dashboard Page */}
          <Route path="/" element={<Body />} />
          <Route path="/product" element={<Product />} />
          <Route path="/settings/estore-edit/:id" element={<EstoreUpdate />} />
          <Route path="/users" element={<EstoreUser />} />
          <Route
            path="/product/ingredientlist/:id"
            element={<IngredientDetails />}
          />
          <Route
            path="/product/ingredientlist/edit/:id"
            element={<EditIngredient />}
          />
          <Route path="/product/addingredient" element={<AddIngredient />} />
          <Route path="/product/ingredientlist" element={<IngredientList />} />
          <Route path="/product/addproduct" element={<AddProduct />} />
          <Route path="/product/editproduct/:id" element={<EditProduct />} />
          <Route path="/product/addfoodItem" element={<AddFoodItem />} />
          <Route path="/product/editfooditem/:id" element={<EditFoodItem />} />
          <Route path="/product/fooditem/:id" element={<FoodItemDetails />} />
          <Route path="/product/categories" element={<Category />} />
          <Route path="/product/events" element={<SpecialEvents />} />
          <Route path="/product/events/:id" element={<SpecialEventDetail />} />
          <Route path="/product/categories/new" element={<CategoryForm />} />
          <Route path="/product/categories/:id" element={<CategoryForm />} />
          <Route
            path="/product/categories/:categoryId/subcategories"
            element={<SubCategory />}
          />
          <Route
            path="/product/categories/subcategories/new"
            element={<SubCategoryForm />}
          />
          <Route
            path="/product/categories/:categoryId/subcategories/:id"
            element={<SubCategoryForm />}
          />
          <Route path="/orders" element={<EstoreOrderList />} />
          <Route path="/orders/tabs" element={<Tabs />} />
          <Route path="/orders/tabs/:id" element={<Tab />} />
          <Route
            path="/orders/tabs/:id/:itemId"
            element={<EstoreTabItemEdit />}
          />
          <Route path="/settings" element={<EstoreView />} />
        </Routes>
      </div>
    </div>
  );
}
export default Dashboard;
