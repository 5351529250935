export function toOneWord(sentence) {
  return sentence.trim().replace(/\s+/g, "-").toLowerCase();
}
export function extractInitials(name) {
  const words = name?.trim()?.split(/\s+/); // Split by spaces
  if (words?.length > 1) {
    const num = words?.map((word) => word[0]?.toUpperCase())?.join("") || ""; // Take first letter of each word
    if (num?.length > 4) {
      return num?.slice(0, 4);
    } else {
      return num;
    }
  }
  return name?.slice(0, 4).toUpperCase(); // Take first 3 letters if only one word
}
