import React, { useState } from "react";
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from "./navbarElements";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth/authSlice";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import CartUtil from "../CartUtil";
import HomeUtil from "../HomeUtil";
import "./index.css";
import MyLogo from "./MyLogo";
import OrderUtil from "../OrderUtil";
import NotificationComponent from "../../pages/components/NotificationComponent";
import { decodeToken } from "../../utils/decodeToken";

const NavBar = () => {
  const { userToken, userInfo } = useSelector((state) => state.auth);
  const userId = decodeToken(userToken)?.id;
  const [isOpen, setIsOpen] = useState(false); // Manage the state of mobile menu
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    dispatch(logout()); // Dispatch the logout action
    navigate("/login"); // Navigate to the login page after logout
  };

  return (
    <Nav>
      <NavLink to="/" style={{ display: "flex", alignItems: "center" }}>
        <MyLogo storeLogoUrl={null} style={{ width: "40px", height: "40px" }} />
      </NavLink>
      <Bars onClick={toggleMenu} />
      <NavMenu isOpen={isOpen}>
        <NavLink to="/" onClick={toggleMenu}>
          <span className="icon">
            <HomeUtil /> {/* Replace with the desired icon */}
          </span>
          <span className="text">Home</span>
        </NavLink>
        <NavLink to="/orders" onClick={toggleMenu}>
          <span className="icon">
            <OrderUtil />
          </span>
          <span className="text">Orders</span>
        </NavLink>
        <NavLink to="/cart" onClick={toggleMenu}>
          <span className="icon">
            <CartUtil />
          </span>
          <span className="text">Cart</span>
        </NavLink>
        {userId && (
          <>
            <span className="icon">
              <NotificationComponent
                estoreId={userInfo?.defaultEstore}
                userId={userId}
              />
            </span>
          </>
        )}
        {userToken ? (
          <NavBtn>
            <Menu as="div" className="relative">
              <Menu.Button className="flex items-center text-gray-800 bg-white hover:bg-gray-100 py-2 px-10 rounded">
                {userInfo?.preferredName?.substring(0, 2).toUpperCase() ||
                  "User"}
                <ChevronDownIcon className="ml-2 h-5 w-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="p-1">
                    {userInfo?.defaultEstore && (
                      <Menu.Item>
                        <NavLink
                          to="/dashboard"
                          className="p-2 block text-gray-700 hover:bg-gray-100"
                        >
                          Dashboard
                        </NavLink>
                      </Menu.Item>
                    )}
                    <Menu.Item>
                      <NavLink
                        to="/profiles"
                        className="p-2 block text-gray-700 hover:bg-gray-100"
                      >
                        Profile
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item>
                      <button
                        onClick={() => {
                          handleLogout();
                          toggleMenu();
                        }}
                        className="p-2 block text-gray-700 hover:bg-gray-100 w-full text-left"
                      >
                        Logout
                      </button>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </NavBtn>
        ) : (
          <NavBtn>
            <NavBtnLink to="/login" onClick={toggleMenu}>
              Login
            </NavBtnLink>
          </NavBtn>
        )}
      </NavMenu>
    </Nav>
  );
};

export default NavBar;
