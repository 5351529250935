const countryCodes = [
  "1",
  "44",
  "49",
  "91",
  "33",
  "61",
  "81",
  "86",
  "234",
  "971",
  "256",
]; // Extend this list as needed

export const formatPhoneNumber = (value) => {
  if (!value) return value;

  // Remove all non-numeric characters except "+"
  const cleaned = value.replace(/[^\d+]/g, "");

  // Reverse lookup: Assume last 10 digits are local, extract possible country code
  const localNumber = cleaned.slice(-10);
  let countryCode = cleaned.slice(0, -10); // Get everything before the local number

  // Check if the extracted part is a valid country code
  if (!countryCodes.includes(countryCode.replace("+", ""))) {
    countryCode = ""; // Reset if it's not in the list
  }

  const localLength = localNumber.length;

  if (localLength < 4)
    return `${countryCode ? `${countryCode} ` : ""}${localNumber}`;
  if (localLength < 7)
    return `${countryCode ? `${countryCode} ` : ""}(${localNumber.slice(
      0,
      3
    )}) ${localNumber.slice(3)}`;

  return `${countryCode ? `${countryCode} ` : ""}(${localNumber.slice(
    0,
    3
  )}) ${localNumber.slice(3, 6)}-${localNumber.slice(6, 10)}`;
};
