import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { backendURL } from "../../components/utils/constants";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl: backendURL }),
  endpoints: (builder) => ({
    verify: builder.mutation({
      query: ({ code }) => ({
        url: `/auth/verify?code=${code}`, // Pass code as query param
        method: "GET",
      }),
    }),
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: "/auth/forgot-password",
        method: "POST",
        body: { email },
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ code, newPassword }) => ({
        url: "/auth/reset-password",
        method: "POST",
        body: { code, newPassword },
      }),
    }),
  }),
});

export const {
  useVerifyMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApi;
