import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemsToTab,
  removeItemFromTab,
  editItemInTab,
  addProductItemsToTab,
  removeProductItemFromTab,
  editProductItemInTab,
  incrementItemQuantity,
  decrementItemQuantity,
  addToItemVariantCart,
  deleteTab,
} from "../../../redux/auth/estoreTab";
import { FaPlusCircle } from "react-icons/fa";
import { decodeToken } from "../../../utils/decodeToken";
import { useLazyGetAllFoodItemsAndProductsQuery } from "../../../auth/services/foodItemService";
import SearchFilter from "../../utils/SearchFilter";
import FoodItemEstore from "../../../pages/components/FoodItemEstore";
import ProductEstore from "../../../pages/components/ProductEstore";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateEstoreOrderMutation,
  useUpdateEstoreOrderItemsMutation,
} from "../../../auth/services/estoreOrderService";
import { normalizeEstoreOrderCartItem } from "../../utils/cartNormalization";
const Tab = () => {
  const params = useParams();
  const navigate = useNavigate();
  const tabId = params?.id;
  const dispatch = useDispatch();
  const [createEstoreOrder, { isLoading, er, res }] =
    useCreateEstoreOrderMutation();
  const [updateEstoreOrderItems] = useUpdateEstoreOrderItemsMutation();
  const token = useSelector((state) => state.auth.userToken);
  const tab = useSelector((state) => state?.estoreTab?.estoreTabs || []);
  const estoreTabs = useSelector((state) => state?.estoreTab?.estoreTabs || []);
  const estoreId = decodeToken(token).store;
  const [menuOpen, setMenuOpen] = useState(null);
  const [tabMenuOpen, setTabMenuOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [filterOptions, setFilterOptions] = useState({
    name: "",
    categoryId: undefined,
    subcategoryId: undefined,
    estoreId: estoreId,
    page,
    size,
  });
  const [fetchAllItems, { data, error, isFetching }] =
    useLazyGetAllFoodItemsAndProductsQuery();

  const observer = useRef();
  const lastItemRef = useCallback(
    (node) => {
      if (isFetching || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetching, hasMore]
  );

  // Adjust size dynamically based on screen width
  useLayoutEffect(() => {
    const updateSize = () => {
      if (window.innerWidth >= 1200) {
        setSize(10);
      } else if (window.innerWidth >= 768) {
        setSize(7);
      } else {
        setSize(5);
      }
    };

    updateSize(); // Set initial size
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    fetchAllItems({ ...filterOptions, page }).then((res) => {
      if (res.data) {
        const newItems = [
          ...(res?.data?.foodItems?.content || []),
          ...(res?.data?.products?.content || []),
        ];
        // Merge all items into a Set based on unique ID
        if (page === 0) {
          setItems([...newItems]);
        } else {
          setItems((prev) => {
            const uniqueItems = new Map();

            [...prev, ...newItems].forEach((item) => {
              const key = item.foodItemId || item.productId || item.eventId; // Ensure uniqueness
              uniqueItems.set(key, item);
            });

            return Array.from(uniqueItems.values());
          });
        }
        setHasMore(newItems?.length >= size); // If fewer items are returned, stop fetching
      }
    });
  }, [page, filterOptions, fetchAllItems, size]);
  const handlePlaceOrder = async () => {
    try {
      const inStoreId = tab?.find((t) => t.tabId === tabId).name;
      /* const items = tab
        ?.find((t) => t.tabId === tabId)
        .items?.map((item) => {
          return normalizeCartItem(item, name);
        }); */
      const itemsToPost = tab
        ?.find((t) => t.tabId === tabId)
        .items?.map((item) => {
          return normalizeEstoreOrderCartItem(item, estoreId, inStoreId);
        });

      //console.log("Order Placed:", items);
      console.log(
        `Order placed successfully! items: ${JSON.stringify({
          estoreId,
          orderItemRequests: itemsToPost,
        })}`
      );
      const response = await createEstoreOrder({
        data: { estoreId, orderItems: [...itemsToPost] },
      }).unwrap();

      // Handle success (optional)
      //dispatch(removeAllFromFoodItemCart());
      //dispatch(removeAllFromProductCart());
      alert("Order posted successfully:", response);
    } catch (err) {
      // Handle error
      console.error("Error posting order:", err);
    }
  };
  // Remove food item from cart
  const handleAddVariantFromCart = (item) => {
    dispatch(addToItemVariantCart({ estoreId, tabId, item }));
  };

  const handleQuantityMinus = (uniqueId, val) => {
    const newQuantity = parseInt(val, 10);
    if (newQuantity !== 0) {
      dispatch(
        decrementItemQuantity({ estoreId, tabId, uniqueId, quantity: -1 })
      );
    }
  };
  const handleQuantityPlus = (uniqueId, val) => {
    const newQuantity = parseInt(1, 10);
    if (newQuantity > 0) {
      dispatch(
        incrementItemQuantity({ estoreId, tabId, uniqueId, quantity: 1 })
      );
    }
  };
  const handleProductQuantityMinus = (uniqueId, val) => {
    const newQuantity = parseInt(val, 10);
    if (newQuantity !== 0) {
      dispatch(incrementItemQuantity({ uniqueId, quantity: -1 }));
    }
  };
  const handleProductQuantityPlus = (uniqueId, val) => {
    const newQuantity = parseInt(1, 10);
    if (newQuantity > 0) {
      dispatch(decrementItemQuantity({ uniqueId, quantity: 1 }));
    }
  };
  // Add a dummy item to a tab
  const handleAddItemToTab = (tabId, foodItem) => {
    const tb = estoreTabs.find((t) => t.tabId === tabId);
    if (!tb) return;
    const isItemExists = tb.items.some(
      (item) => item.foodItemId === foodItem.foodItemId
    );
    if (isItemExists) return;
    const updatedItems = [
      ...tb.items,
      ...(Array.isArray(foodItem) ? foodItem : [foodItem]),
    ];

    dispatch(addItemsToTab({ estoreId, tabId, items: [foodItem] }));
  };
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".dropdown-menu") &&
        !event.target.closest(".ellipsis-btn")
      ) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    setPage(0); // Reset page when filterOptions change
  }, [filterOptions]);
  // Edit an item in a tab
  const handleEditItem = (tabId, foodItemId, uniqueId) => {
    setMenuOpen(false);
    navigate(`${foodItemId}`, {
      state: { estoreId, tabId, foodItemId, uniqueId },
    });
  };
  // Remove an item from a tab
  const handleRemoveItem = (tabId, uniqueId) => {
    setMenuOpen(false);
    dispatch(removeItemFromTab({ estoreId, tabId, uniqueId }));
  };
  // Add a dummy item to a tab
  const handleAddProductItemToTab = (tabId) => {
    setMenuOpen(false);
    const newItem = {
      foodItemId: `item-${Date.now()}`,
      name: "Sample Item",
      price: Math.floor(Math.random() * 20) + 1,
    };
    dispatch(addProductItemsToTab({ estoreId, tabId, items: [newItem] }));
  };

  // Edit an item in a tab
  const handleEditProductItem = (tabId, foodItemId) => {
    setMenuOpen(false);
    const updatedFields = { name: "Updated Item Name" };
    dispatch(
      editProductItemInTab({ estoreId, tabId, foodItemId, updatedFields })
    );
  };
  // Remove an item from a tab
  const handleRemoveProductItem = (tabId, foodItemId) => {
    setMenuOpen(false);
    dispatch(removeProductItemFromTab({ estoreId, tabId, foodItemId }));
  };
  // Delete a tab
  const handleDeleteTab = (tabId) => {
    setMenuOpen(false);
    dispatch(deleteTab({ estoreId, tabId }));
  };

  return (
    <div className={""}>
      {/* <pre>{JSON.stringify(estoreTabs, null, 2)}</pre> */}
      <pre>
        {JSON.stringify(
          tab?.find((t) => t.tabId === tabId),
          null,
          2
        )}
      </pre>
      <div
        className="flex flex-wrap bg-white"
        onClick={() => setTabMenuOpen(!tabMenuOpen)}
      >
        <p className="ellipsis-btn">
          <i class="fa-solid fa-ellipsis-vertical"></i>
        </p>
        <p className="mt-1">
          <strong>Tab Name: {tab?.find((t) => t.tabId === tabId)?.name}</strong>
        </p>
        {tabMenuOpen && (
          <div className="product-item">
            <button
              className="remove-btn"
              onClick={() => {
                if (
                  window.confirm("Are you sure you want to delete this tab?")
                ) {
                  handleDeleteTab(tab.tabId);
                }
                setTabMenuOpen(!tabMenuOpen);
              }}
            >
              Delete Tab
            </button>
            <button
              onClick={() => {
                handlePlaceOrder();
                setTabMenuOpen(!tabMenuOpen);
              }}
              disabled={isLoading}
            >
              {isLoading ? "Submitting..." : " Place Order"}
              {er && <div>{er.message}</div>}
              {res && <div>Order submitted successfully!</div> && navigate(-1)}
            </button>
          </div>
        )}
      </div>
      {/* <pre>{JSON.stringify(tab, null, 2)}</pre> */}
      <SearchFilter onFilterChange={setFilterOptions} estoreId={estoreId} />
      <p>Item(s): {tab?.find((t) => t.tabId === tabId).items?.length}</p>
      {/* <pre>
        {JSON.stringify(tab?.find((t) => t.tabId === tabId).items[0], null, 2)}
      </pre> */}
      {/* Items in Tab */}
      {tab?.find((t) => t.tabId === tabId).items?.length > 0 ? (
        tab
          ?.find((t) => t.tabId === tabId)
          .items?.map((item) => (
            <div
              key={item?.foodItemId || item?.itemId + item?.unique}
              className="product-item"
            >
              {menuOpen === item?.uniqueId && (
                <div
                  className={`dropdown-menu ${
                    menuOpen === item.uniqueId ? "visible" : "hidden"
                  }`}
                  onMouseEnter={() => setMenuOpen(item.uniqueId)}
                  onMouseLeave={() => setMenuOpen(null)}
                >
                  <div className="item-actions">
                    <p
                      className="ellipsis-btn"
                      style={{ marginRight: "20px" }}
                      onClick={() => setMenuOpen(null)}
                    >
                      <i class="fa-solid fa-ellipsis-vertical"></i>
                    </p>
                    {(item?.type === "FOOD_ITEM" || item?.foodItemId) && (
                      <button
                        onClick={() =>
                          handleEditItem(
                            tabId,
                            item?.foodItemId || item?.itemId,
                            item?.uniqueId
                          )
                        }
                      >
                        Edit
                      </button>
                    )}
                    <button onClick={() => handleAddVariantFromCart(item)}>
                      <FaPlusCircle className="add-icon bg-white" /> Variant
                    </button>
                    <button
                      className="remove-btn"
                      onClick={() => handleRemoveItem(tabId, item.uniqueId)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              )}
              <div
                className={`product-details ${
                  menuOpen === item.uniqueId ? "blurred" : ""
                }`}
              >
                {item?.type === "FOOD_ITEM" || item?.foodItemId ? (
                  <label
                    className="product-name"
                    onClick={() =>
                      handleEditItem(tabId, item.foodItemId || item.itemId)
                    }
                  >
                    {item.name} - ${item?.price?.basePrice}
                  </label>
                ) : (
                  <label className="product-name">
                    {item.name} - ${item?.price?.basePrice}
                  </label>
                )}
                <div className="estore-item-quantity">
                  <p className="estore-item-quantity">
                    Qty:
                    <button
                      onClick={() => handleQuantityMinus(item.uniqueId, -1)}
                      style={{ padding: "0px" }}
                    >
                      <i className="fa-solid fa-minus"></i>
                    </button>
                    <label
                      type="number"
                      value={item?.quantity}
                      min="1"
                      disabled={true}
                      className={"estore-item-quantity-input"}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {item?.quantity}&nbsp;&nbsp;&nbsp;
                    </label>
                    <button
                      onClick={() => handleQuantityPlus(item.uniqueId, 1)}
                      style={{ padding: "0px", marginLeft: "1px" }}
                    >
                      <i className="fa-solid fa-plus"></i>
                    </button>
                  </p>
                </div>
                <p
                  className="ellipsis-btn"
                  onClick={() =>
                    setMenuOpen(
                      menuOpen === item.uniqueId ? null : item.uniqueId
                    )
                  }
                >
                  <i class="fa-solid fa-ellipsis-vertical"></i>
                </p>
              </div>

              {/* Ellipsis Dropdown */}
              <div className="dropdown-container">
                {/* <p
                  className="ellipsis-btn"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  <i className="fa-solid fa-ellipsis-vertical"></i>
                </p> */}
              </div>
            </div>
          ))
      ) : (
        <p>No items added</p>
      )}
      <span className="product-container">
        {items?.map((item, i) => (
          <React.Fragment key={i}>
            {item?.foodItemId && (
              <FoodItemEstore
                key={item.foodItemId + i}
                foodItem={item}
                isItemInFoodItemCart={(id) =>
                  tab
                    ?.find((t) => t.tabId === tabId)
                    .items?.some((it) => it.foodItemId === id)
                }
                handleAddItemToTab={() => handleAddItemToTab(tabId, item)}
              />
            )}
            {item?.productId && (
              <ProductEstore
                key={item?.productId + i}
                product={item}
                isItemInFoodItemCart={(id) =>
                  tab?.items?.some((it) => it.productId === id)
                }
                handleAddProductItemToTab={() =>
                  handleAddProductItemToTab(tabId, item)
                }
              />
            )}
          </React.Fragment>
        ))}
      </span>
      {/* Tab Actions */}
    </div>
  );
};

export default Tab;
