import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { getFoodItemInfo } from "../../../auth/services/homeService.js";
import { getImageUrl } from "../../utils/constants.js";
import CheckboxWithRadio from "../../../pages/components/CheckboxWithRadio/index.js";
import { updateCustomizationMap } from "../../../pages/components/updateCustomizationMap.js";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import { FaPlusCircle } from "react-icons/fa";
import "./index.css";
import ErrorDisplay from "../../../pages/components/ErrorDisplay.js";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { Pagination } from "swiper/modules";
import { editItemInTab } from "../../../redux/auth/estoreTab.js";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #ccc",
    background: "rgba(255, 255, 255, 1)",
  },
};

const EstoreTabItemEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams(); // Get foodItem ID from the URL
  const location = useLocation();
  const { estoreId, tabId, foodItemId, uniqueId } = location.state || {}; // Ensure default to avoid errors
  /* const token = useSelector((state) => state.auth.userToken);
  const foodItemId = Number(params?.itemId);
  const tabId = params?.id;
  const estoreId = decodeToken(token).store; */
  const itemId = Number(params?.itemId);

  const [foodItem, setFoodItem] = useState();
  const [chefNote, setChefNote] = useState();
  const tab = useSelector((state) => state?.estoreTab?.estoreTabs || []);
  const tb = tab.find((t) => t.tabId === tabId);

  // Find the item in the cart that matches the foodItemId and uniqueId
  const tabItem = tb?.items?.find((item) => item.uniqueId === uniqueId);
  const [error, setError] = useState();
  const [openModalId, setOpenModalId] = useState(null);
  const [customizationMap, setCustomizationMap] = useState(new Map());
  const categories = [
    { label: "Garnish", field: "garnish" },
    { label: "Spice Levels", field: "spiceLevels" },
    { label: "Protein", field: "solidAddons" },
    { label: "Liquid Base", field: "liquidAddons" },
    { label: "Other Items", field: "otherIngredients" },
  ];

  const [selectedItems, setSelectedItems] = useState({
    garnish: [],
    spiceLevels: "",
    solidAddons: [],
    liquidAddons: "",
    otherIngredients: [],
  });
  let subtitle;
  const [modalContent, setModalContent] = useState({
    category: "",
    item: "",
    name: "",
    qty: "norm",
    side: false,
  });

  function openModal(category, item) {
    // Get the existing customization for the category
    const categoryCustomizations = tabItem.customizations?.[category];

    // Check if it's an array or an object
    let existingCustomization = Array.isArray(categoryCustomizations)
      ? categoryCustomizations.find((entry) => entry.name === item)
      : categoryCustomizations?.name === item
      ? categoryCustomizations
      : null;
    setModalContent({
      category,
      item: item,
      name: item,
      qty: existingCustomization?.qty || "norm", // Default to "norm" if not found
      side: existingCustomization?.side || false, // Default to false if not found
    });

    setOpenModalId(`${category}-${item}`);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setOpenModalId(null); // Close all modals
  }
  useEffect(() => {
    if (!tb) return;

    if (tabItem) {
      if (tabItem.customizations) {
        try {
          const parsedCustomizations = JSON.parse(tabItem.customizations);

          setSelectedItems({
            garnish: parsedCustomizations?.garnish || [],
            spiceLevels: parsedCustomizations?.spiceLevels || "",
            solidAddons: parsedCustomizations?.solidAddons || [],
            liquidAddons: parsedCustomizations?.liquidAddons || "",
            otherIngredients: parsedCustomizations?.otherIngredients || [],
          });

          const newCustomizationMap = new Map();
          Object.entries(parsedCustomizations).forEach(([key, value]) => {
            newCustomizationMap.set(key, value);
          });
          setCustomizationMap(newCustomizationMap);
        } catch (error) {
          console.error("Error parsing customizations:", error);
        }
      }
    }
  }, [itemId, tab, tabId, tabItem, tb]);
  useEffect(() => {
    getFoodItemInfo(itemId)
      .then((res) => {
        const { availableDaysAndHours, ...updatedFoodItem } = res.data;
        setFoodItem(updatedFoodItem);
        //setFoodItem(res.data);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [itemId, customizationMap]);

  const handleSelectionChange = (category, value) => {
    if (
      category === "otherIngredients" ||
      category === "garnish" ||
      category === "solidAddons"
    ) {
      setSelectedItems((prevSelected) => {
        const updatedItems = [...prevSelected[category]];
        if (updatedItems.includes(value)) {
          updatedItems.splice(updatedItems.indexOf(value), 1);
        } else {
          updatedItems.push(value);
        }
        return {
          ...prevSelected,
          [category]: updatedItems,
        };
      });
    } else {
      setSelectedItems((prevSelected) => ({
        ...prevSelected,
        [category]: value,
      }));
    }
  };

  const handleUpdateCustomization = (item, category, updates) => {
    // Create a new Map to avoid mutating the existing state directly
    const updatedMap = new Map(customizationMap);
    const items = [item]; // Wrap the single item in an array for `updateCustomizationMap`

    // Update the customization map with provided updates
    updateCustomizationMap(updatedMap, category, items, updates);

    // Update the state with the modified Map
    setCustomizationMap(updatedMap);
    setModalContent({ ...modalContent, updates });
  };

  // Checkbox change handler
  const handleCheckboxChange = (isChecked, item, category) => {
    // handleUpdateCustomization(item, category, { side: isChecked });
    handleUpdateCustomization(item, category, { side: isChecked });
    setModalContent((prev) => ({
      ...prev,
      side: isChecked,
    }));
  };

  // Radio change handler
  const handleRadioChange = (qty, item, category) => {
    handleUpdateCustomization(item, category, { qty });
    setModalContent((prev) => ({
      ...prev,
      qty,
    }));
    //handleUpdateCustomization(item, category, { qty });
  };

  const handleUpdateFoodItemCart = (product) => {
    if (!product) return;

    const updatedCustomizations = Object.entries(selectedItems).reduce(
      (acc, [category, items]) => {
        if (Array.isArray(items)) {
          acc[category] = items.map((item) => {
            const customization = customizationMap.get(item);
            return customization
              ? { name: item, qty: customization.qty, side: customization.side }
              : { name: item, qty: "norm", side: false };
          });
        } else {
          const customization = customizationMap.get(items);
          acc[category] = customization
            ? { name: items, qty: customization.qty, side: customization.side }
            : { name: items, qty: "norm", side: false };
        }
        return acc;
      },
      {}
    );

    const updatedFields = {
      foodItemId: itemId,
      customizations: JSON.stringify(updatedCustomizations),
      specialMessage: chefNote || "",
    };

    alert(JSON.stringify(updatedFields));

    dispatch(
      editItemInTab({ estoreId, tabId, foodItemId, uniqueId, updatedFields })
    );
    navigate(-1);
  };

  if (error) return <ErrorDisplay error={error} />;
  return (
    <div style={styles.container}>
      {/* <pre>{JSON.stringify(location.state, null, 2)}</pre> */}
      <pre>{JSON.stringify(tabItem, null, 2)}</pre>
      {/*<pre>{JSON.stringify({ estoreId, foodItemId, tabId, uniqueId })}</pre> */}
      <div style={styles.headers}>
        <img src={getImageUrl(foodItem?.image, "food-item")} alt="logo" />
        <h5>
          <strong>{foodItem?.name}</strong>
        </h5>
        <p style={{ fontSize: ".8rem" }}>Customize:</p>
      </div>

      {/* Slick Carousel for Customization Categories */}
      <Swiper
        style={{
          height: "300px",
          background: "white",
          color: "black",
          zIndex: 0,
        }}
        slidesPerView={1}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {categories?.map((category, index) => {
          const items = foodItem?.combinedIngredients[category?.field] || [];
          if (items.length === 0) return null; // Skip empty categories

          return (
            <SwiperSlide key={index}>
              <span>
                <h6>
                  <strong>{category.label}</strong>
                </h6>
              </span>
              {items.map((item, itemIndex) => (
                <div key={itemIndex} style={styles.radioLabelContainer}>
                  {["otherIngredients", "garnish", "solidAddons"].includes(
                    category.field
                  ) ? (
                    <label
                      style={styles.checkboxLabel}
                      className="w-auto space-y-1"
                    >
                      {item}

                      <input
                        type="checkbox"
                        name={category.field}
                        value={item}
                        style={styles.input}
                        checked={
                          !!selectedItems[category.field].find(
                            (i) => i.name === item || ""
                          )
                        }
                        onChange={() =>
                          handleSelectionChange(category.field, item)
                        }
                      />
                    </label>
                  ) : (
                    <label style={styles.radioLabel} className="w-auto">
                      <input
                        type="radio"
                        name={category.field}
                        value={item}
                        style={styles.input}
                        checked={selectedItems[category.field].name === item}
                        onChange={() =>
                          handleSelectionChange(category.field, item)
                        }
                      />
                      {item}
                    </label>
                  )}
                </div>
              ))}
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* Selected Items Summary */}
      <div style={styles.selectionSummary}>
        <h6>
          <strong>Selected Customizations</strong>
        </h6>

        <ul>
          {Object.entries(selectedItems)?.map(([category, items]) =>
            (Array.isArray(items) ? items.length > 0 : items) ? (
              <li
                key={
                  category +
                  (Array.isArray(items)
                    ? items.map((i) => i.name).join(",")
                    : items.name)
                }
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "90%",
                  maxWidth: "100%",
                  boxSizing: "border-box",
                  overflow: "hidden",
                }}
              >
                <strong>
                  {categories.find((i) => i.field === category)?.label}:
                </strong>

                {Array.isArray(items) ? (
                  items.map((item) => (
                    <div
                      key={category + item.name}
                      style={{ flex: "1 1 auto", flexWrap: "wrap" }}
                    >
                      <div style={styles.rowItem}>
                        <button
                          className="label"
                          onClick={() => openModal(category, item)}
                        >
                          {item.name}
                        </button>
                      </div>
                      <Modal
                        isOpen={
                          openModalId === `${category}-${modalContent.item}`
                        }
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel={`${modalContent.category} - ${modalContent.item.name}`}
                      >
                        <div style={styles.rowItem}>
                          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                            {categories.map(
                              (i) =>
                                i.field.includes(modalContent.category) &&
                                i.label
                            )}
                          </h2>
                          <button className="rounded-xl" onClick={closeModal}>
                            close
                          </button>
                        </div>
                        <div>
                          Selected Item: {modalContent.item.name}
                          <CheckboxWithRadio
                            label={modalContent.item.name}
                            initialQty={modalContent.item.qty}
                            initialSide={modalContent.item.side}
                            onRadioChange={(qty) =>
                              handleRadioChange(
                                qty,
                                modalContent?.item.name,
                                modalContent?.category
                              )
                            }
                            onCheckboxChange={(isChecked) =>
                              handleCheckboxChange(
                                isChecked,
                                modalContent?.item.name,
                                modalContent?.category
                              )
                            }
                          />
                        </div>
                      </Modal>
                    </div>
                  ))
                ) : (
                  // Handle objects (spiceLevels, liquidAddons)
                  <div
                    style={{ flex: "1 1 auto", flexWrap: "wrap" }}
                    key={category + items.name}
                  >
                    <div style={styles.rowItem}>
                      <button
                        className="label"
                        onClick={() => openModal(category, items)}
                      >
                        {items.name}
                      </button>
                    </div>
                    <Modal
                      isOpen={
                        openModalId === `${category}-${modalContent.item}`
                      }
                      onAfterOpen={afterOpenModal}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel={`${modalContent.category} - ${modalContent.item.name}`}
                    >
                      <div style={styles.rowItem}>
                        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                          {categories.map(
                            (i) =>
                              i.field.includes(modalContent.category) && i.label
                          )}
                        </h2>
                        <button className="rounded-xl" onClick={closeModal}>
                          close
                        </button>
                      </div>
                      <div>
                        Selected Item: {modalContent.item.name}
                        <CheckboxWithRadio
                          label={modalContent.item.name}
                          initialQty={modalContent.item.qty}
                          initialSide={modalContent.item.side}
                          onRadioChange={(qty) =>
                            handleRadioChange(
                              qty,
                              modalContent?.item.name,
                              modalContent?.category
                            )
                          }
                          onCheckboxChange={(isChecked) =>
                            handleCheckboxChange(
                              isChecked,
                              modalContent?.item.name,
                              modalContent?.category
                            )
                          }
                        />
                      </div>
                    </Modal>
                  </div>
                )}
              </li>
            ) : null
          )}
        </ul>
        <pre>{JSON.stringify(selectedItems, null, 2)}</pre>
        <label style={styles.label}>
          Chef Note:
          <textarea
            style={styles.textarea}
            id="specialMessage"
            name="specialMessage"
            maxLength="100"
            rows={4}
            cols={40}
            value={chefNote || ""}
            onChange={(e) => setChefNote(e.target.value)}
            placeholder={
              " Allergies / Intolerants if any please indicate them here"
            }
          ></textarea>
        </label>
        <div style={styles.rowItem}>
          <button className="btn btn-1" style={styles.rowItem}>
            <FaPlusCircle style={{ marginRight: "8px" }} />
            cancel
          </button>
          {/* <button className="btn btn-2" style={styles.rowItem}>
            <FaPlusCircle style={{ marginRight: "8px" }} />
            reset Item
          </button> */}
          {
            <button
              style={styles.rowItem}
              className="btn btn-3"
              onClick={() => handleUpdateFoodItemCart(foodItem)}
            >
              update
            </button>
          }
          {/* <h3>Customization Map:</h3>
          <pre>
            {JSON.stringify(Object.fromEntries(customizationMap), null, 2)}
          </pre> */}
        </div>
      </div>
    </div>
  );
};

// Basic Styles
const styles = {
  headers: {
    justifyItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "5px",
    justifyContent: "space-between",
    width: "100%", // Full width by default (small screens)
    maxWidth: "100%", // Prevents overflow
    boxSizing: "border-box", // Ensures padding doesn't increase width

    // Responsive Design
    "@media (minWidth: 768px)": {
      // Medium screens (tablets)
      maxWidth: "75%", // ¾ width
    },
    "@media (minWidth: 1024px)": {
      // Large screens (desktops)
      maxWidth: "50%", // ½ width
    },
  },
  carouselSlide: {
    padding: "10px",
  },
  radioLabelContainer: {
    display: "flex",
    flexDirection: "row",
    justifyItems: "flex-start",
    gap: "10px",
    width: "100%",
    marginLeft: "40px",
    alignContents: "center",
    marginBottom: "10px",
  },
  radioLabel: {
    display: "flex",
    alignContents: "center",
    marginBottom: "10px",
    justifyItems: "center",
    width: "150px",
    fontSize: "14px",
    lineHeight: "1.5",
  },
  rowContainer: {
    display: "flex", // Align the container items in a row
    alignItems: "center", // Center the items vertically
    alignContents: "center",
    alignSelf: "center",
    gap: "1px", // Add some space between items
  },
  rowItem: {
    display: "flex",
    fontSize: "11px",
    marginLeft: "5px",
    marginRight: "5px",
    flexDirection: "row",
    padding: "5px",
    justifyContent: "space-between",
  },
  checkboxLabel: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
    justifyItems: "center",
    width: "auto",
    fontSize: "14px",
    flexDirection: "row-reverse",
    gap: "-1rem",
  },
  input: {
    margin: 0,
    marginLeft: "-0.90em",
    padding: 0,
    width: "16px",
    height: "16px",
  },
  label: {
    display: "block", // Makes the label a block element, causing it to take up the full width and push the textarea below.
    marginBottom: "5px", // Adds a small space between the label and textarea.
    fontWeight: "bold", // Makes the label text bold for better visibility.
    fontSize: ".85rem",
  },
  textarea: {
    border: "1px solid black", // Adds a black border to the textarea.
    marginRight: "10px", // Adds space to the right side of the textarea.
    width: "100%", // Ensures the textarea takes up the full width of its container.
    padding: "8px", // Adds padding inside the textarea for better readability.
    boxSizing: "border-box", // Ensures the padding is included in the width of the textarea.
  },
  selectionSummary: {
    marginTop: "20px",
    paddingTop: "10px",
    borderTop: "1px solid #ddd",
  },
};

export default EstoreTabItemEdit;
