import axios from "axios";
import { API_BASE_URL } from "../../components/utils/constants";
/**
 * Fetch food items, with or without estoreId.
 * @param {string|null} estoreId - The estoreId (or null if fetching without estoreId).
 * @param {number} page - Page number.
 * @param {number} size - Page size.
 * @returns {Promise} - Promise resolving to the response of the API call.
 */
export const fetchFoodItems = (
  estoreId,
  itemType = "foodItem",
  serchText,
  page,
  size
) => {
  const url = estoreId
    ? `${API_BASE_URL}/estore/${estoreId}/food-items?itemType=${itemType}&serchText=${serchText}&page=${page}&size=${size}`
    : `${API_BASE_URL}/food-items/no-estore?itemType=${itemType}&serchText=${serchText}&page=${page}&size=${size}`;

  return axios.get(url);
};

/**
 * Fetch special event items, with or without estoreId.
 * @param {string|null} estoreId - The estoreId (or null if fetching without estoreId).
 * @param {number} page - Page number.
 * @param {number} size - Page size.
 * @returns {Promise} - Promise resolving to the response of the API call.
 */
export const fetchSpecialEvents = (
  estoreId,
  itemType = "specialEvent",
  serchText,
  page,
  size
) => {
  const url = estoreId
    ? `${API_BASE_URL}/estore/${estoreId}/special-items?itemType=${itemType}&serchText=${serchText}&page=${page}&size=${size}`
    : `${API_BASE_URL}/special-events/no-estore?itemType=${itemType}&serchText=${serchText}&page=${page}&size=${size}`;

  return axios.get(url);
};

/**
 * Fetch product items, with or without estoreId.
 * @param {string|null} estoreId - The estoreId (or null if fetching without estoreId).
 * @param {number} page - Page number.
 * @param {number} size - Page size.
 * @returns {Promise} - Promise resolving to the response of the API call.
 */
export const fetchProducts = (
  estoreId,
  itemType = "product",
  serchText,
  page,
  size
) => {
  const url = estoreId
    ? `${API_BASE_URL}/estore/${estoreId}/product-items?itemType=${itemType}&serchText=${serchText}&page=${page}&size=${size}`
    : `${API_BASE_URL}/products/no-estore?itemType=${itemType}&serchText=${serchText}&page=${page}&size=${size}`;

  return axios.get(url);
};
/**
 * Fetch food items, with id.
 * @param {string|null} id - The food item Id .
 * @returns {Promise} - Promise resolving to the response of the API call.
 */
export const fetchFoodItem = (id) => {
  const url = `${API_BASE_URL}/food-items/${id}`;

  return axios.get(url);
};

/**
 * Fetch special event items, with id.
 * @param {string|null} id - The event Id .
 * @returns {Promise} - Promise resolving to the response of the API call.
 */
export const fetchSpecialEvent = (id) => {
  const url = `${API_BASE_URL}/special-events/${id}`;

  return axios.get(url);
};

/**
 * Fetch product items, with id.
 * @param {long|null} id - The id.
 * @returns {Promise} - Promise resolving to the response of the API call.
 */
export const fetchProduct = (id) => {
  const url = `${API_BASE_URL}/product-items/${id}`;

  return axios.get(url);
};
// Function to get all food items, products, and special event items for an estore
export const getAllFoodItemsAndProducts = async ({
  estoreId,
  itemType,
  serchText,
  page,
  size,
}) => {
  /* const params = new URLSearchParams({
    itemType: itemType || "", // Optional parameter
    page,
    size,
  }); */
  try {
    // Fetch food items, products, and special event items concurrently
    const [productsResult, foodItemsResult, specialEventResult] =
      await Promise.all([
        fetchProducts(estoreId, itemType, serchText, page, size), // Products
        fetchFoodItems(estoreId, itemType, serchText, page, size), // Food Items
        fetchSpecialEvents(estoreId, itemType, serchText, page, size), // Special Event Items
      ]);

    // Combine the results
    const combinedData = {
      products: productsResult.data,
      foodItems: foodItemsResult.data,
      specialEvents: specialEventResult.data,
    };

    // Return the combined data
    return { data: combinedData };
  } catch (error) {
    // Handle errors if any of the requests fail
    console.error("Error fetching data:", error);
    return { error };
  }
};
///estore/open-status/{estoreId}
export const getEstoreInfo = (estoreId) => {
  const url = `${API_BASE_URL}/estore/${estoreId}`;

  return axios.get(url);
};
// Function to send a list of Estore IDs and fetch the safety messages
export const fetchSafetyMessage = (estoreIds) => {
  const url = `${API_BASE_URL}/checkout/special-message`;

  return axios
    .post(url, estoreIds) // Send the list directly in the body
    .then((response) => {
      return response.data; // The safety messages
    })
    .catch((error) => {
      console.error("Error fetching safety messages:", error);
      throw error; // Handle error accordingly
    });
};
export const getEstoreOpenStatus = async (estoreId) => {
  const now = new Date();
  const dayOfWeek = now.toLocaleString("en-US", { weekday: "long" });
  const currentTime = now.toTimeString().split(" ")[0];

  const url = `${API_BASE_URL}/estore/${estoreId}/status?dayOfWeek=${dayOfWeek}&currentTime=${currentTime}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    return err.message;
  }
};
export const getFoodItemInfo = (id) => {
  const url = `${API_BASE_URL}/food-item/${id}`;

  return axios.get(url);
};
