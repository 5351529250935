import React, { useEffect, useState, useMemo } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";

const AddressUpdate = ({ estoreAddress, onChange }) => {
  const [address, setAddress] = useState({
    street: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });

  const options = useMemo(() => countryList().getData(), []);

  // Sync local state with prop changes
  useEffect(() => {
    if (estoreAddress) {
      setAddress(estoreAddress);
    }
  }, [estoreAddress]);

  // Update local state and propagate changes to parent
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedAddress = { ...address, [name]: value };
    setAddress(updatedAddress);
    if (onChange && typeof onChange === "function") {
      onChange(updatedAddress);
    }
  };

  const changeHandler = (selectedOption) => {
    const updatedAddress = { ...address, country: selectedOption?.value || "" };
    setAddress(updatedAddress);
    if (onChange && typeof onChange === "function") {
      onChange(updatedAddress);
    }
  };
  return (
    <div className="w-full sm:w-3/4 md:w-1/2 lg:w-2/5 xl:w-2/5 border-none  border-gray-300 p-2 rounded-md">
      <h2>Update Address</h2>
      <div className="w-full">
        <label>Street: </label>
        <input
          type="text"
          name="street"
          value={address.street}
          onChange={handleChange}
          className="w-full"
          required
        />
      </div>
      <div className="w-full">
        <label>City:</label>
        <input
          type="text"
          name="city"
          value={address.city}
          onChange={handleChange}
          className="w-full"
          required
        />
      </div>
      <div className="w-full">
        <label>State:</label>
        <input
          type="text"
          name="state"
          value={address.state}
          onChange={handleChange}
          className="w-full"
          required
        />
      </div>
      <div className="w-full">
        <label>Zip Code:</label>
        <input
          type="text"
          name="zipCode"
          value={address.zipCode}
          onChange={handleChange}
          className="w-full"
          required
        />
      </div>
      <div className="w-full">
        <label>Country:</label>
        <Select
          className="w-full min-w-20 px-3 py-2  rounded-md"
          options={options}
          name="country"
          value={
            options.find((option) => option.value === address?.country) || null
          }
          onChange={changeHandler}
        />
      </div>
    </div>
  );
};

export default AddressUpdate;
