import React from "react";
import { formatTimeToStandard } from "../../components/utils/formatTimeToHHMM";
import { useNavigate } from "react-router-dom";

const OpenDaysHours = ({ openDaysAndHours, estoreId }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div
        className="text-blue-500"
        onClick={() =>
          navigate(`events`, {
            state: { estore: estoreId },
          })
        }
      >
        {/* <FaClipboardList className={"icon-link"} /> */}
        <span className={""}>
          <strong>Events</strong>
        </span>
      </div>
      <h6 style={{ marginBottom: "8px" }}>
        <strong>Open Hours</strong>
      </h6>
      {openDaysAndHours?.openPeriodsByDay
        .filter((day) => day.openPeriods.length > 0) // Filter out days without openPeriods
        .map((day) => (
          <div key={day.id} style={{ marginBottom: "4px" }}>
            <h6 style={{ margin: "2px 0", fontSize: ".95rem" }}>
              <strong>
                {day.dayOfWeek.includes("Thursday")
                  ? day.dayOfWeek.substr(0, 4)
                  : day.dayOfWeek.substr(0, 3)}
              </strong>
            </h6>
            <ul
              style={{
                margin: "2px 0",
                paddingLeft: "16px",
                fontSize: ".95rem",
              }}
            >
              {day?.openPeriods?.map((period) => (
                <li key={period.id}>
                  {/*  Open:{" "} */}
                  {period?.open === true && (
                    <span
                      style={{
                        textDecoration: `none`,
                      }}
                    >
                      <strong
                        style={{ fontWeight: "200px", fontSize: "0.9rem" }}
                      >
                        {formatTimeToStandard(period?.openTime)}
                      </strong>{" "}
                      -{" "}
                      <strong
                        style={{ fontWeight: "200px", fontSize: "0.9rem" }}
                      >
                        {formatTimeToStandard(period?.closeTime)}
                      </strong>
                    </span>
                  )}
                  {period?.open === false && (
                    <span
                      style={{
                        textDecoration: `line-through`,
                      }}
                    >
                      <strong
                        style={{ fontWeight: "200px", fontSize: "0.9rem" }}
                      >
                        {formatTimeToStandard(period?.openTime)}
                      </strong>{" "}
                      -{" "}
                      <strong
                        style={{ fontWeight: "200px", fontSize: "0.9rem" }}
                      >
                        {formatTimeToStandard(period?.closeTime)}
                      </strong>
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
    </div>
  );
};

export default OpenDaysHours;
