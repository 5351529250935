// SpecialEventSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../../components/utils/constants";

// Async Thunk for updating special event logo
export const updateSpecialEventLogo = createAsyncThunk(
  "specialEvent/updateLogo",
  async ({ id, file }, { getState, rejectWithValue }) => {
    // Check if the file is present
    if (!file) {
      const errorMessage = "No file provided for uploading.";
      alert(errorMessage); // Optionally alert the user
      return rejectWithValue(errorMessage); // Return an error
    }

    try {
      const token = getState().auth.userToken;
      // Create a FormData object to hold the image file
      const formData = new FormData();
      formData.append("file", file); // The "file" key should match the server-side expectation

      //http://localhost:8080/api/v1/public/special-event/undefined/logo
      // Make the PATCH request to upload the special event logo
      const { data } = await axios.patch(
        baseURL + `/special-event/${id}/logo`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      alert("updated successfully");
      return data; // Return the response data
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      //alert(JSON.stringify(errorMessage) + " from SpecialEventSlice.js");
      return rejectWithValue(errorMessage);
    }
  }
);

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: null,
  specialEventLogo: null, // Store the uploaded logo (or URL)
};

const specialEventSlice = createSlice({
  name: "specialEvent",
  initialState,
  reducers: {
    resetSpecialEvent: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.error = null;
      state.specialEventLogo = null; // Reset logo state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateSpecialEventLogo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSpecialEventLogo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.specialEventLogo = action.payload; // Store the logo response (e.g., URL)
      })
      .addCase(updateSpecialEventLogo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const { resetSpecialEvent } = specialEventSlice.actions;
export default specialEventSlice.reducer;
