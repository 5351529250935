import React, { useEffect, useState } from "react";
import AddressUpdate from "../../utils/AddressUpdate";
import OpenDaysAndHoursEditor from "../../utils/OpenDaysAndHoursEditor";
import {
  useGetEstoreInfoQuery,
  useUpdateEstoreDetailsMutation,
  estoreApi,
} from "../../../auth/services/estoreService";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import "./index.css";
import EstoreFileUpload from "../../utils/EstoreFileUpload";
import Header from "../Header";
import { estoreURL } from "../../utils/constants";
import TimezoneSelect from "react-timezone-select";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
//import OrderPolicy from "../../utils/OrderPolicy";

const EstoreUpdate = () => {
  const { id } = useParams();
  const [updateEstoreDetails] = useUpdateEstoreDetailsMutation();

  const estoreDataFromCache = useSelector((state) =>
    estoreApi.endpoints.getEstoreInfo.select(id)(state)
  );

  const { data: fetchedEstore } = useGetEstoreInfoQuery(id);
  const [estoredata, setEstoreData] = useState({
    phoneNumber: "",
    name: "",
    email: "",
    logo: "",
    timeZone: "",
    foodSafetyMessage: "",
    orderPolicy: {
      defaultWaitingTime: 0,
      processingTime: 0,
      confirmedTime: 0,
    },
    primaryAddress: {
      addressId: 0,
      street: "",
      city: "",
      state: "",
      zipCode: "",
    },
    openDaysAndHours: {
      openPeriodsByDay: [],
    },
  });

  const [selectedTimezone, setSelectedTimezone] = useState(
    estoredata?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  // Sync form state with fetched estore data or cached estore data
  useEffect(() => {
    if (estoreDataFromCache?.data || fetchedEstore) {
      const dataToSet = estoreDataFromCache?.data || fetchedEstore;
      setEstoreData(dataToSet);
    }
  }, [estoreDataFromCache?.data, fetchedEstore]);

  // Update estoredata when a new timezone is selected
  useEffect(() => {
    setEstoreData((prevData) => ({
      ...prevData,
      timeZone: selectedTimezone?.value || selectedTimezone,
    }));
  }, [selectedTimezone]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEstoreData((prevEstoreData) => ({
      ...prevEstoreData,
      [name]: value,
    }));
  };
  const handlePhoneNumberChange = (newPhoneNumber) => {
    //alert("New Phone Number:", newPhoneNumber);
    setEstoreData((prevEstoreData) => {
      const updatedData = {
        ...prevEstoreData,
        phoneNumber: newPhoneNumber || "", // Fallback for null/undefined
      };
      //alert("Updated Estore Data:", updatedData);
      return updatedData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    //console.log(JSON.stringify(dataToSnd));
    try {
      const result = await updateEstoreDetails({
        id,
        data: {
          ...estoredata,
          openDaysAndHoursDTO: estoredata.openDaysAndHours, // Renaming field for backend if needed
        },
      }).unwrap();
      console.log("Data updated successfully:", result);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  if (!estoreDataFromCache && !fetchedEstore) {
    return <div>Loading...</div>;
  }
  return (
    <div className="estore-update-container">
      <Header />
      <h2>Update Page</h2>
      <EstoreFileUpload className="w-full" />
      <img
        className="estore-logo"
        src={`${estoreURL}${estoredata?.logo}`}
        alt="Estore logo"
      />

      <form onSubmit={handleSubmit}>
        <div className="card ">
          <h3>Basic Info</h3>
          <div className="form-group ">
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={estoredata?.name || ""}
              onChange={handleChange}
              required
              className={"w-full"}
              /* className="w-full sm:w-3/4 md:w-1/2 lg:w-2/5 xl:w-2/5 border border-gray-300 p-2 rounded-md" */
            />
          </div>
          <div className="form-group">
            <label>Phone Number:</label>
            <input
              className={"hidden"}
              type="text"
              name="phoneNumber"
              value={estoredata?.phoneNumber}
              onChange={handleChange}
              required
              display={false}
            />
            {/*   <PhoneInput
              placeholder="Enter phone number"
              value={estoredata?.phoneNumber || value}
              onChange={setValue}
              defaultCountry="US"
              international={true}
            /> */}
            <input
              className={"hidden"}
              type="text"
              name="phoneNumber"
              value={estoredata?.phoneNumber || ""}
              onChange={(e) => handlePhoneNumberChange(e.target.value)}
              placeholder="Enter phone number"
            />
            <PhoneInput
              key={estoredata?.phoneNumber || ""} // Force rerender if needed
              placeholder="Enter phone number"
              value={estoredata?.phoneNumber || ""}
              onChange={handlePhoneNumberChange}
              defaultCountry="US"
              className={"w-full"}
              /*  className="w-full sm:w-3/4 md:w-1/2 lg:w-2/5 xl:w-2/5 border border-gray-300 p-2 rounded-md" */
            />
          </div>
          <div className="form-group ">
            <label>Email:</label>
            <input
              className="w-full"
              type="email"
              name="email"
              value={estoredata?.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Safety Message:</label>
            <textarea
              type="text"
              name="foodSafetyMessage"
              value={estoredata?.foodSafetyMessage}
              onChange={handleChange}
              className="safety-message-textarea"
              cols={7}
            />
          </div>
          <div className="form-group">
            <label>Selected Time Zone: </label>
            <div className="select-wrapper">
              <TimezoneSelect
                value={selectedTimezone}
                onChange={setSelectedTimezone}
              />
            </div>
          </div>
        </div>

        {/* Remaining Form Sections */}
        {/* Order Policy */}
        {/* <div className="card">
          <h3>Order Policy</h3>
          <OrderPolicy
            orderPolicy={estoredata?.orderPolicy}
            onChange={(newOrderPolicy) =>
              setEstoreData((prevEstoreData) => ({
                ...prevEstoreData,
                orderPolicy: newOrderPolicy,
              }))
            }
          />
        </div> */}

        {/* Open Days and Hours */}
        <div className="card">
          <h3>Open Days & Hours</h3>
          <OpenDaysAndHoursEditor
            openDaysAndHours={
              estoredata?.openDaysAndHours.openPeriodsByDay || []
            }
            onUpdate={(newOpenDaysAndHours) =>
              setEstoreData((prevEstoreData) => ({
                ...prevEstoreData,
                openDaysAndHours: { openPeriodsByDay: newOpenDaysAndHours },
              }))
            }
          />
        </div>

        {/* Address */}
        <div className="card">
          <h3>Primary Address</h3>
          <AddressUpdate
            estoreAddress={estoredata?.primaryAddress}
            onChange={(newAddress) =>
              setEstoreData((prevEstoreData) => ({
                ...prevEstoreData,
                primaryAddress: newAddress,
              }))
            }
          />
        </div>

        <button className="update-button" type="submit">
          Update
        </button>
      </form>
    </div>
  );
};

export default EstoreUpdate;
