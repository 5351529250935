// EstoreView.js
import React, { useEffect, useState } from "react";
import AddressUpdate from "../../utils/AddressUpdate";
import OpenDaysAndHoursEditor from "../../utils/OpenDaysAndHoursEditor";
import {
  useGetEstoreInfoQuery,
  estoreApi,
} from "../../../auth/services/estoreService";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./index.css";
import Header from "../Header";
import { estoreURL } from "../../utils/constants";
import { decodeToken } from "../../../utils/decodeToken";
import QrCodeGenerator from "../../../pages/components/QrCodeGenerator";
import { toOneWord } from "../../../components/utils/formatSentenceToWord";
import { formatPhoneNumber } from "../../../pages/components/formatPhoneNumber";
const EstoreView = () => {
  const tokens = useSelector((state) => state.auth.userToken);
  const estoreId = decodeToken(tokens).store;

  const estoreDataFromCache = useSelector((state) =>
    estoreApi.endpoints.getEstoreInfo.select(estoreId)(state)
  );
  const { data: fetchedEstore } = useGetEstoreInfoQuery(estoreId);

  const [estoredata, setEstoreData] = useState({
    phoneNumber: "",
    name: "",
    email: "",
    logo: "",
    timeZone: "",
    orderPolicy: {
      defaultWaitingTime: 0,
      processingTime: 0,
      confirmedTime: 0,
    },
    primary_address: {
      addressId: 0,
      street: "",
      city: "",
      state: "",
      zipCode: "",
    },
    openDaysAndHours: {
      openPeriodsByDay: [],
    },
  });

  useEffect(() => {
    const dataToSet = estoreDataFromCache?.data || fetchedEstore;
    if (dataToSet && dataToSet !== estoredata) {
      setEstoreData(dataToSet);
    }
  }, [estoreDataFromCache, fetchedEstore, estoredata]);

  if (!estoreDataFromCache && !fetchedEstore) {
    return <div>Loading...</div>;
  }

  return (
    <div className="estore-update-container">
      <Header />
      <h2>Preview Estore </h2>
      <img
        className="estore-logo"
        src={
          estoredata?.logo?.includes("https://")
            ? estoredata?.logo
            : `${estoreURL}${estoredata.logo}`
        }
        alt="Estore logo"
      />

      {/* Basic Info Card */}
      <div className="card">
        <h3>Basic Info</h3>
        <div className="form-group">
          <label>Name: {estoredata?.name}</label>
        </div>
        <div className="form-group">
          <label>
            Phone Number:{formatPhoneNumber(estoredata?.phoneNumber)}
          </label>
        </div>
        <div className="form-group">
          <label>Email: {estoredata?.email}</label>
        </div>
        <div className="form-group">
          <label>Time Zone: {estoredata?.timeZone}</label>
        </div>
      </div>

      {/* Order Policy Card */}
      {/*  <div className="card">
        <h3>Order Policy</h3>
        <div className="form-group">
          <label>
            Default Waiting Time (minutes):{" "}
            {estoredata?.orderPolicy?.defaultWaitingTime}
          </label>
        </div>
        <div className="form-group">
          <label>
            Processing Time (minutes):{estoredata?.orderPolicy?.processingTime}
          </label>
        </div>
        <div className="form-group">
          <label>
            Confirmed Time (minutes):{estoredata?.orderPolicy?.confirmedTime}
          </label>
        </div>
      </div>
 */}
      {/* Open Days and Hours Card */}
      <div className="card">
        <h3>Open Days & Hours</h3>
        <OpenDaysAndHoursEditor
          openDaysAndHours={estoredata?.openDaysAndHours.openPeriodsByDay || []}
          canAddPeriod={false}
        />
      </div>

      {/* Address Card */}
      <div className="card">
        <h3>Primary Address</h3>
        <AddressUpdate estoreAddress={estoredata?.primaryAddress} />
      </div>

      <Link className="w-50 update-button " to={`estore-edit/${estoreId}`}>
        Update Store
      </Link>
      <QrCodeGenerator
        url={`https://horelo.com/store/${toOneWord(estoredata?.name)}/${
          estoredata?.estoreId
        }`}
      />
    </div>
  );
};

export default EstoreView;
