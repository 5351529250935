import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useResetPasswordMutation } from "../../auth/services/authVerfiyApi";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetCode = searchParams.get("code");
  const [newPassword, setNewPassword] = useState("");
  const [resetPassword, { isLoading, isSuccess, isError, error }] =
    useResetPasswordMutation();
  useEffect(() => {
    if (isSuccess) setTimeout(() => navigate("/login"), 2000);
  }, [isSuccess, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await resetPassword({ code: resetCode, newPassword });
  };

  return (
    <div className="w-full sm:w-3/4 md:w-1/2 lg:w-2/5 xl:w-2/5 border border-gray-300 p-2 rounded-md">
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="w-full  mb-3"
          required
        />
        <button type="submit" disabled={isLoading} className={"rounded-xl"}>
          Reset Password
        </button>
      </form>

      {isSuccess && <p>Password reset successful! You can now log in.</p>}
      {isError && <p>Error: {error?.data}</p>}
    </div>
  );
};

export default ResetPassword;
