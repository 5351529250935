// estoreSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../../components/utils/constants";

export const fetchEstoreList = createAsyncThunk(
  "auth/estoreProfileList",
  async (_, { rejectWithValue, getState }) => {
    try {
      const token = getState().auth.userToken;
      const { data } = await axios.get(baseURL + "/user/estore", {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchEstoreById = createAsyncThunk(
  "auth/estoreProfileById",
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const token = getState().auth.userToken;
      const storeId = getState().auth.defaultEstore || id;
      const { data } = await axios.get(baseURL + `/estore/${storeId || id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const updateEstore = createAsyncThunk(
  "auth/estoreUpdate",
  async (estoreRequest, { rejectWithValue, getState }) => {
    try {
      const token = getState().auth.userToken;
      const { data } = await axios.put(baseURL + "/estore", estoreRequest, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const updateEstoreLogo = createAsyncThunk(
  "auth/estoreUpdateLogo",
  async ({ id, file }, { rejectWithValue, getState }) => {
    if (!file) {
      return rejectWithValue("No file provided for uploading.");
    }
    try {
      const token = getState().auth.userToken;
      const storeId = getState().auth.defaultEstore || id;
      const formData = new FormData();
      formData.append("file", file);
      const { data } = await axios.patch(
        baseURL + `/estore/${storeId || id}/logo`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const createEstore = createAsyncThunk(
  "auth/estoreCreate",
  async (estoreRequest, { rejectWithValue, getState }) => {
    try {
      const token = getState().auth.userToken;
      const { data } = await axios.post(baseURL + "/estore", estoreRequest, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const initialState = {
  isLoading: false,
  isSuccess: false,
  estore: {},
  estoreList: [],
  error: null,
};

const estoreSlice = createSlice({
  name: "estore",
  initialState,
  reducers: {
    resetEstore: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.estore = {};
      state.estoreList = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEstoreList.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(fetchEstoreList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.estoreList = action.payload;
      })
      .addCase(fetchEstoreList.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })
      .addCase(fetchEstoreById.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(fetchEstoreById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.estore = action.payload;
      })
      .addCase(fetchEstoreById.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })
      .addCase(updateEstore.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(updateEstore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.estoreList.findIndex(
          (estore) => estore.estoreId === action.payload.estoreId
        );
        if (index !== -1) {
          state.estoreList[index] = action.payload;
        }
      })
      .addCase(updateEstore.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })
      .addCase(createEstore.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(createEstore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.estore = action.payload;
        state.estoreList.push(action.payload);
      })
      .addCase(createEstore.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })
      .addCase(updateEstoreLogo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateEstoreLogo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (state.estore.estoreId === action.payload.estoreId) {
          state.estore.logo = action.payload.logo;
        }
      })
      .addCase(updateEstoreLogo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetEstore } = estoreSlice.actions;
export default estoreSlice.reducer;
