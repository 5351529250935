import axios from "axios";
import { refreshAccessToken, isTokenExpired } from "./decodeToken";
import { baseURL } from "../components/utils/constants";
import { setCookieJson, setCookie } from "./cookie";

const apiClient = axios.create({
  baseURL: `${baseURL}`, // Set your base API URL here
});

export const setAuthToken = (token, refreshToken) => {
  apiClient.interceptors.request.use(
    async (config) => {
      if (token && isTokenExpired(token) && refreshToken) {
        console.log("🔄 Token expired. Refreshing...");
        const newToken = await refreshAccessToken(refreshToken);

        setCookieJson("userToken", {
          userTokens: newToken.accessToken,
          refreshToken: newToken.refreshToken,
        });

        setCookie("userTokens", newToken.accessToken);
        setCookie("refreshToken", newToken.refreshToken);
        config.headers.Authorization = `Bearer ${newToken.accessToken}`;
      } else if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export default apiClient;
