import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
} from "react";
import {
  getEstoreInfo,
  getEstoreOpenStatus,
} from "../../auth/services/homeService"; // Assuming you have a product API service
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import { addToProductCart } from "../../redux/auth/productCartSlice"; // Import cart actions
import { addToFoodItemCart } from "../../redux/auth/foodItemCartSlice"; // Import cart actions
import "./index.css";
import FoodItem from "../components/FoodItem";
import Product from "../components/Product";
import SpecialEvent from "../components/SpecialEvent";
import ErrorDisplay from "../components/ErrorDisplay";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import OpenDaysHours from "../components/OpenDaysHours";
import Address from "../components/Address";
import { getImageUrl } from "../../components/utils/constants";
import StatusIndicator from "../components/StatusIndicator";
import {
  validateCartItem,
  createCartItem,
} from "../../components/utils/cartNormalization";
import SearchFilterFront from "../../components/utils/SearchFilterFront";
import { useLazyGetAllFoodItemsAndProductsQuery } from "../../auth/services/homeRTKService";
import { setInStoreId } from "../../redux/auth/authSlice";

const EstoreFrontPage = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const estoreId =
    location.state?.estore?.estoreId || Number(parseInt(params?.estoreId)); // Retrieve estoreId from navigation state
  const dispatch = useDispatch();
  const inStoreId = useSelector((state) => state.auth.inStoreId);
  const { foodItemCart } = useSelector((state) => state.foodItemCart);
  const { productCart } = useSelector((state) => state.productCart);
  // State for combined data and loading/error management
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [estoreOpenStatus, setEstoreOpenStatus] = useState();
  const [estoreInfo, setEstoreInfo] = useState();
  const observerRef = useRef(null);
  const [filterOptions, setFilterOptions] = useState({
    searchText: "",
    categoryId: undefined,
    subcategoryId: undefined,
    estoreId: estoreId || undefined, // Optional estoreId
    page,
    size,
  });

  const [fetchAllItems, { data, isFetching }] =
    useLazyGetAllFoodItemsAndProductsQuery({
      estoreId,
      page,
      size,
    });

  // Fetch products when filters change
  const fetchProducts = useCallback(() => {
    fetchAllItems(filterOptions);
    setItems([]);
    //console.log(JSON.stringify(data));
  }, [filterOptions, fetchAllItems]);

  // **Detect screen size before rendering using useLayoutEffect**
  useLayoutEffect(() => {
    const updatePageSize = () => {
      if (window.matchMedia("(min-width: 1200px)").matches) {
        setSize(10); // Large screens
      } else if (window.matchMedia("(min-width: 768px)").matches) {
        setSize(7); // Tablets
      } else {
        setSize(5); // Mobile
      }
    };

    updatePageSize(); // Set initial value before paint
    const debouncedResize = debounce(updatePageSize, 200);
    window.addEventListener("resize", debouncedResize);

    return () => window.removeEventListener("resize", debouncedResize);
  }, []);
  useEffect(() => {
    if (parseInt(params?.estoreId) || params?.inStoreId) {
      // Redirect to /store/:storeId after 1 second (optional delay)
      params?.inStoreId &&
        dispatch(setInStoreId(params?.inStoreId + "-" + params?.estoreId));
      setTimeout(() => {
        navigate(`/store/${params?.id}`, {
          replace: true,
          state: { estore: { estoreId: params?.estoreId } }, // Preserve state
        });
      }, 1000);
    }
  }, [params?.id, params?.estoreId, params?.inStoreId, dispatch, navigate]);
  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);
  useEffect(() => {
    if (data) {
      setItems((prev) => [
        ...prev,
        ...data?.foodItems.content,
        ...data?.products.content,
      ]); // Append new data
    }
  }, [data]);
  // **Debounced function to update page**
  const debouncedSetPage = debounce(() => {
    if (page < (data?.totalPages || 0) - 1) {
      setPage((prev) => prev + 1);
    }
  }, 500);
  // **Infinite Scroll Logic with Intersection Observer**
  useLayoutEffect(() => {
    if (isFetching) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && page < (data?.totalPages || 0) - 1) {
          //setPage((prev) => prev + 1);
          debouncedSetPage();
        }
      },
      { threshold: 1.0 }
    );

    if (observerRef.current) observer.observe(observerRef.current);

    return () => {
      observer.disconnect();
      debouncedSetPage.cancel();
    };
  }, [isFetching, data?.totalPages, page, debouncedSetPage]);

  // Fetch data with pagination
  useEffect(() => {
    fetchAllItems({ estoreId, page, size });
  }, [estoreId, page, size, fetchAllItems]);

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const fetchStoreStatus = async () => {
      try {
        const status = await getEstoreOpenStatus(estoreId);
        setEstoreOpenStatus(status);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchStoreStatus();
    getEstoreInfo(estoreId).then((response) => {
      if (response.error) {
        setError(response.error);
      } else {
        setEstoreInfo(response.data);
      }
    });
  }, [estoreId]);
  // Helper function to check if the product is already in the cart
  const isItemInFoodItemCart = (productId) => {
    return foodItemCart.some((cartItem) => cartItem.foodItemId === productId);
  };
  // Helper function to check if the product is already in the cart
  const isItemInProductCart = (productId) => {
    return productCart.some((cartItem) => cartItem.productId === productId);
  };
  // Add product foodItem to cart handler
  const handleAddToFoodItemCart = (product) => {
    const normalizedItem = createCartItem(product);
    if (validateCartItem(normalizedItem)) {
      dispatch(addToFoodItemCart(product)); // Dispatch the action to add product to cart
    } else alert.error("not supported");
  };
  // Add product to cart handler
  const handleAddToProductCart = (product) => {
    const normalizedItem = createCartItem(product);
    if (validateCartItem(normalizedItem)) {
      dispatch(addToProductCart(product)); // Dispatch the action to add product to cart
    } else alert.error("not supported");
  };

  if (!estoreId) {
    return (
      <div>
        Error: Store ID not found!{params?.estoreId}-{params?.id} -
        {params?.estoreId}-{params?.inStoreId}
        {typeof Number(params.estoreId)}
      </div>
    );
  }
  if (loading) return <div>Loading products...</div>;
  if (error) return <ErrorDisplay error={error} />;

  return (
    <>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      <div className="w-full">
        <div className="header">
          <img
            src={`${getImageUrl(estoreInfo?.logo, "estore")}`}
            /* src={"/horelo.jpg"} */
            alt="logo"
            className="logo"
          />
          <StatusIndicator
            isOpen={`${estoreOpenStatus?.isOpen}`}
            currentOpenTime={`${estoreOpenStatus?.currentOpenTime}`}
            currentCloseTime={`${estoreOpenStatus?.currentCloseTime}`}
          />
          <div className="header-content">
            <p>{estoreInfo?.note}</p>
            {inStoreId && <p>In-House Self Help</p>}

            <h1>
              <span style={{ color: "#ffff" }}>
                {estoreInfo?.name.charAt(0).toUpperCase() +
                  estoreInfo?.name.slice(1)}
              </span>
            </h1>
          </div>
        </div>

        <div className="sticky-header right">
          {/* Sidebar Toggle Button */}
          <button className="menu-button" onClick={() => setSidebarOpen(true)}>
            ☰ Menu
          </button>

          {/* <a href="#">Home</a>
          <a href="#" className="right">
            About
          </a>
          <a href="#">Contact</a> */}
        </div>
        <div className="row">
          <div className="side">
            <div className="">
              {/* <button
                className="nav-link d-flex"
                onClick={() =>
                  navigate(`events`, {
                    state: { estore: estoreId },
                  })
                }
              >
                
                <span className={"text-link"}>Events</span>
              </button> */}
              <OpenDaysHours
                openDaysAndHours={estoreInfo?.openDaysAndHours}
                estoreId={estoreId}
              />
              <div>
                <h6>
                  <strong>Contact</strong>
                </h6>
                <Address
                  address={estoreInfo?.primaryAddress}
                  phoneNumber={estoreInfo?.phoneNumber}
                  email={estoreInfo?.email}
                />
              </div>
            </div>
          </div>
          {/* Sidebar as a Modal */}
          {isSidebarOpen && (
            <div className="side sidebar-modal">
              <div className="modal-content">
                <button
                  className="close-button w-50"
                  onClick={() => setSidebarOpen(false)}
                >
                  ✖
                </button>
                <OpenDaysHours
                  openDaysAndHours={estoreInfo?.openDaysAndHours}
                />
                <div>
                  <h6>
                    <strong>Contact</strong>
                  </h6>
                  <Address
                    address={estoreInfo?.primaryAddress}
                    phoneNumber={estoreInfo?.phoneNumber}
                    email={estoreInfo?.email}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="main">
            <SearchFilterFront
              onFilterChange={setFilterOptions}
              estoreId={estoreId}
            />
            <span className="product-container">
              {items?.map((item, i) => (
                <React.Fragment key={i}>
                  {item?.foodItemId && (
                    <FoodItem
                      key={item.foodItemId + i}
                      foodItem={item}
                      handleAddToFoodItemCart={handleAddToFoodItemCart}
                      isItemInFoodItemCart={isItemInFoodItemCart}
                    />
                  )}
                  {item?.productId && (
                    <Product
                      key={item?.productId + i}
                      product={item}
                      handleAddToProductCart={handleAddToProductCart}
                      isItemInProductCart={isItemInProductCart}
                    />
                  )}
                  {item?.id && <SpecialEvent key={item?.id + i} event={item} />}
                </React.Fragment>
              ))}
            </span>
          </div>
        </div>
      </div>

      {/* Loading Indicator & Scroll Trigger */}
      <div
        ref={observerRef}
        style={{ height: "50px", background: "lightgray" }}
      >
        {isFetching}
      </div>
    </>
  );
};

export default EstoreFrontPage;
