import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit, FaPlusCircle } from "react-icons/fa";
import { BsCartDash } from "react-icons/bs";
import {
  updateFoodItemCartItemQuantity,
  removeFromFoodItemCart,
  addToFoodItemVariantCart,
} from "../../../redux/auth/foodItemCartSlice";
import {
  removeFromProductCart,
  updateProductCartItemQuantity,
} from "../../../redux/auth/productCartSlice";
import "./index.css"; // Import CSS for car
import { useNavigate } from "react-router-dom";

const CartPage = () => {
  const { foodItemCart } = useSelector((state) => state.foodItemCart);
  const { productCart } = useSelector((state) => state.productCart);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (foodItemCart.length === 0 && productCart.length === 0) {
    return (
      <div style={{ width: "60%" }}>
        <h2>Cart is empty</h2>
      </div>
    );
  }

  // Handle quantity change for food items
  const handleQuantityChange = (uniqueId, e) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (newQuantity > 0) {
      dispatch(
        updateFoodItemCartItemQuantity({ uniqueId, quantity: newQuantity })
      );
    }
  };
  const handleQuantityMinus = (uniqueId, val) => {
    const newQuantity = parseInt(val, 10);
    if (newQuantity !== 0) {
      dispatch(updateFoodItemCartItemQuantity({ uniqueId, quantity: -1 }));
    }
  };
  const handleQuantityPlus = (uniqueId, val) => {
    const newQuantity = parseInt(val, 10);
    if (newQuantity !== 0) {
      dispatch(updateFoodItemCartItemQuantity({ uniqueId, quantity: 1 }));
    }
  };
  /*  */
  // Remove food item from cart
  const handleRemoveFromCart = (uniqueId) => {
    dispatch(removeFromFoodItemCart(uniqueId));
  };
  // Remove food item from cart
  const handleAddVariantFromCart = (foodItem) => {
    dispatch(addToFoodItemVariantCart(foodItem));
  };

  // Handle quantity change for products
  const handleProductQuantityChange = (productId, e) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (newQuantity > 0) {
      dispatch(
        updateProductCartItemQuantity({ productId, quantity: newQuantity })
      );
    }
  };

  // Remove product from cart
  const handleRemoveFromProductCart = (productId) => {
    dispatch(removeFromProductCart(productId));
  };

  return (
    <div className="cart-container">
      <h2>Cart</h2>
      <ul className="cart-list">
        {foodItemCart.map((product) => (
          <li key={product.foodItemId} className="cart-item">
            <div className="cart-item-details">
              {product?.image && (
                <img
                  src={
                    product?.image?.includes("https://")
                      ? product.image
                      : `https://horeloimgs.s3.amazonaws.com/food-item/${product.image}`
                  }
                  alt={product.name}
                />
              )}
              <div className="cart-item-info">
                <h4 className="cart-item-name">
                  {product?.name}
                  {product?.variant && (
                    <spa>
                      <br />
                      <p style={{ fontSize: "11px" }}>{product?.variant}</p>
                    </spa>
                  )}
                </h4>
                <p className="cart-item-price">
                  Price: $
                  {product?.price?.basePrice
                    ? product?.price?.basePrice
                    : product?.price?.price}
                </p>
                <div className="cart-item-quantity">
                  <p className="cart-item-quantity">
                    Qty:
                    <button
                      onClick={() => handleQuantityMinus(product.uniqueId, -1)}
                      style={{ padding: "0px" }}
                    >
                      <i className="fa-solid fa-minus"></i>
                    </button>
                    <label
                      type="number"
                      value={product?.quantity}
                      min="1"
                      onChange={(e) =>
                        handleQuantityChange(product.uniqueId, e)
                      }
                      disabled={true}
                      className={"cart-item-quantity-input"}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {product?.quantity}&nbsp;&nbsp;&nbsp;
                    </label>
                    <button
                      onClick={() => handleQuantityPlus(product.uniqueId, 1)}
                      style={{ padding: "0px", marginLeft: "1px" }}
                    >
                      <i className="fa-solid fa-plus"></i>
                    </button>
                  </p>
                </div>
              </div>

              <div className="cart-item-info">
                <button
                  className="remove-btn"
                  onClick={() => handleRemoveFromCart(product?.uniqueId)}
                >
                  <BsCartDash className="add-icon" />
                  Remove
                </button>
                <button
                  className="remove-btn"
                  onClick={() => handleAddVariantFromCart(product)}
                >
                  <FaPlusCircle className="add-icon" /> Variant
                </button>
                <button
                  className="remove-btn"
                  onClick={() => {
                    navigate(`/cart-item-edit/${product.foodItemId}`, {
                      state: {
                        foodItemId: product.foodItemId,
                        uniqueId: product.uniqueId,
                      },
                    });
                  }}
                >
                  <FaEdit className="add-icon" /> Customize
                </button>
              </div>
              <div style={{ maxWidth: "20rem" }}>
                <h6>
                  <strong>Description</strong>
                </h6>
                <p>{product?.description}</p>
              </div>
            </div>

            <div
              className="estore-info"
              onClick={() =>
                navigate(`/store/${product?.estore?.name}`, {
                  state: { estore: product?.estore },
                })
              }
            >
              <label>{product?.estore?.name}</label>
            </div>
          </li>
        ))}
        {productCart.map((product) => (
          <li key={product.productId} className="cart-item">
            <div className="cart-item-details">
              {product?.image && (
                <img
                  src={
                    product?.image?.includes("https://")
                      ? product.image
                      : `https://horeloimgs.s3.amazonaws.com/product/${product.image}`
                  }
                  alt={product.name}
                />
              )}
              <div className="cart-item-info">
                <h3 className="cart-item-name">{product.name}</h3>
                <p className="cart-item-price">
                  Price: ${product.price.basePrice}
                </p>
                <p className="cart-item-quantity">
                  Quantity:
                  <input
                    type="number"
                    value={product.quantity}
                    min="1"
                    onChange={(e) =>
                      handleProductQuantityChange(product.productId, e)
                    }
                  />
                </p>
              </div>
              <button
                className="remove-btn"
                onClick={() => handleRemoveFromProductCart(product.productId)}
              >
                Remove
              </button>
            </div>
          </li>
        ))}
      </ul>
      <h3>
        Total: $
        {(
          foodItemCart.reduce(
            (total, product) =>
              total + product.price.basePrice * product.quantity,
            0
          ) +
          productCart.reduce(
            (total, product) =>
              total + product.price.basePrice * product.quantity,
            0
          )
        ).toFixed(2)}
      </h3>
      <button
        className="checkout-btn rounded-xl"
        onClick={() => navigate("check-out")}
      >
        Check-out
      </button>
    </div>
  );
};

export default CartPage;
