import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllFoodItemsAndProducts } from "../auth/services/homeService";
import { addToProductCart } from "../redux/auth/productCartSlice";
import { addToFoodItemCart } from "../redux/auth/foodItemCartSlice";
import "./index.css";
import FoodItem from "./components/FoodItem";
import Product from "./components/Product";
import SpecialEvent from "./components/SpecialEvent";
import ErrorDisplay from "./components/ErrorDisplay";
import SearchBar from "../components/utils/SearchBar";
import { debounce } from "lodash";
const Home = () => {
  const dispatch = useDispatch();
  const { foodItemCart } = useSelector((state) => state.foodItemCart);
  const { productCart } = useSelector((state) => state.productCart);

  // State Management
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const observerRef = useRef(null);
  const totalPages = useRef(0);
  useEffect(() => {
    setPage(0); // Reset page when size changes
  }, [size]);

  // Set Dynamic Page Size based on screen width
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize(
        window.matchMedia("(min-width: 1200px)").matches
          ? 10
          : window.matchMedia("(min-width: 768px)").matches
          ? 7
          : 5
      );
    };
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  // Fetch All Items
  const fetchAllItems = useCallback(async (page, size) => {
    if (page > 0 && page >= totalPages.current) return;

    setLoading(true);
    setError(null); // Reset error before fetching

    try {
      const response = await getAllFoodItemsAndProducts({
        estoreId: null,
        itemType: null,
        serchText: "",
        page,
        size,
      });

      if (!response || response.error)
        throw new Error(response?.error || "Unknown error");

      const newFoodItems = response.data?.foodItems?.content || [];
      const newProducts = response.data?.products?.content || [];
      const newSpecialEvents = response.data?.specialEvents?.content || [];

      if (page === 0) {
        totalPages.current =
          response.data?.totalPages ??
          Math.max(
            response.data?.foodItems?.page?.totalPages ?? 0,
            response.data?.products?.page?.totalPages ?? 0,
            response.data?.specialEvents?.page?.totalPages ?? 0
          );
      }

      setItems((prev) => {
        const uniqueItems = new Map();

        [...prev, ...newFoodItems, ...newProducts, ...newSpecialEvents].forEach(
          (item) => {
            const key = item.foodItemId || item.productId || item.id;
            uniqueItems.set(key, item);
          }
        );

        return Array.from(uniqueItems.values());
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  // Initial Fetch and Pagination Handling
  useEffect(() => {
    fetchAllItems(page, size);
  }, [page, size, fetchAllItems]);

  // Intersection Observer for Infinite Scroll
  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      debounce((entries) => {
        if (entries[0]?.isIntersecting && page < totalPages.current - 1) {
          setPage((prev) => prev + 1);
        }
      }, 500), // 300ms debounce
      { threshold: 0.5 }
    );

    observer.observe(observerRef.current);
    return () => observer.disconnect();
  }, [page]);

  // Helper Functions
  const isItemInFoodItemCart = (foodItemId) =>
    foodItemCart.some((item) => item.foodItemId === foodItemId);

  const isItemInProductCart = (productId) =>
    productCart.some((item) => item.productId === productId);

  const handleAddToFoodItemCart = (product) =>
    dispatch(addToFoodItemCart(product));

  const handleAddToProductCart = (product) =>
    dispatch(addToProductCart(product));

  // Render UI
  return (
    <div className="w-full">
      <div className="flex items-center space-x-2 w-full">
        <SearchBar />
      </div>

      {error && <ErrorDisplay error={error} className="w-full items-center" />}
      <div className="product-container">
        {items.length > 0 ? (
          items.map((item) => (
            <React.Fragment key={item.foodItemId || item.productId || item.id}>
              {item.foodItemId && (
                <FoodItem
                  foodItem={item}
                  handleAddToFoodItemCart={handleAddToFoodItemCart}
                  isItemInFoodItemCart={isItemInFoodItemCart}
                />
              )}
              {item.productId && (
                <Product
                  product={item}
                  handleAddToProductCart={handleAddToProductCart}
                  isItemInProductCart={isItemInProductCart}
                />
              )}
              {item.id && <SpecialEvent event={item} />}
            </React.Fragment>
          ))
        ) : !loading ? (
          <div></div>
        ) : null}
      </div>
      {/* Scroll Trigger */}
      <div
        ref={observerRef}
        style={{ height: "50px", background: "lightgray" }}
      >
        {loading && <p></p>}
      </div>
    </div>
  );
};

export default Home;
