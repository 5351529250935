import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getImageUrl } from "../../utils/constants";
import { fetchSpecialEvent } from "../../../auth/services/homeService";
import {
  formatDateTime,
  formatDate,
} from "../../../pages/components/getTimeToClose";
import "./index.css";
const CartEvent = () => {
  const { id } = useParams();
  const [itemData, setItemData] = useState();
  const [error, setError] = useState();
  // Fetch Event item data using the provided ID

  useEffect(() => {
    fetchSpecialEvent(id)
      .then((response) => {
        setItemData(response?.data);
      })
      .catch((er) => setError(er.message));
  }, [id]);

  // Handle errors
  if (error) {
    return <p>Error loading Event item details{id}.</p>;
  }
  return (
    <div style={styles.container}>
      {/* <pre>{JSON.stringify(itemData, null, 2)}</pre> */}
      <h2>{itemData?.name}</h2>
      {itemData?.image && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <img
            src={getImageUrl(itemData?.image, "specialEvents")}
            alt={itemData?.name}
            //style={styles.image}
            className="event-image"
          />
        </div>
      )}

      <div style={styles.infoSection}>
        {itemData?.price?.basePrice > 0 && (
          <p>
            <strong>Price:</strong> $
            {itemData?.price?.basePrice > 0 && itemData?.price?.basePrice}{" "}
            {itemData?.price?.discount > 0 &&
              `Discount: $${itemData?.price?.discount}`}{" "}
            {itemData?.price?.currency}
          </p>
        )}
        <p>
          <strong></strong> {formatDate(`${itemData?.eventDate}T00:00:00`)}
        </p>
        <p className="w-full sm:w-full md:w-3/4 lg:w-3/5 xl:w-3/5">
          Description: <strong>{itemData?.description}</strong>
        </p>
        <p>
          <strong>Start Date Time:</strong>{" "}
          {formatDateTime(itemData?.startTime)}
        </p>
        <p>
          <strong>End Date Time:</strong> {formatDateTime(itemData?.endTime)}
        </p>

        {/* Estore and User Info */}
        <div style={styles.additionalInfo}>
          <p>
            <strong>Estore:</strong> {itemData?.estore?.name}
          </p>
        </div>
      </div>
    </div>
  );
};

// Basic Styles
const styles = {
  container: {
    display: "block",
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "20px",
    width: "80vw",
    maxWidth: "800px", // Prevents excessive stretching
    margin: "20px auto",
    textAlign: "center", // Centers content inside
  },
  image: {
    width: "100%", // Make image take full width of its container
    height: "auto", // Maintain aspect ratio
    maxHeight: "400px", // Set a reasonable max height
    objectFit: "cover",
    borderRadius: "8px",
    marginBottom: "15px",
  },
  infoSection: {
    fontSize: "16px",
    lineHeight: "1.6",
  },

  additionalInfo: {
    marginTop: "20px",
  },
};

export default CartEvent;
