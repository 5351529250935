import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../components/utils/constants";

export const categoryApi = createApi({
  reducerPath: "categoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.userToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
        return headers;
      }
    },
  }),
  endpoints: (builder) => ({
    getCategoryInfo: builder.query({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const { id } = args;
        const result = await fetchWithBQ({
          url: `/category/${id}`,
          method: "GET",
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),
    getCategoryBySubCategoryId: builder.query({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const { id } = args;
        const result = await fetchWithBQ({
          url: `/category/sub/${id}`,
          method: "GET",
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),
    getAllCategories: builder.query({
      queryFn: async (_, { getState }, _extraOptions, fetchWithBQ) => {
        const { defaultEstore } = getState().auth.userInfo;
        const result = await fetchWithBQ({
          url: `/category/estore/${defaultEstore}`,
          method: "GET",
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),
    getCategoryByName: builder.query({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const { name } = args;
        const { defaultEstore } = getState().auth?.userInfo;

        const result = await fetchWithBQ({
          url: `/category/estore/${defaultEstore}/${name}`,
          method: "GET",
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),

    updateCategoryDetails: builder.mutation({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const { id, data } = args;
        const { defaultEstore } = getState().auth.userInfo;

        const result = await fetchWithBQ({
          url: `/category/${id}`,
          method: "PATCH",
          body: {
            ...data,
            estoreId: defaultEstore, // Append state value
          },
        });

        return result.error ? { error: result.error } : { data: result.data };
      },
    }),

    createCategoryDetails: builder.mutation({
      queryFn: async (data, { getState }, _extraOptions, fetchWithBQ) => {
        //const { defaultEstore } = getState().auth.userInfo;
        const { defaultEstore } = getState().auth.userInfo;

        const result = await fetchWithBQ({
          url: `/category`,
          method: "POST",
          body: {
            ...data,
            estoreId: defaultEstore, // Append state value
          },
        });

        return result.error ? { error: result.error } : { data: result.data };
      },
    }),
    deleteCategory: builder.mutation({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const { id } = args;

        const result = await fetchWithBQ({
          url: `/category/${id}`,
          method: "DELETE",
        });
        return result.error ? { error: result.error } : { data: result.data };
      },
    }),
  }),
});

export const {
  useGetCategoryInfoQuery,
  useGetCategoryByNameQuery,
  useGetCategoryBySubCategoryIdQuery,
  useGetAllCategoriesQuery,
  useUpdateCategoryDetailsMutation,
  useCreateCategoryDetailsMutation,
  useDeleteCategoryMutation,
} = categoryApi;
