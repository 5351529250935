import React from "react";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "centre",
        alignItems: "centre",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <h1>Verify Identity</h1>
      <p>Check your email for the verification link provided:</p>
      <button
        onClick={() => navigate("/")}
        style={{
          width: "100px",
          borderRadius: "35px",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#00CDFE",
        }}
      >
        Home
      </button>
    </div>
  );
};

export default About;
