import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./index.css";
import OutOfStockCheckbox from "../OutOfStock";
import SpiceLevels from "../SpiceLevel";
import GarnishToppings from "../GarnishToppings";
import LiquidAddons from "../LiquidAddOns";
import SolidAddons from "../SolidAddons";
import Ingredients from "../Ingredients";
import { useGetSubCategoriesQuery } from "../../../auth/services/subcategoryService"; // Adjust the path if needed
import { useGetAllCategoriesQuery } from "../../../auth/services/categoryService";
import FoodCourse from "../FoodCourse";
import { useGetIngredientInfoByEstoreQuery } from "../../../auth/services/ingredientService";
import { useCreateFoodItemDetailsMutation } from "../../../auth/services/foodItemService";
import DefaultIngredients from "../DefaultIngredients";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; // Import slick styles
import "slick-carousel/slick/slick-theme.css"; // Import slick theme styles

const AddFoodItem = () => {
  const navigate = useNavigate();

  // Retrieve userId and estoreId from Redux store
  const userId = useSelector((state) => state.auth.userInfo.id);
  const estoreId = useSelector((state) => state.auth.userInfo.defaultEstore);

  // State for form inputs
  const [name, setName] = useState("");
  const [cuisineType, setCuisineType] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0.0);
  const [discount, setDiscount] = useState(0.0);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [visibility, setVisibility] = useState("PUBLIC");

  const [createFoodItemDetails] = useCreateFoodItemDetailsMutation();
  // Fetch categories from backend
  const { data: categories = [], isLoading: loadingCategories } =
    useGetAllCategoriesQuery();
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  //Fetch Ingredients from backend
  const {
    data: ingredientSource = [],
    isLoading: loadingIngredientSource,
    refetch,
  } = useGetIngredientInfoByEstoreQuery();
  // Fetch subcategories based on selected category
  const { data: subcategories = [], isLoading: loadingSubcategories } =
    useGetSubCategoriesQuery(
      {
        categoryId: selectedCategoryId
          ? parseInt(selectedCategoryId)
          : undefined,
      },
      { skip: !selectedCategoryId }
    );
  const [garnishes, setGarnishes] = useState([]);
  const [foodCourses, setFoodCourses] = useState([]);
  const [liquidAddons, setLiquidAddons] = useState([]);
  const [solidAddons, setSolidAddons] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [selectedDefaultIngredients, setSelectedDefaultIngredients] = useState(
    []
  );
  const [spiceLevels, setSpiceLevels] = useState([]);
  // Initialize foodCourses after fetching ingredientSource
  // Manual refetch handler
  const handleRefetch = (e) => {
    e.preventDefault();
    refetch(); // This triggers the refetch
  };

  useEffect(() => {
    if (!loadingIngredientSource && ingredientSource.length > 0) {
      const courseTypeIngredients = ingredientSource.filter(
        (item) => item.SubCategory.name === "Course Type"
      );
      const garnishIngredients = ingredientSource.filter(
        (item) => item.SubCategory.name === "Garnish/Toppings"
      );
      setGarnishes(
        garnishIngredients.map((item) => ({
          ...item,
          checked: false, // Add a checked property for toggling
        }))
      );

      //ingredientSource.filter((item) => item.SubCategory.name === "Garnish/Toppings")
      const liquidAddonsIngredients = ingredientSource.filter(
        (item) => item.SubCategory.name === "Liquid AddOn"
      );
      setLiquidAddons(
        liquidAddonsIngredients.map((item) => ({
          ...item,
          checked: false, // Add a checked property for toggling
        }))
      );
      //ingredientSource.filter((item) => item.SubCategory.name === "Liquid AddOn")
      //ingredientSource.filter((item) => item.SubCategory.name === "Other Ingredients")
      const spiceLevelsIngredients = ingredientSource.filter(
        (item) => item.SubCategory.name === "Spice Level"
      );
      setSpiceLevels(
        spiceLevelsIngredients.map((item) => ({
          ...item,
          checked: false, // Add a checked property for toggling
        }))
      );
      //ingredientSource.filter((item) => item.SubCategory.name === "Spice Level")
      const solidAddonsIngredients = ingredientSource.filter(
        (item) => item.SubCategory.name === "Solid AddOn"
      );
      setSolidAddons(
        solidAddonsIngredients.map((item) => ({
          ...item,
          checked: false, // Add a checked property for toggling
        }))
      );
      //ingredientSource.filter((item) => item.SubCategory.name === "Solid AddOn")

      //setSelectedIngredients();
      /* otherIngredients.push(
        ingredientSource.filter(
          (item) => item.SubCategory.name === "Other Ingredients"
        )
      ); */
      //ingredientSource.filter((item) => item.SubCategory.name === "Course Type");

      setFoodCourses(
        courseTypeIngredients.map((item) => ({
          ...item,
          checked: false, // Add a checked property for toggling
        }))
      );
    }
  }, [loadingIngredientSource, ingredientSource]);

  const combinedIngredients = {
    garnish: garnishes
      .filter((garnish) => garnish.checked)
      .map((garnish) => garnish.name),
    spiceLevels: spiceLevels
      .filter((spice) => spice.checked)
      .map((spice) => spice.name),
    solidAddons: solidAddons
      .filter((solid) => solid.checked)
      .map((solid) => solid.name),
    liquidAddons: liquidAddons
      .filter((liquid) => liquid.checked)
      .map((liquid) => liquid.name),
    otherIngredients: selectedIngredients.map((ingredient) => ingredient.name),
    foodCourses: foodCourses
      .filter((course) => course.checked)
      .map((course) => course.name),
  };

  // A handler function to update foodCourses from the child
  const handleSetFoodCourses = (updatedCourses) => {
    console.log("Parent updating foodCourses:", updatedCourses);
    setFoodCourses(updatedCourses);
  };
  const handleSetGarnish = (updatedCourses) => {
    console.log("Parent updating foodCourses:", updatedCourses);
    setGarnishes(updatedCourses);
  };
  const handleSetLiquidAddons = (updatedCourses) => {
    console.log("Parent updating foodCourses:", updatedCourses);
    setLiquidAddons(updatedCourses);
  };
  const handleSetSolidAddons = (updatedCourses) => {
    console.log("Parent updating foodCourses:", updatedCourses);
    setSolidAddons(updatedCourses);
  };
  const handleSetSpiceLevels = (updatedCourses) => {
    console.log("Parent updating foodCourses:", updatedCourses);
    setSpiceLevels(updatedCourses);
  };
  const handleSetIngredients = (selectedIngredients) => {
    // Update local state of selected ingredients
    setSelectedIngredients(selectedIngredients);
  };
  const handleSetSelectedDefaultIngredients = (selectedDefaultIngredients) => {
    // Update local state of selected ingredients
    setSelectedDefaultIngredients(selectedDefaultIngredients);
  };

  // Submit data to server
  const submitData = async () => {
    const data = {
      name,
      calories: productData.calories,
      subCategoryId: productData.subCategoryId,
      image: "https://horeloimgs.s3.amazonaws.com/tacos.jpg", // Example static image
      spice_level:
        "" +
        spiceLevels
          .filter((spice) => spice.checked)
          .map((spice) => spice.name)
          .join(", "), // Format spice levels as a comma-separated string
      cuisine_type: cuisineType,
      price: {
        basePrice: parseFloat(price),
        discount: parseFloat(discount),
        currency: "USD",
      },
      description,
      ingredients: selectedDefaultIngredients
        .map((ingredient) => ingredient.name)
        .join(","), // Comma-separated selectedIngredients
      visibility,
      estoreId,
      userId,
      combinedIngredients,
    };

    //console.log(JSON.stringify(data));
    try {
      const result = await createFoodItemDetails(data).unwrap();
      console.log("Data submitted successfully:", result);
      //alert(JSON.stringify(result));
      navigate("/dashboard");
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };
  // State to capture form data
  const [productData, setProductData] = useState({
    name: "",
    description: "",
    category: "",
    subCategoryId: null, // Stores selected subcategory ID
    calories: 0,
    price: {
      basePrice: 0,
      discount: 0.0,
      currency: "USD",
    },
    estoreId,
  });

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "basePrice") {
      setProductData((prevData) => ({
        ...prevData,
        price: { ...prevData.price, basePrice: parseFloat(value) }, // Ensure it's a number
      }));
    } else if (name === "calories") {
      setProductData((prevData) => ({
        ...prevData,
        calories: parseFloat(value), // Ensure it's a number
      }));
    } else if (name === "subCategoryId") {
      setProductData((prevData) => ({
        ...prevData,
        subCategoryId: parseInt(value),
      }));
    } else {
      setProductData((prevData) => ({ ...prevData, [name]: value }));
    }
  };
  const handleCategoryChange = (e) => {
    const categoryId = parseInt(e.target.value);
    setSelectedCategoryId(categoryId);
    setProductData((prevData) => ({
      ...prevData,
      category: categoryId,
      subCategoryId: null, // Reset subcategory when category changes
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    submitData();
  };

  const handleBackClick = () => {
    navigate("/dashboard");
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
  };
  return (
    <div className="add-food-item-container w-full">
      <button className="btn btn-secondary mb-3" onClick={handleBackClick}>
        Back to Dashboard
      </button>
      <h3>Add New Menu Item</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="productCategory">Menu Item Category</label>
          <select
            id="productCategory"
            className="form-control"
            value={productData.category || ""}
            onChange={handleCategoryChange}
            required
          >
            <option value="">Select Category</option>
            {loadingCategories || loadingIngredientSource ? (
              <option disabled>Loading...</option>
            ) : (
              categories.map((cat) => (
                <option key={cat.categoryId} value={cat.categoryId}>
                  {cat.name}
                </option>
              ))
            )}
          </select>
        </div>
        {selectedCategoryId && (
          <div className="form-group">
            <label htmlFor="productSubcategory">Menu Item Subcategory</label>
            <select
              id="productSubcategory"
              className="form-control"
              name="subCategoryId"
              value={productData.subCategoryId || ""}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Sub Category</option>
              {loadingSubcategories ? (
                <option disabled>Loading...</option>
              ) : (
                subcategories.map((subcat) => (
                  <option
                    key={subcat.subCategoryId}
                    value={subcat.subCategoryId}
                  >
                    {subcat.name}
                  </option>
                ))
              )}
            </select>
          </div>
        )}
        <div className="form-group">
          <label htmlFor="product-name">Product Name</label>
          <input
            id="product-name"
            name="product-name"
            type="text"
            className="form-control w-full"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        {/* loadingIngredientSource ? (
          <option disabled>Loading...</option>
        ) : (
          <pre>{JSON.stringify(ingredientSource, null, 2)}</pre>
        ) */}

        <div className="form-group">
          <label htmlFor="cuisine-type">Cuisine Type</label>
          <input
            id="cuisine-type"
            name="cuisine-type"
            type="text"
            className="form-control w-full"
            value={cuisineType}
            onChange={(e) => setCuisineType(e.target.value)}
          />
        </div>
        {/* <pre>{JSON.stringify(foodCourses, null, 2)}</pre> */}

        <div className="form-group w-full">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            className="form-control w-full"
            rows="3"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
        <DefaultIngredients
          selectedDefaultIngredients={selectedDefaultIngredients}
          setSelectedDefaultIngredients={handleSetSelectedDefaultIngredients}
          suggestions={ingredientSource}
        />
        <div className="d-flex w-100">
          <div className="form-group">
            <label htmlFor="price">Price</label>
            <input
              id="price"
              name="price"
              type="number"
              className="form-control"
              value={price}
              onChange={(e) => setPrice(parseFloat(e.target.value))}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="discount">Discount</label>
            <input
              id="discount"
              name="discount"
              type="number"
              className="form-control"
              value={discount}
              onChange={(e) => setDiscount(parseFloat(e.target.value))}
            />
          </div>
          <div className="form-group">
            <label htmlFor="calories">Calories</label>
            <input
              id="calories"
              type="number"
              className="form-control"
              name="calories"
              value={productData.calories}
              onChange={handleInputChange}
            />
          </div>
          {/*  */}
        </div>
        <div className="d-flex w-100">
          <div className="form-group me-2 w-50">
            <label htmlFor="visibility">Visibility</label>
            <select
              id="visibility"
              name="visibility"
              value={visibility}
              onChange={(e) => setVisibility(e.target.value)}
              className="form-control"
            >
              <option value="PUBLIC">Public</option>
              <option value="PRIVATE">Private</option>
              <option value="UNAVAILABLE">Unavailable</option>
            </select>
          </div>
          <div className="form-group  w-50">
            <OutOfStockCheckbox
              isOutOfStock={isOutOfStock}
              setIsOutOfStock={setIsOutOfStock}
            />
          </div>
        </div>
        <p className="italic text-black-500 font-300 mb-0 flex items-center space-x-2">
          Optional Sections for client order customization:
          <button
            onClick={handleRefetch}
            className="flex items-center space-x-1 rounded bg-blue-500"
          >
            <i className="fas fa-sync text-sm"></i>
          </button>
        </p>
        <div className="mt-0">
          <Slider {...settings} className="mt-0">
            <FoodCourse
              foodCourses={foodCourses}
              setFoodCourses={handleSetFoodCourses}
            />
            <GarnishToppings
              garnishes={garnishes}
              setGarnishes={handleSetGarnish}
            />
            <LiquidAddons
              liquidAddons={liquidAddons}
              setLiquidAddons={handleSetLiquidAddons}
            />
            <SolidAddons
              solidAddons={solidAddons}
              setSolidAddons={handleSetSolidAddons}
            />
            <Ingredients
              selectedIngredients={selectedIngredients}
              setSelectedIngredients={handleSetIngredients}
              suggestions={ingredientSource}
            />
            <SpiceLevels
              spiceLevels={spiceLevels}
              setSpiceLevels={handleSetSpiceLevels}
            />
          </Slider>
        </div>
        <button type="submit" className="btn btn-primary">
          Create
        </button>
      </form>
    </div>
  );
};

export default AddFoodItem;
