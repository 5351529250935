import React, { useEffect, useState } from "react";
import "./OpenDaysAndHoursEditor.css";

const DAYS_OF_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const AvailableDaysAndHoursEditor = ({
  availableDaysAndHours,
  onUpdate,
  canAddPeriod = true,
}) => {
  const [daysAndHours, setDaysAndHours] = useState(
    DAYS_OF_WEEK.map((day, index) => ({
      id: 352 + index, // Generate initial IDs starting from 352
      dayOfWeek: day,
      openPeriods: [],
    }))
  );
  const [selectedDay, setSelectedDay] = useState("Sunday"); // Default to Sunday
  useEffect(() => {
    if (availableDaysAndHours?.length) {
      // Sync with parent-provided data
      setDaysAndHours(availableDaysAndHours);
    }
  }, [availableDaysAndHours]);

  const handleChange = (dayIndex, periodIndex, field, value) => {
    const updatedDaysAndHours = daysAndHours.map((day, i) => {
      if (i === dayIndex) {
        const updatedPeriods = day.openPeriods.map((period, j) =>
          j === periodIndex ? { ...period, [field]: value } : period
        );
        return { ...day, openPeriods: updatedPeriods };
      }
      return day;
    });
    setDaysAndHours(updatedDaysAndHours);
    onUpdate(updatedDaysAndHours); // Notify parent of updates
  };

  const handleToggleIsOpen = (dayIndex, periodIndex) => {
    const updatedDaysAndHours = daysAndHours.map((day, i) => {
      if (i === dayIndex) {
        const updatedPeriods = day.openPeriods.map((period, j) =>
          j === periodIndex ? { ...period, open: !period.open } : period
        );
        return { ...day, openPeriods: updatedPeriods };
      }
      return day;
    });
    setDaysAndHours(updatedDaysAndHours);
    onUpdate(updatedDaysAndHours); // Notify parent of updates
  };

  const handleAddPeriod = (dayIndex) => {
    const updatedDaysAndHours = daysAndHours.map((day, i) => {
      if (i === dayIndex) {
        return {
          ...day,
          openPeriods: [
            ...day.openPeriods,
            { id: null, openTime: "", closeTime: "", open: false },
          ],
        };
      }
      return day;
    });
    setDaysAndHours(updatedDaysAndHours);
    onUpdate(updatedDaysAndHours); // Notify parent of updates
  };
  const handleRemovePeriod = (dayIndex) => {
    const updatedDaysAndHours = daysAndHours.map((day, i) => {
      if (i === dayIndex) {
        return {
          ...day,
          openPeriods: day.openPeriods.slice(0, -1), //remove last period
        };
      }
      return day;
    });
    setDaysAndHours(updatedDaysAndHours);
    onUpdate(updatedDaysAndHours); // Notify parent of updates
  };
  const getSelectedDayData = () =>
    daysAndHours.find((day) => day.dayOfWeek === selectedDay) || {
      dayOfWeek: selectedDay,
      openPeriods: [],
    };

  return (
    <div>
      <h2>Edit Available Days and Hours</h2>
      {/* Dropdown to select a day */}
      <label>Select a Day: </label>
      <select
        value={selectedDay}
        onChange={(e) => setSelectedDay(e.target.value)}
      >
        {DAYS_OF_WEEK.map((day) => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </select>

      {/* Display the selected day's periods */}
      <div className="day-card w-full">
        <h3>{selectedDay}</h3>

        {getSelectedDayData().openPeriods.map((period, periodIndex) => (
          <div key={periodIndex} className="period-inputs w-full">
            <label>
              Open Time:
              <input
                type="time"
                value={period.openTime}
                onChange={(e) =>
                  canAddPeriod &&
                  handleChange(periodIndex, "openTime", e.target.value)
                }
                className="w-full"
              />
            </label>
            <label>
              Close Time:
              <input
                type="time"
                value={period.closeTime}
                onChange={(e) =>
                  canAddPeriod &&
                  handleChange(periodIndex, "closeTime", e.target.value)
                }
                className="w-full"
              />
            </label>
            <label>
              Open:
              <input
                type="checkbox"
                checked={period.open}
                onChange={() => canAddPeriod && handleToggleIsOpen(periodIndex)}
              />
            </label>
          </div>
        ))}

        {canAddPeriod && (
          <>
            <button type="button" onClick={handleAddPeriod} className="button">
              Add Period
            </button>

            {getSelectedDayData().openPeriods.length > 0 && (
              <button
                type="button"
                onClick={handleRemovePeriod}
                className="button mt-1"
              >
                Remove
              </button>
            )}
          </>
        )}
      </div>
      {/* {daysAndHours.map((day, dayIndex) => (
          <div key={day.id} className="day-card  w-full">
            <h3>{day.dayOfWeek}</h3>
            {day.openPeriods.map((period, periodIndex) => (
              <div key={periodIndex} className="period-inputs  w-full">
                <label>
                  Open Time:
                  <input
                    type="time"
                    value={period.openTime}
                    onChange={(e) =>
                      handleChange(
                        dayIndex,
                        periodIndex,
                        "openTime",
                        e.target.value
                      )
                    }
                  />
                </label>
                <label>
                  Close Time:
                  <input
                    type="time"
                    value={period.closeTime}
                    onChange={(e) =>
                      handleChange(
                        dayIndex,
                        periodIndex,
                        "closeTime",
                        e.target.value
                      )
                    }
                  />
                </label>
                <label>
                  Open:
                  <input
                    type="checkbox"
                    checked={period.open}
                    onChange={() => handleToggleIsOpen(dayIndex, periodIndex)}
                  />
                </label>
              </div>
            ))}
            {canAddPeriod && (
              <>
                <button
                  type="button"
                  onClick={() => handleAddPeriod(dayIndex)}
                  className="button"
                >
                  Add Period
                </button>

                {day?.openPeriods?.length > 0 && (
                  <button
                    type="button"
                    onClick={() => handleRemovePeriod(dayIndex)}
                    className="button mt-1"
                  >
                    Remove
                  </button>
                )}
              </>
            )}
          </div>
        ))}*/}
    </div>
  );
};

export default AvailableDaysAndHoursEditor;
